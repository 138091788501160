import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/faq-questions`, params);
    },

    store(params) {
        return BaseService.post(`/faq-questions`, params);
    },

    show(faqQuestionId) {
        return BaseService.get(`/faq-questions/${faqQuestionId}`);
    },

    update(faqQuestionId, params) {
        return BaseService.post(`/faq-questions/${faqQuestionId}`, params);
    },

    destroy(faqQuestionId) {
        return BaseService.post(`/faq-questions/${faqQuestionId}/destroy`);
    },
    getCategories(){
        return BaseService.get(`/faq-questions/categories`);
    }
}
