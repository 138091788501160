<template>
    <div>
        <div class="field">
            <label class="label" for="title">{{ $t('categories.meta_title') }}</label>
            <div class="field-control">
                <input id="title" type="text" v-model="category.meta_title" class="meta-titel">
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('categories.meta_description') }}</label>
            <div class="field-control">
                <textarea placeholder="" v-model="category.meta_description"></textarea>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            category: {
                required: true
            },
        },
        methods: {
            storeSeo() {
                this.$store.commit('categories/set_category', this.category)
            },
        },
        name: "MetaTab"
    }
</script>
