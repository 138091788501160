<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search" type="text" v-model="searchQuery" />
            </div>

            <div>
                <a href="#" class="btn btn--secondary btn--push-right" @click.prevent="$router.push({name: 'filters.index'})">{{ $t('filters.filters') }}</a>
                <a href="#" class="btn btn--primary" @click.prevent="createFilter">{{ $t('filters.create_filter') }}</a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('filters.title') }}</th>
                    <th>{{ $t('filters.options') }}</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="filter in filtersByCategory" :key="'filter_' + filter[0].filter_category.id" @click="editFilter(filter[0].filter_category.id)">
                    <td>{{ filter[0].filter_category.name }}</td>
                    <td>{{ filter.map(q => q.option).join(', ') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { FilterService } from '../../../services';

    export default {
        name: 'product-specifications-tab',

        data() {
            return {
                searchQuery: '',
                filters: [],
            };
        },

        computed: {
            filtersByCategory() {
                const results = this.filters.filter(f => {
                    const searchQuery = this.searchQuery.toLowerCase();
                    const filter = f.option.toLowerCase();
                    const filterCategory = f.filter_category.name.toLowerCase();

                    return filter.includes(searchQuery) || filterCategory.includes(searchQuery);
                });

                return results.reduce((objectsByKeyValue, obj) => {
                    const value = obj['filter_category_id'];
                    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);

                    return objectsByKeyValue;
                }, {});
            },
        },

        beforeMount() {
            this.fetchFilters();
        },

        methods: {
            fetchFilters() {
                const productId = this.$route.params.id;

                const params = {
                    filters: {
                        name: this.searchQuery,
                    }
                };

                FilterService.getProductFilters(productId, params).then(response => {
                    this.filters = response.data;
                });
            },

            editFilter(filterCategoryId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.filters.edit',
                    params: {
                        id: productId,
                        filterCategory: filterCategoryId
                    }
                });
            },

            createFilter() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.filters.create',
                    params: {
                        id: productId
                    }
                });
            },
        },
    }
</script>
