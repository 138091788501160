import BaseService from './index';

export default {
    getConfigurations(productId, searchQuery = '') {
        return BaseService.get(`/products/${productId}/configurations`, { searchQuery });
    },

    getConfigurationTypes()
    {
        return BaseService.get(`/products/configuration-types`);
    },

    getConfiguration(configurationId) {
        return BaseService.get(`/configurations/${configurationId}/show`);
    },

    store(productId, params) {
        return BaseService.post(`/products/${productId}/configurations`, params);
    },

    update(configurationId, params) {
        return BaseService.put(`/configurations/${configurationId}`, params);
    },

    updatePositions(configurations) {
        return BaseService.put(`/configurations/update-positions`, { configurations });
    },

    delete(configurationId) {
        return BaseService.delete(`/configurations/${configurationId}`);
    },
}
