import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/news-articles`, params)
    },

    all(params) {
        return BaseService.get(`/news-articles/all`, params)
    },

    show(articleId) {
        return BaseService.get(`/news-articles/${articleId}`);
    },

    store(params) {
        return BaseService.post(`/news-articles`, params);
    },

    update(articleId, params) {
        return BaseService.post(`/news-articles/${articleId}`, params);
    },

    categories() {
        return BaseService.get(`/news-articles/categories`);
    },
}
