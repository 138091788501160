import BaseService from './index';

const baseParams = {
    baseURL: process.env.MIX_APP_URL,
};

export default {
    index(params) {
        return BaseService.get(`/powerpages/componentGroups`, params, baseParams)
    },

    show(componentGroupId) {
        return BaseService.get(`/powerpages/componentGroups/${componentGroupId}`, {}, baseParams);
    },

    store(params) {
        return BaseService.post(`/powerpages/componentGroups`, params, {}, baseParams);
    },

    update(componentGroupId, params) {
        return BaseService.post(`/powerpages/componentGroups/${componentGroupId}`, params, {}, baseParams);
    },
}
