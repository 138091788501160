<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search" type="text" v-model="searchQuery" @input="onSearchInput" />
            </div>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="createAnswer">
                    {{ $t('choice_help.add_answer') }}
                </a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('choice_help.answer') }}</th>
                    <th>{{ $t('choice_help.filter_option') }}</th>
                    <th>{{ $t('choice_help.follow_up_question') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="answer in answers" @click="editAnswer(answer.id)">
                    <td>{{ answer.title }}</td>
                    <td v-if="answer.filter_option">{{ answer.filter_option.filter_category.name }} | {{ answer.filter_option.option }}</td>
                    <td v-else style="color:red">Filteroptie is verwijderd</td>
                    <td v-if="answer.follow_up_question !== null">{{ answer.follow_up_question.name }}</td>
                    <td v-else></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import store from '../../../../store';
    import { ChoiceHelpAnswerService } from '../../../../services';
    import { debounce } from 'lodash';

    export default {
        name: 'product-questions-tab',

        data() {
            return {
                answers: [],
                searchQuery: '',
            }
        },

        beforeMount() {
            this.fetchAnswers();
        },

        methods: {
            fetchAnswers() {
                ChoiceHelpAnswerService.index(store.state.choiceHelpQuestions.question.id, { search: this.searchQuery }).then(response => {
                    this.answers = response.data;
                });
            },

            editAnswer(answerId) {
                this.$router.push('antwoorden/' + answerId);
            },

            createAnswer() {
                this.$router.push('antwoorden/nieuw');
            },

            onSearchInput: debounce(function () {
                this.fetchAnswers();
            }, 250),
        },
    }
</script>
<style type="scss" scoped>
    th, td {
        width : 33%;
    }
</style>
