<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5 0H6.5C5.12125 0 4 1.12125 4 2.5V17.5C4 18.8788 5.12125 20 6.5 20H21.5C22.8788 20 24 18.8788 24 17.5V2.5C24 1.12125 22.8788 0 21.5 0ZM6.5 17.5V2.5H21.5L21.5025 17.5H6.5Z" fill="black"/>
        <path d="M2.38535 6.99341H0V21.5705C0 22.9104 1.06983 24 2.38535 24H16.6975V21.5705H2.38535V6.99341ZM13.0395 11.2681L10.981 9.17147L9.29453 10.8891L13.1994 14.8662L20 6.55732L18.1657 5L13.0395 11.2681Z" fill="black"/>
    </svg>
</template>

<script>
    export default {
        name: 'MultipleChoice',
    };
</script>