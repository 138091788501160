export default {
    nl: {
        general: {
            my_profile: 'Mijn profiel',
            logout: 'Uitloggen',
            general_information: 'Algemene informatie',
            configurator_questions: 'Configurator',
            prices: 'Prijzen',
            images: 'Afbeeldingen',
            search: 'Zoeken',
            yes: 'Ja',
            no: 'Nee',
            required: 'Verplicht',
            cancel: 'Annuleren',
            confirm: 'Bevestigen',
            homepageSettings: 'Homepage instellingen',
            save: 'Opslaan',
            delete: 'Verwijderen',
            update_success: 'Succesvol opgeslagen',
            update_failed: 'Er is iets misgegaan bij het opslaan',
            recommendedProducts: 'Aanbevolen producten',
            categories: 'Categoriën voor \"Kies jouw categorie\"',
            workfield: 'Categoriën voor \"Producten op basis van werkveld\"',
            noResults: 'Geen resultaten',
            header_title: 'Header titel',
            header_description: 'Header omschrijving',
            header_button_text: 'Header knop label',
            header_button_url: 'Header knop url',
            header_image: 'Header afbeelding',
            homepage_video: 'Homepage video (Voeg het video ID van YouTube toe. Dit is de tekst na \'?v=\')',
        },
        images: {
            add_image: 'Voeg een afbeelding toe',
            delete_success: 'Afbeelding verwijderd',
            delete_fail: 'Afbeelding kon niet worden verwijderd',
            store_fail: 'Afbeelding kon niet worden opgeslagen',
            updated_order: 'Afbeelding volgorde aangepast',
        },
        homepage: {
            homepage: 'Startpagina',
        },
    },
};
