<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">{{ $t('powerpages.title') }}</h1>

            <div>
                <a href="#"
                   class="btn btn--primary"
                   @click.prevent="$router.push({name: 'powerpages.create'})">
                    {{ $t('powerpages.add_power_page') }}
                </a>
            </div>
        </div>

        <div class="filters">
            <div class="search">
                <img src="/svgs/search-icon.svg"
                     alt="search-icon">
                <input class="search"
                       type="text"
                       :placeholder="$t('general.search') + '...'"
                       v-model="searchQuery"
                       @input="onSearchInput" />
            </div>
        </div>

        <table class="table "
               ref="overviewTable">
            <thead>
                <tr>
                    <th class="small">{{ $t('powerpages.status') }}</th>
                    <th>{{ $t('powerpages.slug') }}</th>
                </tr>
            </thead>

            <tbody>
                <router-link v-for="page in pages"
                             :key="page.id"
                             custom
                             v-slot="{route}"
                             :to="{name: 'powerpages.show', params: {id: page.id}}">
                    <tr @click="$router.push(route)">
                        <td><div class="dot" :class="{ offline: page.offline }"></div></td>
                        <td>{{ page.slug }}</td>
                    </tr>
                </router-link>
            </tbody>
        </table>

        <div class="pagination pagination--push-top"
             v-if="totalPages > 1">
            <span @click="paginate(n)"
                  v-for="n in totalPages"
                  :class="{'active' : n === page}">{{ n }}</span>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import PowerpageService from '../../services/powerpages';

    export default {
        name: "PowerPagesIndex",

        data() {
            return {
                page: 1,
                pages: [],
                searchQuery: null,
                totalPages: 1,
            };
        },

        mounted() {
            this.loadPowerpages();
        },

        methods: {
            loadPowerpages() {
                const params = {
                    page: this.page,
                };

                if (this.searchQuery != null) {
                    params.search = this.searchQuery;
                }

                PowerpageService.index(params)
                    .then(response => {
                        this.totalPages = response.data.meta.last_page;
                        this.pages = response.data.data;
                    });
            },

            paginate(page) {
                this.page = page;
                this.loadPowerpages();
            },

            onSearchInput: debounce(function(){
                this.page = 1;
                this.loadPowerpages();
            }, 250),
        },
    }
</script>

<style lang="scss" scoped>
.table {
    .small {
        width : 80px;
    }

    .dot {
        border        : 7px solid green;
        position      : absolute;
        border-radius : 50%;
        margin        : 25px;

        &.offline {
            border : 7px solid red;
        }
    }
}
</style>
