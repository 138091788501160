export default {
    nl: {
        pages: {
            title: "Pagina's",
            homepage: {
                title: "Homepage",
            },
            thank_you_page: {
                title: "Bedanktpagina",
                video: "Bedanktpagina video (Voeg het video ID van YouTube toe. Dit is de tekst na \'?v=\')",
            },
            category_page: {
                title: "Categoriepagina",
                video: "Categoriepagina video (Voeg het video ID van YouTube toe. Dit is de tekst na \'?v=\')",
            },
            product_page: {
                title: "Productpagina",
                video: "Productpagina fallback video (Voeg het video ID van YouTube toe. Dit is de tekst na \'?v=\')",
            },
            about_us_page: {
                title: "Over ons pagina",
                video: "Over ons pagina video (Voeg het video ID van YouTube toe. Dit is de tekst na \'?v=\')",
            },
            returns_and_refunds_page: {
                title: "Retour- & teruggavebeleid pagina",
                add_image: "Voeg een afbeelding toe",
            },
            error_page: {
                title: "404 pagina",
                video: "404 pagina video (Voeg het video ID van YouTube toe. Dit is de tekst na \'?v=\')",
            },
        },
    },
};
