import BaseService from './index';

export default {
    getRelevantProducts(productId, searchQuery = '') {
        return BaseService.get(`/products/${productId}/relevant-products`, { searchQuery });
    },

    getRelevantProduct(productId, relevantProductId) {
        return BaseService.get(`/relevant-products/${relevantProductId}/show`,);
    },

    updatePosition(relevantProductId, position){
        return BaseService.put(`/relevant-products/${relevantProductId}/position`, { position });
    },

    store(productId, params) {
        return BaseService.post(`/products/${productId}/relevant-products`, params);
    },

    update(relevantProductId, params) {
        return BaseService.put(`/relevant-products/${relevantProductId}`, params);
    },

    delete(relevantProductId) {
        return BaseService.delete(`/relevant-products/${relevantProductId}`);
    },
}
