import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/filters`, params);
    },

    options(params) {
        return BaseService.get(`/filters/options`, params);
    },

    show(id) {
        return BaseService.get(`/filters/${id}`);
    },

    store(params) {
        return BaseService.post('/filters', params);
    },

    update(id, params) {
        return BaseService.put(`/filters/${id}`, params);
    },

    delete(id) {
        return BaseService.delete(`/filters/${id}`);
    },

    getProductFilters(productId, params) {
        return BaseService.get(`/products/${productId}/filters`, params);
    },

    updateProductFilter(productId, params) {
        return BaseService.put(`/products/${productId}/filters`, params);
    },

    deleteProductFilter(productId, filterCategoryId) {
        return BaseService.delete(`/products/${productId}/filters/${filterCategoryId}`);
    },
}
