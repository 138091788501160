<template>
    <div class="category-filters">
        <div class="field"
             v-for="i in 12"
             :key="`product-${i}`">
            <label class="label">Selecteer product {{ i }}</label>
            <div class="field-control">
                <multiselect :options="productSelectOptions"
                             label="name"
                             placeholder="Selecteer een product"
                             track-by="id"
                             v-model="selectedProducts[i - 1]">
                    <template v-slot:clear v-if="selectedProducts[i - 1] != null">
                        <button class="multiselect__clear" @click="deselectProduct(i - 1)" />
                    </template>
                </multiselect>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import draggable from 'vuedraggable';
    import { CategoryService } from '../../../services';

    export default {
        name: 'ProductsTab',
        components: {
            draggable,
            Multiselect,
        },
        props: {
            category: {
                required: true,
            },
        },
        data() {
            return {
                selectedProducts: [],
            };
        },
        created() {
            CategoryService.getProductsPosition(this.category.id)
                           .then(response => {
                               this.selectedProducts = response.data.slice(0, 12);
                           });
        },
        mounted() {
            this.$root.$on('save-category-products', () => {
                // Strip non visible selected products and unselected
                this.selectedProducts = this.selectedProducts.filter(function (selectedProduct) {
                    return selectedProduct != null;
                });

                CategoryService.updateProducts(this.category.id, this.selectedProducts)
                               .then(() => {
                                   this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                               });
            });
        },
        beforeDestroy() {
            this.$root.$off('save-category-products');
        },
        methods: {
            savePriority(e) {
                this.category.product_category = this.category.product_category.map((pivot, index) => {
                    pivot.priority = index + 1;
                    return pivot;
                });
                this.$store.commit('categories/set_category', this.category);
            },
            deselectProduct(index) {
                this.$set(this.selectedProducts, index, null);
            },
        },
        computed: {
            selectedProductIds() {
                return this.selectedProducts
                           .filter(selectedProduct => selectedProduct !== null)
                           .map(selectedProduct => selectedProduct['product_id']);
            },
            productSelectOptions() {
                return this.category
                           .product_category
                           .filter(pivot => {
                               return !this.selectedProductIds.includes(pivot.product.id);
                           })
                           .map(pivot => {
                               return {
                                   id: pivot.id,
                                   product_id: pivot.product.id,
                                   name: pivot.product.name,
                               };
                           });
            },
        },
    };
</script>
