<template>
    <div class="panel"
         :key="localConfiguration.id">
        <div class="panel-heading">
            <h3 class="panel-title">{{ $t('configurations.configuration') }}</h3>
        </div>

        <div class="field">
            <label class="label">Type</label>
            <div class="field-control">
                <vue-multi-select v-model="localConfiguration.configuration_type"
                                  :class="{'error' : errors[`${errorKey}configuration_type.id`]}"
                                  :options="configurationTypes"
                                  label="title"
                                  track-by="id"
                                  placeholder="Selecteer configuratie type" />
            </div>
            <div class="error-message" v-if="errors[`${errorKey}configuration_type.id`]">{{ errors[`${errorKey}configuration_type.id`][0] }}</div>
        </div>

        <div class="field">
            <label class="label">Vraag</label>
            <div class="field-control">
                <input type="text"
                       v-model="localConfiguration.title"
                       :class="{'error' : errors[`${errorKey}title`]}" />
            </div>
            <div class="error-message" v-if="errors[`${errorKey}title`]">{{ errors[`${errorKey}title`][0] }}</div>
        </div>

        <div class="field">
            <label class="label">Uitleg</label>
            <div class="field-control">
                <input type="text" v-model="localConfiguration.description" />
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('general.required') }}</label>
            <div class="field field--checkbox" @click="toggleIsRequired">
                <input type="checkbox" :checked="localConfiguration.is_required" />
                <div class="field-control">
                    <label>{{ $t('general.yes') }}</label>
                </div>
            </div>
        </div>

        <h3 class="panel-title panel-title--push panel-title--brighter">{{ $t('configurations.configuration_options') }}</h3>

        <div>
            <div class="error-message" v-if="errors[`${errorKey}configuration_options`]">{{ errors[`${errorKey}configuration_options`][0] }}</div>

            <transition-group name="flip-list">
                <configuration-option v-for="(configurationOption, index) in orderedConfigurationOptions"
                                      @changedDefault="changedDefault(orderedConfigurationOptions)"
                                      @orderChanged="orderChanged(configurationOption, $event)"
                                      @delete="deleteChild(configurationOption)"
                                      :is-color-question="localConfiguration.configuration_type.id === 1"
                                      :is-multiple-choice="localConfiguration.configuration_type.id === 3"
                                      :title="$t('configurations.configuration_option') + ' #' + (index + 1)"
                                      :key="'answer_' + index + '_' + configurationOption.id"
                                      :configuration-option="configurationOption"
                                      :is-last-configuration-option="index + 1 === orderedConfigurationOptions.length"
                                      :error-key="`${errorKey}configuration_options.${index}.`" />
            </transition-group>

            <div class="new-row" @click="addNewAnswer">
                <div class="new-row__circle">
                    <img src="/svgs/plus.svg">
                </div>

                <span>{{ $t('configurations.add_configuration_option') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { ConfigurationService } from '../../../services';
    import ConfigurationOption from './ConfigurationOption';
    import { debounce } from 'lodash';
    import OptionsMenu from '../../../components/options-menu';
    import VueMultiSelect from 'vue-multiselect';

    export default {
        name: 'configuration',

        components: {
            OptionsMenu,
            ConfigurationOption,
            VueMultiSelect,
        },

        data() {
            return {
                localConfiguration: this.selectedConfiguration,
                configurationTypes: [],
            };
        },

        computed: {
            errors() {
                const filtered = Object.entries(this.$store.state.configurations.errors).filter(([key, value]) => {
                    return key.startsWith(this.errorKey);
                });

                return Object.fromEntries(filtered);
            },
            errorKey() {
                return this.$store.state.configurations.selectedConfigurationOffset.replace(/\\/g, '');
            },
            orderedConfigurationOptions() {
                if (this.localConfiguration.configuration_options.filter(configurationOption => configurationOption.order === 0).length > 0) {
                    // this list is not ordered yet
                    let count = 1;
                    this.localConfiguration.configuration_options.forEach(configurationOption => {
                        configurationOption.order = count;
                        count++;
                    });
                }

                return this.localConfiguration.configuration_options.sort((a, b) => (a.order > b.order) ? 1 : -1);
            },

            availableConfigurationOptions() {
                return this.configuration.configuration_options;
            },

            selectedConfiguration() {
                return this.$store.state.configurations.selectedConfiguration;
            },
        },

        beforeMount() {
            ConfigurationService.getConfigurationTypes().then(response => {
                this.configurationTypes = response.data;
            });
        },

        watch: {
            selectedConfiguration: {
                handler() {
                    this.localConfiguration = this.selectedConfiguration;
                },
                deep: true,
                immediate: true,
            },
            localConfiguration: {
                handler() {
                    // this.commit();
                },
                deep: true,
            },
        },

        methods: {
            changedDefault(configurationOptions)
            {
                configurationOptions.forEach((configurationOption) => {
                    configurationOption.is_default = false;
                })
            },
            deleteChild(child) {
                this.localConfiguration.configuration_options = this.localConfiguration.configuration_options.filter(configuration_option => configuration_option !== child);
            },
            toggleIsRequired() {
                this.localConfiguration.is_required = !this.localConfiguration.is_required;
            },
            orderChanged(movedConfigurationOption, event) {
                const order = event.order;
                const direction = event.direction;

                let configurationOption = this.orderedConfigurationOptions.find((configurationOption) => {
                    return configurationOption.order === order && movedConfigurationOption !== configurationOption;
                });

                if (direction === 'down') {
                    configurationOption.order = order - 1;
                }

                if (direction === 'up') {
                    configurationOption.order = order + 1;
                }
            },

            addNewAnswer() {
                this.$store.commit('configurations/add_new_configuration_option', this.selectedConfiguration);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .panel {
        .error-message {
            border        : 2px solid #DB4649;
            border-radius : 4px;
            padding       : 10px;
            font-size     : 14px;
            background    : #F5DEDF;
            margin        : 10px 0px;
        }
    }
</style>
