<template>
    <div class="category-settings">
        <div class="card"
             v-for="(card, idx) in cards">
            <div class="field">
                <h1>Positie {{ card.position + 1 }}</h1>
                <q v-if="card.position === 47">Wordt altijd als laatste op elke pagina getoond</q>
                <q v-else>Vanaf {{ card.position + 4 - cards.filter(c => c.position < card.position).length }} producten</q>
            </div>

            <div class="fields">
                <div class="field field--checkbox">
                    <input type="checkbox"
                           :id="`is-enabled-${idx}`"
                           v-model="card.isEnabled" />
                    <div class="field-control">
                        <label :for="`is-enabled-${idx}`">
                            Actief
                        </label>
                    </div>
                </div>

                <div class="field">
                    <div class="field-control">
                        <vue-multiselect v-model="card.backgroundColor"
                                         :options="colorHexCodes"
                                         :customLabel="customLabel"
                                         :close-on-select="true"
                                         placeholder="Kies een kleur voor de achtergrond"
                                         :multiple=false />

                    </div>
                </div>

                <div class="field field--image">
                    <label class="label">Afbeelding</label>
                    <div v-if="card.image === null"
                         class="field-control">
                        <label class="btn btn--blue"
                               :for="'image-input' + card.id">
                            {{ $t('images.add_image') }}
                        </label>

                        <input :id="'image-input' + card.id"
                               accept="image/*"
                               name="images"
                               type="file"
                               @change="fileInputChange($event, card)">
                    </div>
                    <div v-else
                         class="field-control">
                        <div class="image-container">
                            <img :src="card.image.path || card.image"
                                 alt=""
                                 class="image">
                            <div class="icon"
                                 @click="card.image = null">
                                <img alt="close-icon"
                                     src="/svgs/close-icon.svg">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field field--row">
                    <div class="field-control">
                        <input type="text"
                               placeholder="Titel"
                               v-model="card.title" />
                    </div>
                    <div class="field-control">
                        <vue-multiselect v-model="card.titleColor"
                                         :options="colorHexCodes"
                                         :customLabel="customLabel"
                                         :close-on-select="true"
                                         placeholder="Kies een kleur voor de titel"
                                         :multiple=false />

                    </div>
                </div>

                <div class="field field--checkbox">
                    <input type="checkbox"
                           :id="`has-button-${idx}`"
                           v-model="card.hasButton" />
                    <div class="field-control">
                        <label :for="`has-button-${idx}`">
                            Heeft button
                        </label>
                    </div>
                </div>

                <div class="field field--row"
                     v-if="card.hasButton">
                    <div class="field-control">
                        <input type="text"
                               placeholder="Button tekst"
                               v-model="card.buttonText" />
                    </div>
                    <div class="field-control">
                        <vue-multiselect v-model="card.buttonTextColor"
                                         :options="colorHexCodes"
                                         :customLabel="customLabel"
                                         :close-on-select="true"
                                         placeholder="Kies een kleur voor de button tekst"
                                         :multiple=false />

                    </div>
                    <div class="field-control">
                        <vue-multiselect v-model="card.buttonBackgroundColor"
                                         :options="colorHexCodes"
                                         :customLabel="customLabel"
                                         :close-on-select="true"
                                         placeholder="Kies een kleur voor de button achtergrond"
                                         :multiple=false />

                    </div>
                </div>

                <div class="field" v-if="card.hasButton">
                    <div class="field-control">
                        <input type="text" placeholder="Button url" v-model="card.buttonUrl">
                    </div>
                </div>

                <div class="field">
                    <div class="field-control">
                        <Redactor placeholder="Type hier uw beschrijving.."
                                  id="description"
                                  v-model="card.description"
                                  :config="redactorConfig" />
                    </div>
                </div>

                <div class="field">
                    <div class="field-control">
                        <vue-multiselect v-model="card.descriptionColor"
                                         :options="colorHexCodes"
                                         :customLabel="customLabel"
                                         :close-on-select="true"
                                         placeholder="Kies een kleur voor de beschrijving tekst"
                                         :multiple=false />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import Redactor from '../../../components/redactor-text-area';
    import BaseService from '../../../services';

    export default {
        name: 'CallToActionsTab',
        props: {
            category: {
                required: true,
            },
        },
        components: {
            'vue-multiselect': Multiselect,
            Redactor,
        },
        data() {
            return {
                redactorConfig: {
                    buttons: ['bold', 'html', 'lists', 'italic'],
                },
                cards: [],
                colors: {
                    '#0a1f2f': 'Donkerblauw',
                    '#eff2f5': 'Grijs',
                    '#ffffff': 'Wit',
                    '#30AF4C': 'Groen',
                    '#DB4649': 'Rood',
                },
            };
        },
        mounted() {
            BaseService.get(`/categories/${this.category.id}/call-to-actions`)
                       .then(response => {
                           this.cards = response.data.data;
                       });

            this.$root.$on('save-ctas', this.save);
        },
        beforeDestroy() {
            this.$root.$off('save-ctas');
        },
        computed: {
            colorHexCodes() {
                return Object.keys(this.colors);
            },
        },
        methods: {
            fileInputChange(e, card) {
                const files = e.target.files || e.dataTransfer.files;
                const reader = new FileReader();

                if (!files.length) {
                    return;
                }
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    card.image = e.target.result;
                };
            },
            customLabel(hexCode) {
                return this.colors[hexCode];
            },
            save() {
                BaseService
                    .post(`/categories/${this.category.id}/call-to-actions`, {
                        callToActions: this.cards,
                    })
                    .then(value => {
                        this.$flashMessage('Call to Actions opgeslagen', 3500, 'success');
                    })
                    .catch(err => {
                        const errors = Object.entries(err.response.data.errors)
                                             .map((err) => err[1])
                                             .flat()
                                             .join('<br />');

                        this.$flashMessage(errors, 3500, 'error');
                    });
            },
        },
    };
</script>

<style lang="scss"
       scoped>
    .card {
        background    : white;
        border-radius : 4px;
        box-shadow    : 0px 2px 8px rgb(0 0 0 / 10%);
        border        : 1px solid rgba(0, 0, 0, 0.02);
        padding       : 34px;

        &:not(:last-child) {
            margin-bottom : 42px;
        }

        h1 {
            font-weight   : bold;
            font-size     : 20px;
            margin-bottom : 4px;
        }

        q {
            font-size   : 14px;
            opacity     : 0.7;
        }
    }

    ::v-deep {
        .redactor-styles {
            height : 200px;
        }
    }

    .field--row {
        .field-control {
            width  : 50%;
            height : fit-content;
        }
    }
</style>
