<template>
    <div class="product-detail">
        <div class="page-header">
            <h1 class="page-title">{{$t('choice_help.new_question')}}</h1>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('choice_help.name') }}</label>
                <div class="field-control">
                    <input type="text" v-model="name" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('choice_help.description') }}</label>
                <div class="field-control">
                    <textarea placeholder="Type hier uw beschijving tekst.." v-model="description"></textarea>
                </div>
            </div>
        </div>

        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('choice_help.save_question') }}
        </a>
    </div>
</template>
<script>
    import { ChoiceHelpService } from '../../../services';

    export default {
        name: 'choice-help-question-create',

        data() {
            return {
                name: '',
                description: '',
            };
        },

        methods: {
            save() {
                ChoiceHelpService.store({ name: this.name,description: this.description }).then(response => {
                    this.$router.push('/keuzehulpvragen/' + response.data.id + '/antwoorden');
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");
                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
    }
</script>
