import BaseService from './index';

export default {
    index(productId, searchQuery = '') {
        return BaseService.get(`/products/${productId}/related-products`, { searchQuery });
    },

    show(productId, relatedProductId) {
        return BaseService.get(`/products/${productId}/related-products/${relatedProductId}`,);
    },

    updatePositions(productId, relatedProductGroupPositions){
        return BaseService.post(`/products/${productId}/related-products/position`, { relatedProductGroups: relatedProductGroupPositions });
    },

    store(productId, params) {
        return BaseService.post(`/products/${productId}/related-products`, params);
    },

    update(productId, relatedProductId, params) {
        return BaseService.put(`/products/${productId}/related-products/${relatedProductId}`, params);
    },

    delete(productId, relatedProductId) {
        return BaseService.delete(`/products/${productId}/related-products/${relatedProductId}`);
    },
}
