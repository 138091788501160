export default {
    nl: {
        related_products: {
            tab_title: 'Gerelateerde producten',

            add_related_product: 'Nieuwe groep toevoegen',
            add_related_product_title: 'Nieuw gerelateerd product toevoegen',
            edit_related_product_title: 'Wijzig gerelateerd product',

            save: 'Opslaan',
            delete: 'Gerelateerde producten verwijderen',

            product: 'Product',
            title: 'Titel',

            option: 'Optie',

            showImage: 'Toon product afbeelding',
            showPriceDifference: 'Toon prijsverschil',

            save_success: 'Gerelateerde producten opgeslagen',
            update_success: 'Gerelateerde producten aangepast',

            delete_modal: {
                title: 'Gerelateerde producten verwijderen',
                content: 'Wil je deze gerelateerde producten echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
}
