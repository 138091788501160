import Vue from 'vue';
import Vuex from 'vuex';

import products from './product/products';
import configurations from './product/configurations';
import categories from './category/categories';
import choiceHelpQuestions from './choice-help/questions';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        products,
        configurations,
        categories,
        choiceHelpQuestions,
    }
});
