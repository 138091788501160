<template>
    <div ref="questionsInput">
        <div class="field-control field-control--tags" :class="{'field-control--tags--empty': ! selected.length}">
            <div v-for="item in selected" :key="item.id" class="tag">
                <div class="tag-title tag-title--push-bottom">
                    {{ item.name }}

                    <span class="tag-remove" @click="removeItem(item)">
                        <img src="/svgs/close-icon.svg" alt="close-icon" />
                    </span>
                </div>
            </div>

            <input type="text" v-model="search" @input="onSearchInput" @focus="onFocus()" />

            <ul class="tag-list" v-if="active">
                <li v-for="item in searchResults" class="tag-list-item" :key="item.id" @click="selectItem(item)"
                    :class="{'active': !!selected.find(c => c.id === item.id)}">
                    {{ item.name }}
                    <img src="/svgs/checkmark.svg" v-if="!!selected.find(c => c.id === item.id)" />
                </li>

                <li class="tag-list-item" v-if="! searchResults.length && searched">
                    No results
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { ChoiceHelpService } from '../../../services'

    export default {
        name: 'questions-input',

        props: ['value', 'maxSelection'],

        data() {
            return {
                active: false,
                search: null,
                searchResults: [],
                selected: this.value,
                searched: false,
            }
        },

        watch: {
            selected: function (newVal) {
                this.$emit('input', newVal);
            },

            maxSelection: function (newVal) {
                if (newVal === 1 && this.selected.length > 1) {
                    this.selected = [this.selected[0]];
                }
            },
        },

        methods: {
            onFocus(){
                this.active = true;
                this.onSearchInput();
            },
            onSearchInput: debounce(function () {
                this.searched = true;
                ChoiceHelpService.questions({
                    searchQuery: this.search,
                    questionId: this.$route.params.id
                }).then(response => {
                    this.searchResults = response.data;
                });
            }, 500),

            selectItem(item) {
                const max = parseInt(this.maxSelection);
                const i = this.selected.findIndex(c => c.id === item.id);

                if (max === 1) {
                    this.selected = [item];
                    return;
                }

                if (i !== -1) {
                    this.selected.splice(i, 1);
                    return;
                }

                if (this.selected.length === max) {
                    return;
                }

                this.selected.push(item);
            },

            removeItem(item) {
                const i = this.selected.findIndex(c => c.id === item.id);

                if (i !== -1) {
                    this.selected.splice(i, 1);
                }
            },
        },

        mounted() {
            document.addEventListener('click', (e) => {
                if (!this.active) {
                    return;
                }

                const questionsInput = this.$refs.questionsInput;
                let targetEl = e.target;

                do {
                    if (targetEl === questionsInput) {
                        return;
                    }

                    targetEl = targetEl.parentNode;
                } while (targetEl);

                this.active = false;
                this.searchResults = [];
                this.searched = false;
            });
        },
    }
</script>
