<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">{{ $t('filters.filters') }}</h1>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="$router.push({name: 'filters.create'})">
                    {{ $t('filters.new') }}
                </a>
            </div>
        </div>

        <div class="filters">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search"
                       type="text"
                       :placeholder="$t('general.search') + '...'"
                       v-model="searchQuery"
                       @input="onSearchInput" />
            </div>
        </div>

        <table class="table" ref="overviewTable">
            <thead>
                <tr>
                    <th>{{ $t('filters.title') }}</th>
                    <th>{{ $t('filters.options') }}</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="filterCategory in filters" :key="filterCategory.id" @click="showDetail(filterCategory)">
                    <td>{{ filterCategory.name }}</td>
                    <td>{{ filterCategory.filters.map(q => q.option).join(', ') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { FilterService } from '../../services';
    import debounce from 'lodash/debounce';

    export default {
        name: 'overview-filters',

        data() {
            return {
                filters: [],
                searchQuery: '',
            }
        },

        methods: {
            fetchFilters() {
                const params = {
                    filters: {
                        name: this.searchQuery,
                    }
                };

                const promise = FilterService.index(params);

                promise.then(response => {
                    this.filters = response.data;
                });

                return promise;
            },

            onSearchInput: debounce(function () {
                this.fetchFilters();
            }, 250),

            showDetail(filter) {
                this.$router.push('filters/' + filter.id)
            },
        },

        beforeMount() {
            this.fetchFilters();
        }
    }
</script>
