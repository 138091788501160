import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/categories`, params);
    },

    main(params) {
        return BaseService.get(`/categories/main`, params);
    },

    store(params) {
        return BaseService.post(`/categories`, params);
    },

    show(categoryId) {
        return BaseService.get(`/categories/${categoryId}`);
    },

    update(categoryId, params) {
        return BaseService.post(`/categories/${categoryId}`, params);
    },

    sortMethods() {
        return BaseService.get('/categories/sort-methods');
    },

    updateProducts(categoryId, params) {
        return BaseService.put(`/categories/${categoryId}/products`, params);
    },

    getProductsPosition(categoryId) {
        return BaseService.get(`/categories/${categoryId}/products`);
    },

    updatePowerpageEnabled(categoryId, enabled) {
        return BaseService.put(`/categories/${categoryId}/powerpage-enabled`, {
            enabled
        });
    },
}
