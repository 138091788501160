<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('specifications.add_specification') }}</h1>
            </div>

            <div>
                <a href="#" class="btn btn--link" v-if="$route.params.specification"
                   @click.prevent="isDeleteModalActive = true">
                    {{ $t('specifications.delete') }}
                </a>

                <a href="#" class="btn btn--orange" @click.prevent="saveProductSpecification">
                    {{ $t('specifications.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('specifications.category') }}</label>
                <div class="field-control">
                    <select v-model="specificationCategory">
                        <option v-for="item in specificationCategories" :value="item.id">{{ item.title }}</option>
                    </select>
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('specifications.key') }}</label>
                <div class="field-control">
                    <input type="text" v-model="specificationKey" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('specifications.value') }}</label>
                <div class="field-control">
                    <Redactor placeholder="Type hier uw tekst.." id="text-row-1" v-model="specificationValue"
                              :config="redactorConfig" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('specifications.explanation') }}</label>
                <div class="field-control">
                    <textarea placeholder="" v-model="specificationExplanation"></textarea>
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('specifications.delete_modal.title')"
                   :content="$t('specifications.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteProductSpecification" />
    </div>
</template>

<script>
    import { SpecificationService } from '../../services';
    import BaseModal from '../../components/BaseModal';
    import Redactor from '../../components/redactor-text-area';

    export default {
        name: 'product-specification',

        components: { BaseModal, Redactor },

        beforeRouteEnter(to, from, next) {
            if (to.params.specification) {
                SpecificationService.getSpecification(to.params.specification).then(response => {
                    next(vm => {
                        vm.specificationCategory = response.data.product_specification_category_id;
                        vm.specificationKey = response.data.key;
                        vm.specificationValue = response.data.value;
                        vm.specificationExplanation = response.data.explanation;
                    })
                }).catch((err) => {
                    next({
                        name: 'products.specification',
                        params: { id: to.params.id },
                    });
                });
            } else {
                next();
            }
        },

        data() {
            return {
                specificationCategories: [],
                specificationCategory: null,
                specificationKey: null,
                specificationValue: null,
                specificationExplanation: null,
                isDeleteModalActive: false,
                redactorConfig: {
                    buttons: ['link'],
                    maxHeight: '100px',
                    allowedTags: ['a'],
                    linebreaks: true,
                    deniedTags: ['br'],
                    enterKey: false,
                    breakline: true,
                },
            };
        },

        beforeMount() {
            this.fetchSpecificationCategories();
        },

        methods: {
            fetchSpecificationCategories() {
                SpecificationService.getProductSpecificationCategories(this.$route.params.id).then(response => {
                    this.specificationCategories = response.data;
                });
            },

            store(productId, params) {
                SpecificationService.store(productId, params).then(response => {
                    this.$flashMessage(this.$t('specifications.save_success'), 3500, 'success');
                    this.$router.push({ name: 'products.specification', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            update(specificationId, productId, params) {
                SpecificationService.update(specificationId, params).then(response => {
                    this.$flashMessage(this.$t('specifications.update_success'), 3500, 'success');
                    this.$router.push({ name: 'products.specification', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            saveProductSpecification() {
                const productId = this.$route.params.id;
                const specificationId = this.$route.params.specification;

                const params = {
                    key: this.specificationKey,
                    value: this.specificationValue,
                    explanation: this.specificationExplanation,
                    product_specification_category_id: this.specificationCategory,
                };

                if (!specificationId) {
                    this.store(productId, params);
                    return;
                }

                this.update(specificationId, productId, params);
            },

            deleteProductSpecification() {
                const productId = this.$route.params.id;
                const specificationId = this.$route.params.specification;

                SpecificationService.delete(specificationId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.specification',
                            params: { id: productId },
                        }
                    );
                });
            },
        },
    };
</script>
