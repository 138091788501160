<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search" type="text" v-model="searchQuery" @input="onSearchInput" />
            </div>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="createConfiguration">{{ $t('products.add_question') }}</a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('products.question') }}</th>
                    <th>{{ $t('products.question_type') }}</th>
                    <th></th>
                </tr>
            </thead>
        </table>

        <configurations-list v-if="configurations.length" :configurations="configurations" :level="1" @change="fetchConfigurations" />
    </div>
</template>

<script>
    import { ConfigurationService } from '../../../services';
    import debounce from 'lodash/debounce';
    import ConfigurationsList from '../components/ConfigurationsList';

    export default {
        name: 'product-configurations-tab',

        components: { ConfigurationsList },

        data() {
            return {
                searchQuery: '',
                configurations: [],
            };
        },

        beforeMount() {
            this.fetchConfigurations();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchConfigurations();
            }, 250),

            fetchConfigurations() {
                const productId = this.$route.params.id;
                this.configurations = [];

                ConfigurationService.getConfigurations(productId, this.searchQuery).then(response => {
                    // Timout required for refreshing table
                    setTimeout(() => {
                        this.configurations = response.data;
                    }, 50);
                });
            },

            createConfiguration() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.configurator.create',
                    params: {
                        id: productId,
                    },
                });
            },
        },
    };
</script>
