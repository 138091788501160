import BaseService from './index';

export default {
    getAccessories(productId, searchQuery = '') {
        return BaseService.get(`/products/${productId}/accessories`, { searchQuery });
    },

    getAccessory(accessoryId) {
        return BaseService.get(`/accessories/${accessoryId}/show`,);
    },

    store(productId, params) {
        return BaseService.post(`/products/${productId}/accessories`, params);
    },

    update(accessoryId, params) {
        return BaseService.put(`/accessories/${accessoryId}`, params);
    },

    delete(accessoryId) {
        return BaseService.delete(`/accessories/${accessoryId}`);
    },
}
