<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">{{ isCategory() ? $t('categories.create_category') :
                                   $t('categories.create_sub_category') }}</h1>

            <div>
                <a @click.prevent="isDeleteModalActive = true" class="btn btn--link" href="#" v-if="$route.params.id">
                    {{ $t('categories.delete') }}
                </a>

                <a @click.prevent="saveCategory()" class="btn btn--orange" href="#">
                    {{ isCategory() ? $t('categories.create_category') : $t('categories.create_sub_category') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field" v-if="!isCategory()">
                <label class="label">{{ $t('categories.option') }}</label>
                <div class="field-control">
                    <multiselect :options="options" label="name" placeholder="Selecteer een categorie" track-by="id"
                                 v-model="category" />
                </div>
            </div>
            <div class="field">
                <label class="label" for="name">Naam</label>
                <div class="field-control">
                    <input class="field-control" id="name" type="text" v-model="name">
                </div>
            </div>
            <div class="field">
                <label class="label" for="title">Categorie pagina titel</label>
                <div class="field-control">
                    <input class="field-control" id="title" type="text" v-model="title">
                </div>
            </div>
            <div class="field">
                <label class="label">{{ $t('categories.sort_method') }}</label>
                <div class="field-control">
                    <multiselect :options="sortMethods"
                                 label="label"
                                 placeholder="Selecteer een sorteer methode"
                                 track-by="id"
                                 v-model="sort_method" />
                </div>
            </div>
            <div class="field">
                <label class="label" for="url">Url</label>
                <div class="field-control">
                    <input class="field-control" id="url" type="text" v-model="url">
                </div>
            </div>
            <div class="field field--image">
                <label class="label">Afbeelding</label>
                <div class="field-control" v-if="image === null">
                    <label for="image-input" class="btn btn--blue">
                        {{ $t('images.add_image') }}
                    </label>

                    <input id="image-input"
                           name="images"
                           type="file"
                           accept="image/*"
                           @change="fileInputChange($event)">
                </div>
                <div class="field-control" v-else>
                    <img class="image" :src="image" alt="">
                    <div class="icon" @click="image = null">
                        <img src="/svgs/close-icon.svg" alt="close-icon">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CategoryService } from '../../services';
    import multiselect from 'vue-multiselect'

    export default {
        name: 'create-category',

        components: {
            multiselect,
        },


        beforeRouteEnter(to, from, next) {
            let sortMethods = [];
            CategoryService.sortMethods().then(response => {
                sortMethods = response.data;
            });
            let options = [];
            CategoryService.index().then(response => {
                options = response.data;
                if (to.params.id) {
                    CategoryService.show(to.params.id).then(response => {
                        next(vm => {
                            vm.options = options;
                            vm.name = response.data.name;
                            vm.category = response.data.categories.map(o => o.option);
                        })
                    }).catch((err) => {
                        next({ name: 'categories.index' });
                    });
                } else {
                    next(vm => {
                        vm.options = options;
                        vm.sortMethods = sortMethods;
                    });
                }
            });
        },

        data() {
            return {
                name: null,
                title: null,
                url: null,
                image: null,
                category: null,
                options: [],
                sortMethods: [],
                sort_method: null,
            }
        },

        methods: {
            isCategory() {
                return !this.$route.path.includes('subcategorie');
            },
            saveCategory() {
                let params = {
                    name: this.name,
                    title: this.title,
                    url: this.url,
                    image: this.image,
                    category_id: this.category ? this.category.id : null,
                    sort_method: this.sort_method ? this.sort_method.id : null,
                };
                CategoryService.store(params).then(() => {
                    this.$flashMessage(this.$t('categories.save_success'), 3500, 'success');
                    this.$router.push({ name: 'categories.index' })
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },
            fileInputChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                const reader = new FileReader();

                if (!files.length) {
                    return;
                }
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    this.image = e.target.result;
                };
            },
        },
    }
</script>
