import BaseService from './index';

export default {
    getProsAndCons(productId, searchQuery = '') {
        return BaseService.get(`/products/${productId}/pros-and-cons`, { searchQuery });
    },

    getItem(itemId) {
        return BaseService.get(`/pros-and-cons/${itemId}/show`);
    },

    store(productId, params) {
        return BaseService.post(`/products/${productId}/pros-and-cons`, params);
    },

    updatePosition(itemId, position){
        return BaseService.put(`/pros-and-cons/${itemId}/position`, { position });
    },

    update(itemId, params) {
        return BaseService.put(`/pros-and-cons/${itemId}`, params);
    },

    delete(itemId) {
        return BaseService.delete(`/pros-and-cons/${itemId}`);
    },
}
