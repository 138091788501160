<template>
    <div class="product-detail">
        <div class="page-header">
            <h1 class="page-title">{{$t('choice_help.question')}}{{'  ' + question.name }}</h1>
        </div>

        <div class="tabs tabs--push-less">
            <router-link :to="`/keuzehulpvragen/${question.id}/algemene-gegevens`">
                {{ $t('choice_help.general_information') }}
            </router-link>
            <router-link :to="`/keuzehulpvragen/${question.id}/antwoorden`">
                {{ $t('choice_help.answers') }}
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
    import store from '../../../store';

    export default {
        async beforeRouteEnter(to, from, next) {
            await store.dispatch('choiceHelpQuestions/fetch_question', to.params.id)
                .then(response => {
                    next();
                });
        },

        computed: {
            question() {
                return this.$store.state.choiceHelpQuestions.question;
            },
        },
    };
</script>
