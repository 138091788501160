export default {
    nl: {
        news: {
            news: 'Nieuws',
            tab_title: 'Accessoires',

            add_news: 'Nieuw artikel toevoegen',

            save: 'Nieuws artikel opslaan',
            delete: 'Nieuws artikel verwijderen',

            title: 'Titel',
            header_image: 'Hoofdafbeelding',
            text_row_1: 'Tekstvak 1',
            text_row_2: 'Tekstvak 2',
            first_row_images: 'Afbeeldingen rij 1',
            second_row_images: 'Afbeeldingen rij 2',
            youtube: 'Voeg het video ID van YouTube toe. Dit is de tekst na \'?v=\'.',
            products: 'Gerelateerde producten',
            category: 'Categorie',
            relevant_articles: 'Relevante nieuwsartikelen',

            save_success: 'Nieuws artikel opgeslagen',
            update_success: 'Nieuws artikel aangepast',

            image_size_error: 'Afbeelding moet kleiner zijn dan 16mb',

            delete_modal: {
                title: 'Artikel verwijderen',
                content: 'Wil je dit Nieuws artikel echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
}
