import BaseService from "../index";

export default {
    get() {
        return BaseService.get(`/cms/homepage`);
    },

    store(params) {
        return BaseService.put(`/cms/homepage`, params);
    },
};
