<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 0H2.5C1.12125 0 0 1.12125 0 2.5V17.5C0 18.8788 1.12125 20 2.5 20H17.5C18.8788 20 20 18.8788 20 17.5V2.5C20 1.12125 18.8788 0 17.5 0ZM2.5 17.5V2.5H17.5L17.5025 17.5H2.5Z" fill="black"/>
        <path d="M9.03954 11.2681L6.98098 9.17147L5.29453 10.8891L9.19936 14.8662L16 6.55732L14.1657 5L9.03954 11.2681Z" fill="black"/>
    </svg>
</template>

<script>
    export default {
        name: 'SingleChoice',
    };
</script>