<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search" type="text" v-model="searchQuery" @input="onSearchInput" />
            </div>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="createItem">{{ $t('pros_and_cons.add_item') }}</a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('pros_and_cons.title') }}</th>
                    <th>{{ $t('pros_and_cons.type') }}</th>
                </tr>
            </thead>

            <draggable tag="tbody" v-model="prosAndCons" @end="updatePosition">
                <tr v-for="prosAndConsItem in prosAndCons" :key="'pros_and_cons_' + prosAndConsItem.id" :id="prosAndConsItem.id" @click="editItem(prosAndConsItem.id)">
                    <td>{{ prosAndConsItem.title }}</td>
                    <td>{{ !! prosAndConsItem.is_pros ? $t('pros_and_cons.type_pros') : $t('pros_and_cons.type_con') }}</td>
                </tr>
            </draggable>
        </table>
    </div>
</template>

<script>
    import { ProsAndConsService } from '../../../services';
    import debounce from 'lodash/debounce';
    import draggable from 'vuedraggable'

    export default {
        name: 'product-pros-and-cons-tab',

        data() {
            return {
                searchQuery: '',
                prosAndCons: [],
            };
        },

        components: {
            draggable,
        },

        beforeMount() {
            this.fetchProsAndCons();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchProsAndCons();
            }, 250),

            fetchProsAndCons() {
                const productId = this.$route.params.id;

                ProsAndConsService.getProsAndCons(productId, this.searchQuery).then(response => {
                    this.prosAndCons = response.data;
                });
            },

            editItem(itemId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.pros-and-cons.edit',
                    params: {
                        id: productId,
                        item: itemId
                    }
                });
            },

            createItem() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.pros-and-cons.create',
                    params: {
                        id: productId
                    }
                });
            },

            updatePosition(e) {
                ProsAndConsService.updatePosition(this.prosAndCons[e.newIndex].id, e.newIndex + 1);
            },
        },
    }
</script>
