<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">{{ $t('discount_codes.codes') }}</h1>

            <div>
                <a href="#" @click.prevent="$router.push({path: '/kortingscodes/create'})" class="btn btn--orange">
                    {{ $t('discount_codes.new') }}
                </a>
            </div>
        </div>

        <div class="filters">
            <div class="search">
                <img alt="search-icon" src="/svgs/search-icon.svg">
                <input type="text" :placeholder="$t('general.search') + '...'" class="search" v-model="searchQuery" @input="onSearchInput" />
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('discount_codes.name') }}</th>
                    <th>{{ $t('discount_codes.percentage') }}</th>
                    <th>{{ $t('discount_codes.from') }}</th>
                    <th>{{ $t('discount_codes.till') }}</th>
                </tr>
            </thead>

            <tbody>
                <tr v-if="codes.length > 0" v-for="code in codes" @click="showDetail(code)">
                    <td>{{ code.code }}</td>
                    <td>{{ code.percentage }}%</td>
                    <td>{{ code.valid_from.split(' ')[0] }}</td>
                    <td>{{ code.valid_to.split(' ')[0] }}</td>
                </tr>

                <tr class="no-results" v-else>
                    <td>{{ $t('general.noResults') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { DiscountCodeService } from '../../services';
    import debounce from 'lodash/debounce';

    export default {
        name: 'discount-codes',

        data() {
            return {
                codes: [],
                searchQuery: '',
            }
        },

        beforeMount() {
            this.fetchCodes();
        },

        methods: {
            fetchCodes() {
                const params = {
                    search: this.searchQuery
                };

                const promise = DiscountCodeService.index(params);

                promise.then(response => {
                    this.codes = response.data;
                });

                return promise;
            },

            onSearchInput: debounce(function () {
                this.fetchCodes();
            }, 250),

            showDetail(code) {
                this.$router.push({
                    path: 'kortingscodes/' + code.id,
                })
            },
        },
    }
</script>
