import BaseService from "../index";

export default {
    get() {
        return BaseService.get(`/cms/about-us-page`);
    },

    update(params) {
        return BaseService.put(`/cms/about-us-page`, params);
    },
};
