import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/redirects`, params);
    },

    store(params) {
        return BaseService.post(`/redirects`, params);
    },

    show(redirectId) {
        return BaseService.get(`/redirects/${redirectId}`);
    },

    update(redirectId, params) {
        return BaseService.post(`/redirects/${redirectId}`, params);
    },

    destroy(redirectId) {
        return BaseService.post(`/redirects/${redirectId}/destroy`);
    },
}
