export default {
    nl: {
        discount_codes: {
            codes: 'Kortingscodes',
            new: 'Nieuwe kortingscode toevoegen',
            name: 'Kortingscode',
            percentage: 'Percentage',
            from: 'Geldig Vanaf',
            till: 'Geldig Tot',
            save: 'Kortingscode opslaan',
            save_success: 'Kortingscode succesvol opgeslagen',
            show_in_actionbanner: 'Toon in actiebanner',
        },
    },
};
