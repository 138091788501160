<template>
    <div>
        <div class="field field--push-harder field--image">
            <label class="label">Afbeelding</label>
            <div class="field-control" v-if="image === null">
                <label for="image-input" class="btn btn--blue">
                    {{ $t('pages.returns_and_refunds_page.add_image') }}
                </label>

                <input id="image-input"
                       name="images"
                       type="file"
                       accept="image/*"
                       @change="fileInputChange($event)">
            </div>
            <div class="field-control" v-else>
                <div class="image-container">
                    <img class="image" :src="image" alt="">
                    <div class="icon" @click="image = null">
                        <img src="/svgs/close-icon.svg" alt="close-icon">
                    </div>
                </div>
            </div>
        </div>

        <div>
            <button class="btn btn--orange" @click="save">
                {{ $t('general.save') }}
            </button>
        </div>
    </div>
</template>

<script>
    import ReturnsAndRefundsPageService from '../../services/cms/returns-and-refunds-page';

    export default {
        data() {
            return {
                image: null
            };
        },
        methods: {
            save() {
                ReturnsAndRefundsPageService.update({ image: this.image }).then(_ => {
                    this.$flashMessage('Succesvol opgeslagen', 3500, 'succes')
                }).catch(err => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                })
            },

            fileInputChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                const reader = new FileReader();

                if (!files.length) {
                    return;
                }
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    this.image = e.target.result;
                };
            },
        },
        async mounted() {
            const data = (await ReturnsAndRefundsPageService.get()).data.data;

            if (data != null && data.image != null) {
                this.image = data.image;
            }
        },
    }
</script>
