<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('specifications.categories.title') }}</h1>
            </div>

            <div>
                <a href="#" class="btn btn--orange" @click.prevent="saveProductSpecificationCategory">
                    {{ $t('specifications.categories.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('specifications.categories.title') }}</label>
                <div class="field-control">
                    <input type="text" v-model="title" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { SpecificationService } from '../../services';

    export default {
        name: 'product-specification',

        beforeRouteEnter(to, from, next) {
            if (to.params.category) {
                SpecificationService.getSpecificationCategory(to.params.category).then(response => {
                    next(vm => {
                        vm.title = response.data.title;
                    })
                });
            } else {
                next();
            }
        },

        data() {
            return {
                title: null,
            };
        },

        methods: {
            store(productId, params) {
                SpecificationService.storeCategory(productId, params).then(response => {
                    this.$flashMessage(this.$t('specifications.categories.save_success'), 3500, 'success');
                    this.$router.push({ name: 'products.specification', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            update(categoryId, productId, params) {
                SpecificationService.updateCategory(categoryId, params).then(response => {
                    this.$flashMessage(this.$t('specifications.categories.update_success'), 3500, 'success');
                    this.$router.push({ name: 'products.specification', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            saveProductSpecificationCategory() {
                const productId = this.$route.params.id;
                const categoryId = this.$route.params.category;

                const params = {
                    title: this.title,
                };

                if (!categoryId) {
                    this.store(productId, params);
                    return;
                }

                this.update(categoryId, productId, params);
            },
        },
    };
</script>
