<template>
    <div class="sub-panel">
        <div class="sub-panel-heading">
            <h3 class="sub-panel-title">{{ title }}</h3>
            <div class="ordering">
                <img v-if="this.localConfigurationOption.order > 1" src="/svgs/up-arrow.svg" class="order order-up" @click="moveOrderUp" />
                <img v-if="!this.isLastConfigurationOption" src="/svgs/down-arrow.svg" class="order order-down" @click="moveOrderDown" />
            </div>
            <options-menu>
                <a @click.prevent="deleteConfigurationOption"
                   href="#">Verwijderen</a>
            </options-menu>
        </div>

        <div class="field">
            <div class="field-control">
                <input type="text"
                       v-model="localConfigurationOption.title" />
            </div>
            <div class="error-message" v-if="errors[`${errorKey}title`]">{{ errors[`${errorKey}title`][0] }}</div>
        </div>

        <div v-if="isColorQuestion"
             class="field-wrapper field-wrapper--2">
            <div class="field">
                <label class="label">Kleurcode 1</label>
                <color-picker-input v-model="localConfigurationOption.color" />
            </div>

            <span class="grey-text grey-text--center">En</span>

            <div class="field">
                <label class="label">Kleurcode 2</label>
                <color-picker-input :disabled="! localConfigurationOption.color || localConfigurationOption.color.length === 0"
                                    v-model="localConfigurationOption.secondary_color" />
            </div>
        </div>

        <div v-if="isColorQuestion"
             @click="selectCustomColor"
             class="field field--checkbox">
            <input :checked="localConfigurationOption.has_custom_color"
                   type="checkbox" />
            <div class="field-control">
                <label>{{ $t('configurations.has_custom_color') }}</label>
            </div>
            <div class="error-message two-column" v-if="errors[`${errorKey}color`]">{{ errors[`${errorKey}color`][0] }}</div>
        </div>

        <div class="field"
             v-if="configurationOption.has_custom_color">
            <label class="label">{{ $t('configurations.custom_color_label_1') }}</label>
            <div class="field-control">
                <input type="text"
                       v-model="localConfigurationOption.custom_color_label_1" />
            </div>
            <div class="error-message" v-if="errors[`${errorKey}custom_color_label_1`]">{{ errors[`${errorKey}custom_color_label_1`][0] }}</div>
        </div>

        <div class="field"
             v-if="configurationOption.has_custom_color">
            <label class="label">{{ $t('configurations.custom_color_label_2') }}</label>
            <div class="field-control">
                <input type="text"
                       v-model="localConfigurationOption.custom_color_label_2"
                       :disabled="! localConfigurationOption.custom_color_label_1 || localConfigurationOption.custom_color_label_1.length === 0" />
            </div>
        </div>

        <div class="field field--upload field--row">
            <div>
                <label class="label image-label">
                    Afbeelding
                    <img class="trash"
                         v-if="localConfigurationOption.image != null"
                         @click="uploadStateChanged(null)"
                         src="/img/trash.svg" />
                </label>
                <div v-if="! localConfigurationOption.image">
                    <div class="field">
                        <label :for="'image-' + uuid"
                               class="btn btn--blue">
                            Voeg een afbeelding toe
                        </label>

                        <input :id="'image-' + uuid"
                               @change="uploadStateChanged"
                               accept="image/*"
                               type="file" />
                    </div>
                </div>

                <img :src="localConfigurationOption.image.path || localConfigurationOption.image"
                     v-else />
            </div>
            <div>
                <label class="label image-label">
                    Preview
                    <img class="trash"
                         v-if="localConfigurationOption.preview_image != null"
                         @click="uploadStateChanged(null, true)"
                         src="/img/trash.svg" />
                </label>
                <div v-if="!localConfigurationOption.preview_image">
                    <div class="field">
                        <label :for="'image-preview-' + uuid"
                               class="btn btn--blue">
                            Voeg een nieuwe preview toe
                        </label>

                        <input :id="'image-preview-' + uuid"
                               @change="uploadStateChanged($event, true)"
                               accept="image/*"
                               type="file" />
                    </div>
                </div>

                <img :src="localConfigurationOption.preview_image.path || localConfigurationOption.preview_image"
                     v-else />
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('articles.article') }}</label>
            <ArticlesInput :max-selection="Infinity"
                           v-model="localConfigurationOption.articles" />
            <div class="error-message" v-if="errors[`${errorKey}articles`]">{{ errors[`${errorKey}articles`][0] }}</div>
        </div>

        <div class="field sub-field">
            <div class="article-quantity-list">
                <div class="empty column"></div>
                <div class="label column">{{ $t('articles.quantity') }}</div>
                <template v-for="(article, index) in localConfigurationOption.articles">
                    <div class="article label column">
                        <span class="title">{{ article.title }}</span>
                        {{ article.code }}
                    </div>
                    <div class="quantity field-control column">
                        <input :name="article.id" v-model="article.quantity" type="number" @wheel="blockScroll($event)">
                    </div>
                    <div class="two-column error-message" v-if="errors[`${errorKey}articles.${index}.quantity`]">{{ errors[`${errorKey}articles.${index}.quantity`][0] }}</div>
                </template>
            </div>
        </div>

        <div @click="selectDefault"
             class="field field--checkbox">
            <input :checked="!!configurationOption.is_default"
                   type="checkbox" />
            <div class="field-control">
                <label>{{ $t('configurations.is_default_configuration_option') }}</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import ColorPickerInput from '../../../components/color-picker-input';
    import OptionsMenu from '../../../components/options-menu';
    import { uuid } from '../../../helpers';
    import ArticlesInput from './ArticlesInput';
    import ProductInput from './ProductInput';

    export default {
        name: 'configurationOption',

        components: {
            ArticlesInput,
            ProductInput,
            OptionsMenu,
            ColorPickerInput,
        },

        props: {
            title: {
                type: String,
                required: true,
            },

            configurationOption: {
                type: Object,
                required: true,
            },

            isColorQuestion: {
                type: Boolean,
                required: false,
                default: false,
            },

            isMultipleChoice: {
                type: Boolean,
                required: false,
                default: false,
            },

            isLastConfigurationOption: {
                type: Boolean,
                default: false,
            },

            errorKey: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                localConfigurationOption: this.configurationOption,
                uuid: uuid(),
            };
        },

        watch: {
            'localConfigurationOption.custom_color_label_1': function (newVal) {
                if (newVal.length === 0) {
                    this.localConfigurationOption.custom_color_label_2 = '';
                }
            },

            'localConfigurationOption.has_custom_color': function (val) {
                if (!val) {
                    this.localConfigurationOption.custom_color_label_1 = '';
                    this.localConfigurationOption.custom_color_label_2 = '';
                }
            },
        },

        methods: {
            blockScroll(event) {
                event.target.blur();
            },

            moveOrderUp() {
                this.localConfigurationOption.order--;
                this.$emit('orderChanged', { order: this.localConfigurationOption.order, direction: 'up' });
            },

            moveOrderDown() {
                this.localConfigurationOption.order++;
                this.$emit('orderChanged', { order: this.localConfigurationOption.order, direction: 'down' });
            },

            selectCustomColor() {
                this.localConfigurationOption.has_custom_color = !this.localConfigurationOption.has_custom_color;
            },

            deleteConfigurationOption() {
                this.$emit('delete');
            },

            selectDefault() {
                if(!this.isMultipleChoice) {
                    this.$emit('changedDefault');
                    this.localConfigurationOption.is_default = true;
                } else {
                    this.localConfigurationOption.is_default = !this.localConfigurationOption.is_default;
                }
            },

            uploadStateChanged(e, isPreview = false) {
                if (e === null && window.confirm('Wil je deze foto echt verwijderen?')) {
                    if (isPreview) {
                        this.createPreview(null);
                    } else {
                        this.createImage(null);
                    }
                }

                const files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    return;
                }

                if (isPreview) {
                    this.createPreview(files[0]);
                } else {
                    this.createImage(files[0]);
                }
            },

            createPreview(file) {
                if (file === null) {
                    this.localConfigurationOption.preview_image = null;
                    return;
                }

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.localConfigurationOption.preview_image = e.target.result;
                };

                reader.readAsDataURL(file);
            },

            createImage(file) {
                if (file === null) {
                    this.localConfigurationOption.image = null;
                    return;
                }

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.localConfigurationOption.image = e.target.result;
                };

                reader.readAsDataURL(file);
            },
        },
        computed: {
            errors() {
                const filtered = Object.entries(this.$store.state.configurations.errors).filter(([key, value]) => {
                    return key.startsWith(this.errorKey);
                });

                return Object.fromEntries(filtered);
            },
        },
    };
</script>

<style lang="scss"
       scoped>
    .image-label {
        min-width   : 100px;
        display     : flex;
        align-items : center;

        .trash {
            width       : 16px;
            height      : 16px;
            margin-left : 8px;
        }
    }

    .article-quantity-list {
        margin-top            : 10px;
        display               : grid;
        grid-template-columns : auto 100px;
        align-items           : center;

        .column {
            margin : 5px 0;
        }

        .two-column {
            grid-column: span 2;
        }
    }
</style>
