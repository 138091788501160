export default {
	nl: {
		faq: {
			name: 'FAQ',
			add: 'Vraag toevoegen',
			delete: 'Verwijderen',
			category: 'Categorie',
			question: 'Vraag',
			answer: 'Antwoord',
			save: 'Opslaan',
			update_success: 'Opslaan voltooid',
			delete_modal_title: 'Vraag verwijderen',
			delete_modal_content: 'Wil je deze vraag echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
		},
	},
}
