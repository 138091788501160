<template>
    <div class="content">
        <table class="table">
            <thead>
                <tr>
                    <th>
                        Aantal
                    </th>
                    <th>
                        Kortingspercentage
                    </th>
                    <th>
                        Startdatum
                    </th>
                    <th>
                        Einddatum (optioneel)
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(discount, index) in graduatedDiscounts">
                    <td class="field">
                        <div class="field-control" :class="{'has-error': hasError(index, 'quantity')}">
                            <input type="number"
                                   min="0"
                                   max="100"
                                   v-model="discount.quantity">
                        </div>
                    </td>
                    <td class="field">
                        <div class="field-control" :class="{'has-error': hasError(index, 'discountPercentage')}">
                            <input type="number"
                                   min="0"
                                   max="100"
                                   v-model="discount.discountPercentage">
                        </div>
                    </td>
                    <td class="field">
                        <div class="field-control" :class="{'has-error': hasError(index, 'startDate')}">
                            <date-picker :format="dateFormat" v-model="discount.startDate" name="startDate" />
                        </div>
                    </td>
                    <td class="field">
                        <div class="field-control" :class="{'has-error': hasError(index, 'endDate')}">
                            <date-picker clear-button
                                         wrapper-class="date-picker-wrapper"
                                         :format="dateFormat"
                                         v-model="discount.endDate"
                                         name="endDate" />
                        </div>
                    </td>
                    <td class="actions">
                        <button @click="removeGraduatedDiscount(index)" class="btn btn--red">
                            Verwijder
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <button @click="addGraduatedDiscount" :disabled="saving" class="btn btn--secondary" :class="{disabled: saving}">
            Voeg nieuwe korting toe
        </button>

        <button @click="save" :disabled="saving" class="btn btn--orange" :class="{disabled: saving}">
            Opslaan
        </button>
    </div>
</template>

<script>
    import moment from 'moment';
    import DatePicker from 'vuejs-datepicker';

    export default {
        name: 'ProductPricesGraduated',
        components: {
            DatePicker,
        },

        data() {
            return {
                errors: {},
                graduatedDiscounts: [],
                saving: false,
            };
        },

        created() {
            axios.get(`/products/${this.$route.params.id}/graduated-discounts`)
                 .then(response => {
                     this.graduatedDiscounts = response.data.data;
                 });
        },

        methods: {
            save() {
                this.errors = {};
                this.saving = true;

                axios
                    .post(`/products/${this.$route.params.id}/graduated-discounts`, {
                        graduatedDiscounts: this.graduatedDiscounts.map(graduatedDiscount => {
                            graduatedDiscount.startDate = moment(graduatedDiscount.startDate).format('YYYY-MM-DD');
                            graduatedDiscount.endDate = graduatedDiscount.endDate ? moment(graduatedDiscount.endDate).format('YYYY-MM-DD') : null;
                            graduatedDiscount.quantity = parseInt(graduatedDiscount.quantity);

                            return graduatedDiscount;
                        }),
                    })
                    .then(response => {
                        this.graduatedDiscounts = response.data.data;

                        this.$flashMessage('Succesvol opgeslagen', 3500, 'succes');
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors;

                        const errors = Object.entries(this.errors).flatMap(([field, errors]) => {
                            return errors.filter(e => e != null);
                        });

                        this.$flashMessage(errors.join('<br>'), 3500, 'error');
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },

            hasError(index, field) {
                return this.errors['graduatedDiscounts.' + index + '.' + field] != null && this.errors['graduatedDiscounts.' + index + '.' + field].length > 0;
            },

            dateFormat(date) {
                return moment(date).format('DD-MM-YYYY');
            },

            addGraduatedDiscount() {
                this.graduatedDiscounts.push({
                    quantity: 0,
                    discountPercentage: 0,
                    startDate: moment().toDate(),
                    endDate: null,
                });
            },

            removeGraduatedDiscount(index) {
                this.graduatedDiscounts.splice(index, 1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .content {
        display        : flex;
        flex-direction : column;
        gap            : 16px;

        .table {
            .field {
                padding : 8px;
            }

            .actions {
                width : 1%;
            }
        }

        .btn {
            width : fit-content;
        }

        .has-error {
            border-color : #DC3545;
        }

        ::v-deep {
            .date-picker-wrapper {
                & > div:first-child {
                    display        : flex;
                    flex-direction : row;
                    align-items    : center;
                    padding-right  : 12px;
                }
            }

            .vdp-datepicker {
                input {
                    height : 64px;
                }
            }
        }
    }
</style>
