import BaseService from './index';

export default {
    getProductSpecificationCategories(productId) {
        return BaseService.get(`/products/${productId}/specification-categories`);
    },

    getSpecifications(productId, searchQuery = '') {
        return BaseService.get(`/products/${productId}/specifications`, { searchQuery });
    },

    getSpecification(specificationId) {
        return BaseService.get(`/specifications/${specificationId}/show`);
    },

    store(productId, params) {
        return BaseService.post(`/products/${productId}/specifications`, params);
    },

    update(specificationId, params) {
        return BaseService.put(`/specifications/${specificationId}`, params);
    },

    updatePosition(specificationId, position) {
        return BaseService.put(`/specifications/${specificationId}/position`, { position });
    },

    delete(specificationId) {
        return BaseService.delete(`/specifications/${specificationId}`);
    },

    getSpecificationCategory(categoryId) {
        return BaseService.get(`/specification-categories/${categoryId}/show`);
    },

    storeCategory(productId, params) {
        return BaseService.post(`/products/${productId}/specification-categories`, params);
    },

    updateCategory(categoryId, params) {
        return BaseService.put(`/specification-categories/${categoryId}`, params);
    },
}
