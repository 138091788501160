<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search" type="text" v-model="searchQuery" @input="onSearchInput" />
            </div>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="createAccessory">{{ $t('accessories.add_accessory') }}</a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('accessories.product') }}</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="accessoryItem in accessories" :key="'accessory_' + accessoryItem.id" @click="editAccessory(accessoryItem.id)">
                    <td>{{ accessoryItem.product.name }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { AccessoryService } from '../../../services';
    import debounce from 'lodash/debounce';

    export default {
        name: 'product-accessories-tab',

        data() {
            return {
                searchQuery: '',
                accessories: [],
            };
        },

        beforeMount() {
            this.fetchAccessories();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchAccessories();
            }, 250),

            fetchAccessories() {
                const productId = this.$route.params.id;

                AccessoryService.getAccessories(productId, this.searchQuery).then(response => {
                    this.accessories = response.data;
                });
            },

            editAccessory(accessoryId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.accessories.edit',
                    params: {
                        id: productId,
                        accessoryProduct: accessoryId
                    }
                });
            },

            createAccessory() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.accessories.create',
                    params: {
                        id: productId
                    }
                });
            },
        },
    }
</script>
