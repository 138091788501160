<template>
  <div>
    <textarea ref="redactor" :name="name" :placeholder="placeholder" :value="value" />
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'redactor-text-area',

  props: {
    value: {
      default: '',
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    config: {
      default: {},
      type: Object
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (!this.redactor.editor.isFocus()) this.redactor.source.setCode(newValue)
    }
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.destroy()
  },

  methods: {
    init() {
      let self = this;
      let callbacks = {
        changed: function (html) {
          self.handleInput(html);
          return html
        }
      };
      Vue.set(this.config, 'callbacks', callbacks);

      let app = $R(this.$refs.redactor, this.config);

      this.redactor = app;
      this.$parent.redactor = app;
    },

    destroy() {
      $R(this.$refs.redactor, 'destroy');
      this.redactor = null;
      this.$parent.redactor = null;
    },

    handleInput(val) {
      this.$emit('input', val);
    }
  }
}
</script>
