<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title"
                    v-if="isEdit">{{ $t('related_products.edit_related_product_title') }}</h1>
                <h1 class="page-title"
                    v-else>{{ $t('related_products.add_related_product_title') }}</h1>
            </div>

            <div>
                <a href="#"
                   class="btn btn--link"
                   v-if="$route.params.relatedProductGroup"
                   @click.prevent="isDeleteModalActive = true">
                    {{ $t('related_products.delete') }}
                </a>

                <a href="#"
                   class="btn btn--orange"
                   @click.prevent="save">
                    {{ $t('related_products.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="panel">
                <div class="field">
                    <label for="title"
                           class="label">
                        {{ $t('related_products.title') }}
                    </label>
                    <div class="field-control">
                        <input type="text"
                               id="title"
                               v-model="relatedProductGroup.title" />
                    </div>
                </div>

                <div class="field field--checkbox">
                    <input type="checkbox"
                           id="showImage"
                           v-model="relatedProductGroup.showImage" />
                    <div class="field-control">
                        <label for="showImage"
                               class="label">
                            {{ $t('related_products.showImage') }}
                        </label>
                    </div>
                </div>

                <div class="field field--checkbox">
                    <input type="checkbox"
                           id="showPriceDifference"
                           v-model="relatedProductGroup.showPriceDifference" />
                    <div class="field-control">
                        <label for="showPriceDifference"
                               class="label">
                            {{ $t('related_products.showPriceDifference') }}
                        </label>
                    </div>
                </div>

                <div class="relatedProducts">
                    <draggable tag="div"
                               v-model="relatedProductGroup.relatedProducts">
                        <div class="relatedProduct sub-panel"
                             v-for="(relatedProduct, idx) in relatedProductGroup.relatedProducts">
                            <div class="sub-panel-heading">
                                <h3 class="sub-panel-title">{{ $t('related_products.option') }}</h3>

                                <options-menu>
                                    <a href="#"
                                       @click.prevent="deleteOption(idx)">Verwijderen</a>
                                </options-menu>
                            </div>

                            <div class="field">
                                <label :for="`relatedProductTitle-${idx}`"
                                       class="label">
                                    {{ $t('related_products.title') }}
                                </label>
                                <div class="field-control">
                                    <input type="text"
                                           :id="`relatedProductTitle-${idx}`"
                                           v-model="relatedProduct.title" />
                                </div>
                            </div>

                            <div class="field">
                                <label class="label">{{ $t('relevant_products.product') }}</label>
                                <div class="field-control">
                                    <multiselect v-model="relatedProduct.product"
                                                 :options="products"
                                                 label="name"
                                                 track-by="id"
                                                 :loading="isFetchingProducts"
                                                 :internal-search="false"
                                                 @search-change="searchMultiselectProducts"
                                                 placeholder="Selecteer product" />
                                </div>
                            </div>
                        </div>
                    </draggable>

                    <div class="new-row"
                         @click="addRelatedProduct">
                        <div class="new-row__circle">
                            <img src="/svgs/plus.svg">
                        </div>

                        <span>{{ $t('filters.add_filter') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('related_products.delete_modal.title')"
                   :content="$t('related_products.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteRelatedProduct" />
    </div>
</template>

<script>
    import axios from "axios";
    import debounce from "lodash/debounce";
    import Multiselect from "vue-multiselect";
    import draggable from "vuedraggable";
    import BaseModal from "../../components/BaseModal";
    import OptionsMenu from "../../components/options-menu";
    import { ProductService, RelatedProductGroupService } from "../../services";

    export default {
        name: "related-products",

        components: {
            OptionsMenu,
            Multiselect,
            BaseModal,
            draggable,
        },

        computed: {
            isEdit() {
                return this.$route.name === 'products.related-products.edit';
            },
        },

        data() {
            return {
                relatedProductGroup: {
                    title: '',
                    showImage: false,
                    showPriceDifference: false,
                    relatedProducts: [],
                },
                products: [],
                isFetchingProducts: true,
                cancelToken: null,
                isDeleteModalActive: false,
            };
        },

        beforeMount() {
            if (this.isEdit) {
                this.fetchRelatedProductGroup();
            }

            this.fetchProducts();
        },

        methods: {
            async fetchProducts() {
                ProductService.index().then(response => {
                    this.products = response.data.data.filter(p => p.id !== parseInt(this.$route.params.id));
                });

                this.isFetchingProducts = false;
            },

            searchMultiselectProducts: debounce(function (query) {
                if (this.cancelToken !== null) {
                    this.cancelToken.cancel();
                }

                this.cancelToken = axios.CancelToken.source();

                this.isFetchingProducts = true;

                const params = {
                    filters: {
                        name: query,
                    },
                };

                const options = {
                    cancelToken: this.cancelToken.token
                };

                ProductService.index(params, options).then(response => {
                    this.products = response.data.data;
                    this.isFetchingProducts = false;
                });
            }, 350),

            async fetchRelatedProductGroup() {
                const response = await RelatedProductGroupService.show(this.$route.params.id, this.$route.params.relatedProductGroup);

                this.relatedProductGroup = response.data.data;
            },

            async save() {
                const params = {
                    title: this.relatedProductGroup.title,
                    showImage: this.relatedProductGroup.showImage,
                    showPriceDifference: this.relatedProductGroup.showPriceDifference,
                    relatedProducts: this.relatedProductGroup.relatedProducts.map((product, idx) => {
                        return {
                            id: product.id != null ? product.id : null,
                            position: idx,
                            title: product.title,
                            productId: product.product.id,
                        };
                    }),
                };

                let response;

                if (this.isEdit) {
                    response = await RelatedProductGroupService.update(this.$route.params.id, this.$route.params.relatedProductGroup, params)

                    this.relatedProductGroup = response.data.data;
                } else {
                    response = await RelatedProductGroupService.store(this.$route.params.id, params);
                    this.relatedProductGroup = response.data.data;

                    this.$router.replace({
                        name: 'products.related-products.edit',
                        params: {
                            id: this.$route.params.id,
                            relatedProductGroup: response.data.data.id,
                        }
                    });
                }
            },

            addRelatedProduct() {
                this.relatedProductGroup.relatedProducts.push({
                    id: null,
                    title: '',
                    productId: null,
                });
            },

            deleteOption(index) {
                this.relatedProductGroup.relatedProducts.splice(index, 1);
            },

            deleteRelatedProduct() {
                const productId = this.$route.params.id;
                const relatedProductGroupId = this.$route.params.relatedProductGroup;

                RelatedProductGroupService.delete(productId, relatedProductGroupId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.related-products',
                            params: {
                                id: productId
                            },
                        },
                    );
                });
            },
        },
    }
</script>

<style scoped>

</style>
