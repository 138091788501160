import markerSDK from "@marker.io/browser";
import Vue from 'vue';
import store from './store';
import router from './routes';
import i18n from './lang';
import moment from 'moment';
import VueRouter from 'vue-router';
import messages from './plugins/messages';

import '../lib/redactor/redactor.min';
import '../lib/redactor/_plugins/video/video.min';

require('./bootstrap');

moment.locale('nl');

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(messages);

let app = new Vue({
    el: '#app',
    router,
    i18n,
    store,
});

markerSDK.loadWidget({
    destination: process.env.MIX_MARKER_DESTINATION,
});

window.axios.interceptors.response.use(response => response, function (error) {
    if (error.response.status === 500) {
        app.$flashMessage('interne server fout', 3500, 'error');
    }
    return Promise.reject(error);
});
