<template>
    <div class="modal-container">
        <div class="modal-background"></div>

        <div class="modal-wrapper" @click="close">
            <div class="modal" @click.stop>
                <slot>
                    <h1 v-if="title" v-html="title"></h1>

                    <p v-if="content" v-html="content"></p>

                    <div v-if="isConfirmation" class="btn-wrap btn-wrap--right btn-wrap--push-top">
                        <a href="#" class="btn btn--link" @click.prevent="close">{{ $t('general.cancel') }}</a>
                        <a href="#" class="btn btn--primary" @click.prevent="confirm">{{ $t('general.confirm') }}</a>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'base-modal',

        props: {
            isConfirmation: {
                type: Boolean,
                required: false,
                default: false,
            },

            title: {
                type: String,
                required: false,
                default: '',
            },

            content: {
                type: String,
                required: false,
                default: '',
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },

            confirm() {
                this.$emit('confirm');
            },
        },
    }
</script>
