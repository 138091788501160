export default {
    nl: {
        specifications: {
            tab_title: 'Specificaties',

            add_category: 'Categorie toevoegen',
            add_specification: 'Nieuwe specificatie toevoegen',

            category: 'Categorie',
            key: 'Key',
            value: 'Value',
            explanation: 'Uitleg',

            save: 'Specificatie toevoegen',
            delete: 'Specificatie verwijderen',

            save_success: 'Specificatie opgeslagen',
            update_success: 'Specificatie aangepast',

            delete_modal: {
                title: 'Specificatie verwijderen',
                content: 'Wil je dit specificatie echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },

            categories: {
                title: 'Categorie',
                save: 'Categorie toevoegen',

                save_success: 'Specificatie categorie opgeslagen',
                update_success: 'Specificatie categorie aangepast',
            },
        },
    },
}
