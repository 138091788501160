import ConfigurationOption from '../../models/products/ConfigurationOption';
import Configuration from '../../models/products/Configuration';
import { ConfigurationService } from '../../services';
import store from '../index';

const state = {
    configuration: {},
    selectedConfiguration: {},
    selectedConfigurationOffset: '',
    errors: {},
};

const getters = {
    configuration: state => state.configuration,
    selectedConfiguration: state => state.selectedConfiguration,
};

const actions = {
    fetch_configuration({ commit }, configurationId) {
        const promise = ConfigurationService.getConfiguration(configurationId);
        promise.then(response => {
            commit('set_configuration', response.data.data);
        });

        return promise;
    },

    store({ commit }) {
        let promise;

        if (!state.configuration.exists()) {
            commit('set_errors', {});

            promise = ConfigurationService.store(state.configuration.product_id, state.configuration);

            promise.catch((err) => {
                commit('set_errors', err.response.data.errors);
            });

            return promise;
        } else {
            promise = ConfigurationService.update(state.configuration.id, state.configuration);

            commit('set_errors', {});

            promise.then(response => {
                commit('set_configuration', Configuration.makeEmpty(state.configuration.product_id));

                setTimeout(() => {
                    commit('set_configuration', response.data.data);
                }, 1);
            }).catch((err) => {
                commit('set_errors', err.response.data.errors);
            });

            return promise;
        }
    },
};

const mutations = {
    set_errors(state, errors) {
        state.errors = errors;
    },
    set_configuration(state, configuration) {
        state.configuration = configuration instanceof Configuration ? configuration : Configuration.make(configuration);
        state.selectedConfiguration = state.configuration;
    },
    set_selected_configuration(state, {configuration, offset = ''}) {
        state.selectedConfigurationOffset = offset;
        state.selectedConfiguration = configuration;
    },
    add_new_configuration_option(state, configuration) {
        configuration.configuration_options.push(ConfigurationOption.makeEmpty(configuration.id));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
