<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('relevant_products.add_relevant_product_title') }}</h1>
            </div>

            <div>
                <a href="#" class="btn btn--link" v-if="$route.params.relevantProduct" @click.prevent="isDeleteModalActive = true">
                    {{ $t('relevant_products.delete') }}
                </a>

                <a href="#" class="btn btn--orange" @click.prevent="saveRelevantProduct">
                    {{ $t('relevant_products.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('relevant_products.product') }}</label>
                <div class="field-control">
                    <multiselect v-model="relevantProduct"
                                 :options="products"
                                 label="name"
                                 track-by="id"
                                 :loading="isFetchingProducts"
                                 :internal-search="false"
                                 @search-change="searchMultiselectProducts"
                                 placeholder="Selecteer product" />
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('relevant_products.delete_modal.title')"
                   :content="$t('relevant_products.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteRelevantProduct" />
    </div>
</template>

<script>
    import { ProductService, RelevantProductService } from '../../services';
    import BaseModal from '../../components/BaseModal';
    import Multiselect from 'vue-multiselect';
    import debounce from 'lodash/debounce';
    import axios from 'axios';

    export default {
        name: 'relevant-product',

        components: { BaseModal, Multiselect },

        beforeRouteEnter(to, from, next) {
            if (to.params.relevantProduct) {
                RelevantProductService.getRelevantProduct(to.params.id, to.params.relevantProduct).then(response => {
                    next(vm => {
                        vm.relevantProduct = response.data.product;
                    });
                }).catch((err) => {
                    next({
                        name: 'products.relevant-products',
                        params: { id: to.params.id },
                    });
                });
            } else {
                next();
            }
        },

        data() {
            return {
                relevantProduct: null,
                products: [],
                isDeleteModalActive: false,
                isFetchingProducts: false,
                cancelToken: null,
            };
        },

        beforeMount() {
            this.fetchProducts();
        },

        methods: {
            fetchProducts() {
                ProductService.index().then(response => {
                    this.products = response.data.data.filter(p => p.id !== parseInt(this.$route.params.id));
                });
            },

            searchMultiselectProducts: debounce(function (query) {
                if (this.cancelToken !== null) {
                    this.cancelToken.cancel();
                }

                this.cancelToken = axios.CancelToken.source();

                this.isFetchingProducts = true;

                const params = {
                    filters: {
                        name: query,
                    },
                };

                const options = {
                    cancelToken: this.cancelToken.token
                };

                ProductService.index(params, options).then(response => {
                    this.products = response.data.data;
                    this.isFetchingProducts = false;
                });
            }, 350),

            store(productId, params) {
                RelevantProductService.store(productId, params).then(response => {
                    this.$flashMessage(this.$t('relevant_products.save_success'), 3500, 'success');
                    this.$router.push({ name: 'products.relevant-products', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                                         .map((err) => err[1])
                                         .flat()
                                         .join('<br />');

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            update(relevantProductId, productId, params) {
                RelevantProductService.update(relevantProductId, params).then(response => {
                    this.$flashMessage(this.$t('relevant_products.update_success'), 3500, 'success');
                    this.$router.push({ name: 'products.relevant-products', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                                         .map((err) => err[1])
                                         .flat()
                                         .join('<br />');

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            saveRelevantProduct() {
                const productId = this.$route.params.id;
                const relevantProductId = this.$route.params.relevantProduct;

                const params = {
                    relevant_product_id: this.relevantProduct.id,
                };

                if (!relevantProductId) {
                    this.store(productId, params);
                    return;
                }

                this.update(relevantProductId, productId, params);
            },

            deleteRelevantProduct() {
                const productId = this.$route.params.id;
                const relevantProductId = this.$route.params.relevantProduct;

                RelevantProductService.delete(relevantProductId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.relevant-products',
                            params: { id: productId },
                        },
                    );
                });
            },
        },
    };
</script>
