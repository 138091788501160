<template>
    <div class="options-menu"
         :class="{'active': active}"
         @click="active = !active"
         ref="optionsMenu">
        <img src="/svgs/options.svg" />

        <div class="options-menu-items">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'options-menu',

        data() {
            return {
                active: false,
            };
        },

        mounted() {
            document.addEventListener('click', (e) => {
                if (!this.active) {
                    return;
                }

                const optionsMenu = this.$refs.optionsMenu;
                let targetEl = e.target;

                do {
                    if (targetEl === optionsMenu) {
                        return;
                    }

                    targetEl = targetEl.parentNode;
                } while (targetEl);

                this.active = false;
            });
        },
    }
</script>
