<template>
    <div class="category-detail">
        <div class="page-header">
            <h1 class="page-title">{{ category.name }}</h1>
            <a @click.prevent="save" class="btn btn--orange" :class="{saving}" href="#">
                {{ $t('categories.save_data') }}
            </a>
        </div>

        <div class="tabs tabs--push-less">
            <router-link :to="`/categorieen/${category.id}/algemene-gegevens`">
                {{ $t('general.general_information') }}
            </router-link>
            <router-link v-if="category.category_id === null" :to="`/categorieen/${category.id}/labels`">
                {{ $t('categories.category_dividers') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/seo-content`">
                {{ $t('categories.seo_content') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/instellingen`">
                {{ $t('categories.settings') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/filters`">
                {{ $t('categories.filters') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/producten`">
                {{ $t('categories.products') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/meta`">
                {{ $t('categories.meta') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/powerpage`">
                {{ $t('categories.powerpage') }}
            </router-link>
            <router-link v-if="category.category_id === null" :to="`/categorieen/${category.id}/keuzehulp`">
                {{ $t('categories.choice_help') }}
            </router-link>
            <router-link :to="`/categorieen/${category.id}/call-to-actions`">
                {{ $t('categories.ctas') }}
            </router-link>
        </div>
        <router-view :category="category" />
    </div>
</template>

<script>
    import store from '../../../store';

    export default {
        async beforeRouteEnter(to, from, next) {
            await store.dispatch('categories/fetch_category', to.params.id);

            next();
        },
        beforeRouteLeave(to, from, next) {
            this.$store.commit('categories/clear_category');
            next();
        },
        name: 'CategoryDetail',
        data() {
            return {
                saving: false,
            };
        },
        computed: {
            category() {
                return this.$store.state.categories.category;
            },
        },
        methods: {
            async save() {
                if (this.$route.name === 'categories.ctas') {
                    this.$root.$emit('save-ctas');

                    return;
                } else if (this.$route.name === 'categories.products') {
                    this.$root.$emit('save-category-products');

                    return;
                }

                this.saving = true;
                this.$store.dispatch('categories/update_category')
                    .then(async () => {
                        await this.$store.dispatch('categories/fetch_category', this.$route.params.id);
                        this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                    })
                    .catch(err => {
                        const errors = Object.entries(err.response.data.errors)
                                             .map((err) => err[1])
                                             .flat()
                                             .join('<br />');

                        this.$flashMessage(errors, 3500, 'error');
                    })
                    .finally(() => {
                        this.saving = false;
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .btn {
        opacity    : 1;
        transition : opacity ease-out 0.3s;

        &.saving {
            opacity : 0.7;
            cursor  : progress;
        }
    }
</style>
