<template>
    <div>
        <sidebar></sidebar>

        <div class="content-wrap">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import sidebar from './../../components/SideBar';

    export default {
        name: 'dashboard-layout',

        components: {
            sidebar,
        }
    }
</script>
