import { uuid } from '../../helpers';

export default class ConfigurationType {
    constructor(id, title) {
        this.id = !id ? null : id;
        this.title = title;
    }

    static make(obj) {
        return new this(obj.id, obj.title);
    }

    static makeEmpty() {
        return new this(null, '');
    }

    exists() {
        return this.id !== null;
    }
}
