export default {
    nl: {
        accessories: {
            tab_title: 'Accessoires',

            add_accessory: 'Nieuw item toevoegen',
            add_accessories_title: 'Nieuwe accessoires toevoegen',

            save: 'Item toevoegen',
            delete: 'Accessoires verwijderen',

            product: 'Product',

            save_success: 'Accessoires opgeslagen',
            update_success: 'Accessoires aangepast',

            delete_modal: {
                title: 'Accessoires verwijderen',
                content: 'Wil je dit accessoires echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
}
