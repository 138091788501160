<template>
    <div>
        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('choice_help.name') }}</label>
                <div class="field-control">
                    <input type="text" v-model="name" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('choice_help.description') }}</label>
                <div class="field-control">
                    <textarea placeholder="Beschrijving.." v-model="description"></textarea>
                </div>
            </div>
        </div>

        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('choice_help.save_question') }}
        </a>
    </div>
</template>

<script>
    import store from '../../../../store';
    import { ChoiceHelpService } from '../../../../services';

    export default {
        async beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.name = store.state.choiceHelpQuestions.question.name;
                vm.description = store.state.choiceHelpQuestions.question.description;
            });
        },

        data() {
            return {
                name: null,
                desc: null,
            };
        },

        methods: {
            save() {
                ChoiceHelpService.update(this.$route.params.id, {
                    name: this.name,
                    description: this.description
                }).then(response => {
                    this.$store.commit('questions/set_question', response.data);
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
    }
</script>
