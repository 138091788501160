import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/choice-help`, params)
    },

    all() {
        return BaseService.get(`/choice-help/all`)
    },

    store(params) {
        return BaseService.post(`/choice-help`, params)
    },

    show(questionId) {
        return BaseService.get(`/choice-help/${questionId}`);
    },

    update(questionId, params) {
        return BaseService.post(`/choice-help/${questionId}`, params);
    },

    questions(questionId){
        return BaseService.get(`/choice-help/questions`, questionId)
    }
}
