<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img alt="search-icon" src="/svgs/search-icon.svg">
                <input @input="onSearchInput" class="search" type="text" v-model="searchQuery" />
            </div>

            <div>
                <a @click.prevent="createDivider()" class="btn btn--primary" href="#">
                    {{ $t('categories.dividers.create') }}
                </a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('categories.dividers.name') }}</th>
                </tr>
            </thead>
            <tr :id="divider.id" :key="'divider_' + divider.id"
                @click="editDivider(divider.id)"
                v-for="divider in dividers">
                <td>{{ divider.divider_label }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import { DividerService } from '../../../services';
    import debounce from 'lodash/debounce';

    export default {
        name: 'product-specifications-tab',

        data() {
            return {
                searchQuery: '',
                dividers: null,
            };
        },

        beforeMount() {
            this.fetchDividers();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchDividers();
            }, 250),

            fetchDividers() {
                const categoryId = this.$route.params.id;

                DividerService.index(categoryId,{search: this.searchQuery}).then(response => {
                    this.dividers = response.data;
                });
            },

            editDivider(dividerId) {
                const routeId = this.$route.params.id;

                this.$router.push({
                    name: 'categories.dividers.edit',
                    params: {
                        id: Number(routeId),
                        divider: Number(dividerId)
                    }
                });
            },

            createDivider() {
                const categoryId = this.$route.params.id;

                this.$router.push({
                    name: 'categories.dividers.create',
                    params: {
                        id: categoryId
                    }
                });
            },
        },
    }
</script>
