import { uuid } from '../../helpers';
import ConfigurationOption from './ConfigurationOption';
import ConfigurationType from './ConfigurationType';

export default class Configuration {
    constructor(id, product_id, configuration_option_id, configuration_type, title, description, is_summary, is_required, order, configuration_options) {
        this.id = id;
        this.product_id = product_id;
        this.configuration_option_id = configuration_option_id;
        this.configuration_type = configuration_type ? ConfigurationType.make(configuration_type) : ConfigurationType.makeEmpty();
        this.title = title;
        this.description = description;
        this.is_summary = is_summary;
        this.is_required = is_required;
        this.order = order;
        this.configuration_options = configuration_options.map(co => ConfigurationOption.make(co)) || [];
    }

    static make(obj) {
        return new this(obj.id, obj.product_id, obj.configuration_option_id, obj.configuration_type, obj.title, obj.description, obj.is_summary, obj.is_required, obj.order, obj.configuration_options);
    }

    static makeEmpty(product_id, configuration_option_id, is_summary = false) {
        return new this(null, product_id, configuration_option_id,null, '', '', is_summary, false, 0,[]);
    }

    exists() {
        return this.id !== null;
    }
}
