export default {
    nl: {
        powerpages: {
            title: 'Powerpages',
            slug: 'Slug',
            add_power_page: 'Nieuwe powerpage toevoegen',
            save: 'Powerpage opslaan',
            offline: 'Haal offline',
            online: 'Zet online',
            status: 'Status',
            update_success: 'Powerpage succesvol opgeslagen',
            save_success: 'Powerpage opgeslagen',
            delete: 'Powerpage verwijderen',
            delete_modal: {
                title: 'Powerpage verwijderen',
                content: 'Wil je deze powerpage echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
};
