<template>
    <div class="powerpage-input-fields">
        <label class="label" v-if="componentInput.input.type !== 'checkbox'">{{ componentInput.title }}</label>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'text'">
            <input :placeholder="componentInput.placeholder"
                   v-model="inputValue"
                   type="text">
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'textarea'">
                    <textarea v-model="inputValue"
                              :placeholder="componentInput.placeholder" />
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'checkbox'"
             @click="toggleCheckbox">
            <input :checked="inputValue" v-model="inputValue" value="1" type="checkbox">
            <label>{{ componentInput.placeholder }}</label>
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'image'">
            <file-input v-model="inputValue"
                        @base64Updated="$emit('base64Updated', $event)"
                        file-type="image/*">
                <template #default="{image}">
                    <img :src="image"
                         alt="Image"
                         v-if="image">
                    <img :alt="componentInput.name"
                         :src="componentInput.value"
                         v-else-if="!!componentInput.value">
                    <div class="img-placeholder"
                         v-else />
                    <span>
                        {{ componentInput.placeholder }}
                    </span>
                </template>
            </file-input>
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'wysiwyg'">
            <redactor-text-area :placeholder="componentInput.placeholder"
                                v-model="inputValue"
                                :config="redactorConfig" />
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type ==='relations'">

            <vue-multiselect
                :options="relations"
                label="powerpageLabel"
                track-by="id"
                :close-on-select="false"
                :taggable="true"
                :placeholder="componentInput.placeholder"
                @search-change="searchRelations"
                :value="inputValue"
                @input="changeValue"
                :multiple="multipleRelationsAllowed" />
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'number'">
            <input :placeholder="componentInput.placeholder"
                   v-model="inputValue"
                   type="number">
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'date'">
            <input :placeholder="componentInput.placeholder"
                   v-model="inputValue"
                   type="date">
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'select'">
            <select :placeholder="componentInput.placeholder"
                    v-model="inputValue">
                <option v-for="option in componentInput.selectOptions"
                        :value="option.key">
                    {{ option.value }}
                </option>
            </select>
        </div>
        <div class="field-control"
             :class="{'error' : error[0]}"
             v-if="componentInput.input.type === 'color'">
            <color-picker-input v-model="inputValue" />
        </div>

        <div class="error-message" v-if="error[0]">{{ error[0] }}</div>
    </div>
</template>
<script>
    import ComponentInputRelationsService from '../services/powerpages-component-inputs-relations';
    import ColorPickerInput from './color-picker-input';
    import FileInput from './FileInput';
    import RedactorTextArea from './redactor-text-area';
    import VueMultiselect from 'vue-multiselect';

    export default {
        name: 'PowerpageInputFields',
        components: {
            FileInput,
            RedactorTextArea,
            VueMultiselect,
            ColorPickerInput,
        },
        props: {
            componentInput: {},
            component: {},
            isElement: {
                type: Boolean,
                default: false,
            },
            index: {
                type: Number,
            },
            multipleRelationsAllowed: {
                type: Boolean,
                default: true,
            },
            error: {
                type: Array,
                default() {
                    return [];
                },
            },
        },
        created() {
            if (this.componentInput.input.type === 'relations') {
                this.searchRelations();
            }

            if (this.componentInput.defaultValue != null && this.inputValue == null) {
                if (this.componentInput.input.type === 'checkbox') {
                    this.componentInput.defaultValue = this.componentInput.defaultValue === 'true';
                }

                this.inputValue = this.componentInput.defaultValue;
            }
        },
        data() {
            return {
                relations: [],
                redactorConfig: {
                    buttons: ['bold', 'html', 'lists', 'italic', 'link'],
                },
                inputValue: this.isElement
                    ? (this.componentInput.value == null ? undefined : this.componentInput.value[this.index])
                    : this.componentInput.value,
            };
        },
        watch: {
            inputValue() {
                let toInput = this.inputValue;

                if (this.isElement) {
                    toInput = this.componentInput.value ? this.componentInput.value : [];
                    toInput[this.index] = this.inputValue;
                }

                this.$set(this.componentInput, 'value', this.inputValue);
                this.$emit('input', toInput);
            },
        },
        methods: {
            toggleCheckbox() {
                this.inputValue = !this.inputValue;
            },
            changeValue(value) {
                this.inputValue = value;
            },
            searchRelations(value = null) {
                if(value === null && this.relations.length > 0){
                    return;
                }

                if (this.componentInput.input.type === 'relations') {
                    const params = [];

                    if (value !== null) {
                        params.search = value;
                    }

                    ComponentInputRelationsService.index(this.componentInput.id, params)
                                                  .then(response => {
                                                      this.relations = response.data;
                                                  });
                }
            },
        },
    };
</script>
