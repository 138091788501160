<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search" type="text" v-model="searchQuery" @input="onSearchInput" />

            </div>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="createRelevantProduct">{{ $t('relevant_products.add_relevant_product') }}</a>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('relevant_products.product') }}</th>
                </tr>
            </thead>

            <draggable tag="tbody" v-model="relevantProducts" @end="updatePosition">
                <tr v-for="relevantProduct in relevantProducts" :key="'relevant_product_' + relevantProduct.id" :id="relevantProduct.id" @click="editRelevantProduct(relevantProduct.id)">
                    <td>{{ relevantProduct.product.name }}</td>
                </tr>
            </draggable>
        </table>
    </div>
</template>

<script>
    import { RelevantProductService } from '../../../services';
    import debounce from 'lodash/debounce';
    import draggable from 'vuedraggable'

    export default {
        name: 'relevant-products-tab',

        data() {
            return {
                searchQuery: '',
                relevantProducts: [],
            };
        },

        components: {
            draggable,
        },

        beforeMount() {
            this.fetchRelevantProducts();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchRelevantProducts();
            }, 250),

            fetchRelevantProducts() {
                const productId = this.$route.params.id;

                RelevantProductService.getRelevantProducts(productId, this.searchQuery).then(response => {
                    this.relevantProducts = response.data;
                });
            },

            editRelevantProduct(relevantProductId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.relevante-producten.edit',
                    params: {
                        id: productId,
                        relevantProduct: relevantProductId
                    }
                });
            },

            createRelevantProduct() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.relevante-producten.create',
                    params: {
                        id: productId
                    }
                });
            },

            updatePosition(e) {
                RelevantProductService.updatePosition(this.relevantProducts[e.newIndex].id, e.newIndex + 1);
            },
        },
    }
</script>
