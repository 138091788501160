export default {
    nl: {
        relevant_products: {
            tab_title: 'Relevant products',

            add_relevant_product: 'Nieuw item toevoegen',
            add_relevant_product_title: 'Nieuw relevant product toevoegen',

            save: 'Item toevoegen',
            delete: 'Relevante producten verwijderen',

            product: 'Product',

            save_success: 'Relevante producten opgeslagen',
            update_success: 'Relevante producten aangepast',

            delete_modal: {
                title: 'Relevante producten verwijderen',
                content: 'Wil je dit relevante producten echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
}
