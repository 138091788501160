export function uuid(prefix) {
    return (prefix || '_') + (new Date().getUTCMilliseconds().toString() + new Date().getTime().toString()).toString();
}

/**
 * Checks if value is empty.
 *
 * @param value
 *
 * @return {boolean}
 */
export function empty(value) {
    if (typeof value === 'function') {
        return false;
    }

    return typeof value === 'undefined'
        || value === null
        || (
            typeof value.length !== 'undefined'
            && value.length === 0
        )
        || value === 0;
}


/**
 * Append the given data to the given form data.
 */
export function appendToFormData(formData, key, value) {
    if (value == null) {
        return;
    }

    if (value instanceof File) {
        formData.append(key, value);
        return;
    }

    if (Array.isArray(value)) {
        value.forEach((val, index) => {
            appendToFormData(formData, `${key}[${index}]`, val);
        });
        return;
    }

    if (typeof value === 'object' && !empty(value)) {
        Object.entries(value).forEach(([k, val]) => {
            appendToFormData(formData, `${key}[${k}]`, val);
        });
        return;
    }

    formData.append(key, value);
}
