import BaseService from './index';

const baseParams = {
    baseURL: process.env.MIX_APP_URL,
};

export default {
    index(componentInputId, params) {
        return BaseService.get(`/powerpages/componentInputs/${componentInputId}/relations`, params, baseParams)
    },
}
