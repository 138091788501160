<template>
    <div class="product-detail">
        <div class="page-header page-header--push-top">
            <h1 class="page-title">{{$t('static_page.title')}}</h1>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('static_page.url') }}</label>
                <div class="field-control">
                    <input type="text" v-model="item.url" />
                </div>
            </div>
            <div class="field">
                <label class="label">{{ $t('static_page.meta_title') }}</label>
                <div class="field-control">
                    <input type="text" v-model="item.meta_title" />
                </div>
            </div>
            <div class="field">
                <label class="label">{{ $t('static_page.meta_description') }}</label>
                <div class="field-control">
                    <textarea placeholder="" v-model="item.meta_description"></textarea>
                </div>
            </div>

        </div>
        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('redirects.save') }}
        </a>
        <a href="#" class="btn btn--link" v-if="item.id" @click.prevent="deleteModal = true">
            {{ $t('redirects.delete') }}
        </a>
        <BaseModal v-if="deleteModal"
                   :is-confirmation="true"
                   :title="$t('static_page.delete_modal_title')"
                   :content="$t('static_page.delete_modal_content')"
                   @close="deleteModal = false"
                   @confirm="deleteItem()" />
    </div>
</template>
<script>
    import { RedirectService, StaticPageService } from "../../services";
    import BaseModal from '../../components/BaseModal';

    export default {
        name: 'redirects-show',
        components: {
            BaseModal
        },

        data() {
            return {
                deleteModal: false,
                item: {},
            };
        },

        beforeMount() {
            this.fetchItem();
        },

        methods: {
            fetchItem() {
                if (this.$route.path !== '/static/create') {
                    StaticPageService.show(this.$route.params.id).then(response => {
                        this.item = response.data;
                    })
                }
            },

            deleteItem() {
                StaticPageService.destroy(this.item.id).then(response => {
                    this.$router.push({ path: '/static' });
                })
            },

            save() {
                let promise;
                if (this.item.id) {
                    promise = StaticPageService.update(this.item.id, this.item).then(response => {
                        this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                        this.$router.push({ path: '/static' });
                    });
                } else {
                    promise = StaticPageService.store(this.item).then(response => {
                        this.redirect = response.data;
                        this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                        this.$router.push({ path: '/static' });
                    });
                }

                promise.catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");
                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
    }
</script>
