<template>
    <div>
        <div class="page-header">
            <div>
                <h1 v-if="$route.params.configuration"
                    class="page-title">{{ $t('configurations.edit_configuration_title') }}</h1>
                <h1 v-else
                    class="page-title">
                    {{ $t('configurations.new_configuration_title') }}
                </h1>
                <h2 class="page-title--sub">
                    <router-link :to="'/producten/' + product.id">
                        {{ product.name }}
                    </router-link>
                </h2>
            </div>

            <div>
                <a href="#" class="btn btn--link" v-if="$route.params.configuration" @click.prevent="isDeleteModalActive = true">
                    {{ $t('configurations.delete') }}
                </a>

                <a href="#" :class="{'disabled' : savingConfiguration}" class="btn btn--orange" @click.prevent="saveConfiguration">
                    {{ $t('configurations.save') }}
                </a>
            </div>
        </div>
        <div class="question-configurator">
            <configuration-tree :configuration="configuration" />
            <div class="configuration">
                <configuration-component />
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('configurations.delete_modal.title')"
                   :content="$t('configurations.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteConfiguration" />
    </div>
</template>

<script>
    import BaseModal from '../../components/BaseModal';
    import Configuration from '../../models/products/Configuration';
    import { ConfigurationService } from '../../services';
    import store from '../../store';
    import ConfigurationComponent from './components/Configuration';
    import ConfigurationTree from './components/ConfigurationTree';

    export default {
        name: 'product-configurator',

        components: {
            ConfigurationTree,
            BaseModal,
            ConfigurationComponent,
        },

        async beforeRouteEnter(to, from, next) {
            const productId = parseInt(to.params.id);

            await store.dispatch('products/fetch_product', productId);

            if (to.params.configuration) {
                store.dispatch('configurations/fetch_configuration', to.params.configuration).then((response) => {
                    next();
                });
            } else {
                store.commit('configurations/set_configuration', Configuration.makeEmpty(productId, null, true));
                next();
            }
        },

        data() {
            return {
                isDeleteModalActive: false,
                savingConfiguration: false,
            };
        },

        computed: {
            product() {
                return this.$store.state.products.product;
            },

            configuration() {
                return this.$store.state.configurations.configuration;
            },
        },

        methods: {
            saveConfiguration() {
                if(this.savingConfiguration)
                {
                   return false;
                }

                this.savingConfiguration = true;

                this.$store.dispatch('configurations/store').then(response => {
                    if (!response) {
                        return;
                    }
                    if (!this.configuration.exists()) {
                        this.$router.push({
                            name: 'products.configurator.edit',
                            params: {
                                id: this.$route.params.id,
                                configuration: response.data.data.id,
                            },
                        });

                        this.$flashMessage(this.$t('configurations.save_success'), 3500, 'success');
                    } else {
                        this.$flashMessage(this.$t('configurations.update_success'), 3500, 'success');
                    }

                    this.savingConfiguration = false;
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                                         .map((err) => err[1])
                                         .flat()
                                         .join('<br />');

                    this.$flashMessage(errors, 3500, 'error');

                    this.savingConfiguration = false;
                });
            },

            deleteConfiguration() {
                const productId = this.$route.params.id;
                const configurationId = this.$route.params.configuration;

                ConfigurationService.delete(configurationId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.configurator',
                            params: { id: productId },
                        },
                    );
                });
            },
        },
    };
</script>
