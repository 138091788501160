<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">Powerpage</h1>
            </div>

            <div>
                <a href="#"
                   class="btn btn--link"
                   v-if="$route.params.id"
                   @click.prevent="isDeleteModalActive = true">
                    {{ $t('powerpages.delete') }}
                </a>
                <a href="#"
                   :class="{btn: true, 'btn--green': pageOffline, 'btn--red': !pageOffline}"
                   @click.prevent="toggleOffline">
                    {{ $t('powerpages.' + (pageOffline ? 'online' : 'offline')) }}
                </a>
                <a href="#"
                   class="btn btn--orange btn-save-powerpage"
                   :class="{saving: isSavingPowerpage}"
                   @click.prevent="savePowerpage">
                    {{ $t('powerpages.save') }}
                </a>
            </div>
        </div>

        <PowerpageCreateForm :pageId="pageId ? parseInt(pageId) : null"
                             ref="powerpageForm"
                             :key="pageId ? parseInt(pageId) : null"
                             @powerpageSaved="setPowerpageId($event)"
                             @changedStatus="changePowerpageStatus($event)" />

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('powerpages.delete_modal.title')"
                   :content="$t('powerpages.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deletePowerpage" />
    </div>
</template>

<script>
    import BaseModal from '../../components/BaseModal';
    import PowerpageService from '../../services/powerpages';
    import PowerpageFilterService from '../../services/powerpages-filters';
    import PowerpageCreateForm from './PowerpageCreateForm';

    export default {
        name: 'PowerPagesCreate',

        components: {
            PowerpageCreateForm,
            BaseModal,

        },

        data() {
            return {
                isSavingPowerpage: false,
                isDeleteModalActive: false,
                pageId: null,
                pageOffline: true,
            };
        },

        created() {
            if (this.$route.params.id != null) {
                this.pageId = this.$route.params.id;
            }
        },
        methods: {
            async changePowerpageStatus(value) {
                this.pageOffline = value;
            },

            async setPowerpageId(value)
            {
                this.pageId = value;
            },

            async toggleOffline() {
                await this.$refs.powerpageForm.toggleOffline();
            },

            savePowerpage() {
                this.$refs.powerpageForm.resetErrors();

                if (this.isSavingPowerpage) {
                    return;
                }

                this.isSavingPowerpage = true;
                this.$refs.powerpageForm
                    .savePowerpage()
                    .finally(() => {
                        this.isSavingPowerpage = false;
                    });
            },

            orderChanged(movedComponent, event) {
                const order = event.order;
                const direction = event.direction;

                let component = this.sortedComponents.find((component) => {
                    return component.order === order && movedComponent !== component;
                });

                if (direction === 'down') {
                    component.order = order - 1;
                }

                if (direction === 'up') {
                    component.order = order + 1;
                }
            },

            deleteComponent(idx) {
                this.components.splice(idx, 1);
                for (let i = 0; i < this.components.length; i++) {
                    this.components[i].order = i + 1;
                }
            },

            async loadFilters() {
                const response = await PowerpageFilterService.index();

                this.filters = response.data.data;
            },

            deletePowerpage() {
                const id = this.$route.params.id;

                PowerpageService.delete(id).then(() => {
                    this.$flashMessage('Powerpage succesvol verwijderd', 3500, 'delete_success');
                    this.$router.push({ name: 'powerpages' });
                }).catch((err) => {
                    this.isDeleteModalActive = false;
                    this.$flashMessage(err.response.data.message, 3500, 'error');
                });
            },
        },
    };
</script>

<style scoped
       lang="scss">
    .header {
        margin-bottom : 48px;
    }

    .btn-save-powerpage {
        transition : opacity ease-out 0.2s;
        opacity    : 1;

        &.saving {
            opacity : 0.7;
            cursor  : progress;
        }
    }
</style>
