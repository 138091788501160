<template>
    <div class="category-detail">
        <div class="page-header">
            <h1 class="page-title" v-if="!discountCode.id">Nieuwe kortingscode</h1>
            <h1 class="page-title" v-else>kortingscode</h1>

            <a href="#" @click.prevent="save" class="btn btn--orange">
                {{ $t('discount_codes.save') }}
            </a>
        </div>

        <div class="half-size">
            <div class="panel">
                <div class="field">
                    <label class="label">{{ $t('discount_codes.name') }}</label>
                    <div class="field-control">
                        <input type="text" v-model="discountCode.code" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">{{ $t('discount_codes.percentage') }}</label>
                    <div class="field-control">
                        <input min="1" max="100" type="number" v-model="discountCode.percentage" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">{{ $t('discount_codes.from') }}</label>
                    <div class="field-control">
                        <date-picker :format="dateFormat" v-model="discountCode.valid_from" name="valid_from" />
                    </div>
                </div>

                <div class="field">
                    <label class="label">{{ $t('discount_codes.till') }}</label>
                    <div class="field-control">
                        <date-picker :format="dateFormat" v-model="discountCode.valid_to" name="valid_from" />
                    </div>
                </div>

                <div @click="toggleActionBanner" class="field field--checkbox">
                    <input :checked="!!discountCode.show_in_actionbanner" type="checkbox" />
                    <div class="field-control">
                        <label>{{ $t('discount_codes.show_in_actionbanner') }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DiscountCodeService } from '../../services';
    import DatePicker from 'vuejs-datepicker';
    import moment from "moment";

    export default {
        name: 'CategoryDetail',

        components: {
            DatePicker
        },

        data() {
            return {
                discountCode: {
                    percentage: 1,
                    code: null,
                    valid_to: null,
                    valid_from: null
                },
            }
        },

        created() {
            this.fetchDiscountCode();
        },

        methods: {
            toggleActionBanner() {
                return this.discountCode.show_in_actionbanner = !this.discountCode.show_in_actionbanner
            },

            dateFormat(date) {
                return moment(date).format('DD-MM-YYYY');
            },

            fetchDiscountCode() {
                if (this.$route.params.id !== 'create') {
                    DiscountCodeService.show(this.$route.params.id).then(response => {
                        this.discountCode = response.data;
                    });
                }
            },

            save() {
                this.discountCode.valid_to = new moment(this.discountCode.valid_to).format('YYYY-MM-DD');
                this.discountCode.valid_from = new moment(this.discountCode.valid_from).format('YYYY-MM-DD');

                if (this.$route.params.id !== 'create') {
                    DiscountCodeService.update(this.discountCode.id, this.discountCode).then(() => {
                        this.$flashMessage(this.$t('discount_codes.save_success'), 3500, 'success');
                    }).catch(err => {
                        const errors = Object.entries(err.response.data.errors)
                            .map((err) => err[1])
                            .flat()
                            .join("<br />");

                        this.$flashMessage(errors, 3500, 'error');
                    });

                } else {
                    DiscountCodeService.store(this.discountCode).then(response => {
                        this.$flashMessage(this.$t('discount_codes.save_success'), 3500, 'success');
                        this.$router.push({ path: '/kortingscodes/' + response.data.id })
                    }).catch(err => {
                        const errors = Object.entries(err.response.data.errors)
                            .map((err) => err[1])
                            .flat()
                            .join("<br />");

                        this.$flashMessage(errors, 3500, 'error');
                    });
                }
            }
        },
    }
</script>
