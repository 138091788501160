<template>
    <div>
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('pros_and_cons.create_title') }}</h1>
            </div>

            <div>
                <a href="#" class="btn btn--link" v-if="$route.params.item" @click.prevent="isDeleteModalActive = true">
                    {{ $t('pros_and_cons.delete') }}
                </a>

                <a href="#" class="btn btn--orange" @click.prevent="saveItem">
                    {{ $t('pros_and_cons.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('pros_and_cons.type') }}</label>
                <div class="field-control">
                    <select v-model="isPros">
                        <option :value="true">{{ $t('pros_and_cons.type_pros') }}</option>
                        <option :value="false">{{ $t('pros_and_cons.type_con') }}</option>
                    </select>
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('pros_and_cons.title') }}</label>
                <div class="field-control">
                    <input type="text" v-model="title" />
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('pros_and_cons.delete_modal.title')"
                   :content="$t('pros_and_cons.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteItem" />
    </div>
</template>

<script>
    import { ProsAndConsService } from '../../services';
    import BaseModal from '../../components/BaseModal';

    export default {
        name: 'product-pros-and-cons',

        components: { BaseModal },

        beforeRouteEnter(to, from, next) {
            if (to.params.item) {
                ProsAndConsService.getItem(to.params.item).then(response => {
                    next(vm => {
                        vm.isPros = response.data.is_pros;
                        vm.title = response.data.title;
                    })
                }).catch((err) => {
                    next({
                        name: 'products.pros-and-cons',
                        params: { id: to.params.id },
                    });
                });
            } else {
                next();
            }
        },

        data() {
            return {
                isPros: null,
                title: null,
                isDeleteModalActive: false,
            };
        },

        methods: {
            store(itemId, params) {
                ProsAndConsService.store(itemId, params).then(response => {
                    this.$flashMessage(this.$t('pros_and_cons.save_success'), 3500, 'success');
                    this.$router.push({ name: 'products.pros-and-cons', params: { id: itemId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            update(itemId, productId, params) {
                ProsAndConsService.update(itemId, params).then(response => {
                    this.$flashMessage(this.$t('pros_and_cons.update_success'), 3500, 'success');
                    this.$router.push({ name: 'products.pros-and-cons', params: { id: productId } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            saveItem() {
                const productId = this.$route.params.id;
                const itemId = this.$route.params.item;

                const params = {
                    title: this.title,
                    is_pros: !!this.isPros,
                };

                if (!itemId) {
                    this.store(productId, params);
                    return;
                }

                this.update(itemId, productId, params);
            },

            deleteItem() {
                const productId = this.$route.params.id;
                const itemId = this.$route.params.item;

                ProsAndConsService.delete(itemId).then(() => {
                    this.$router.push(
                        {
                            name: 'products.pros-and-cons',
                            params: { id: productId },
                        }
                    );
                });
            },
        },
    };
</script>
