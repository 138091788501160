<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">{{$t('news.news')}}</h1>

            <div>
                <a href="#" class="btn btn--primary" @click.prevent="$router.push({name: 'news.create'})">
                    {{ $t('news.add_news') }}
                </a>
            </div>
        </div>

        <div class="filters">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon">
                <input class="search"
                       type="text"
                       :placeholder="$t('general.search') + '...'"
                       v-model="searchQuery"
                       @input="onSearchInput" />
            </div>
        </div>

        <table class="table " ref="overviewTable">
            <thead>
                <tr>
                    <th class="status">{{ $t('products.status') }}</th>
                    <th>{{ $t('products.name') }}</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="article in newsArticles" :key="article.id" @click="showDetail(article)">
                    <td class="status">
                        <div class="indicator" :class="{'green': article.is_visible,'red': !article.is_visible}" />
                    </td>
                    <td>{{ article.title }}</td>
                </tr>
            </tbody>
        </table>

        <div class="pagination pagination--push-top" v-if="totalPages > 1">
            <span @click="paginate(n)" v-for="n in paginationPages" :class="{'active' : n === page}">{{ n }}</span>
        </div>
    </div>
</template>

<script>
    import { NewsArticleService } from '../../services';
    import debounce from 'lodash/debounce';

    export default {
        name: 'overview-nieuws-artikelen',

        data() {
            return {
                newsArticles: {},
                searchQuery: '',
                page: 1,
                totalPages: 1,
            }
        },

        computed: {
            paginationPages() {
                const totalPages = this.totalPages;
                const page = this.page;
                const pages = 17;

                let lowerLimit = Math.min(page, totalPages);
                let upperLimit = Math.min(page, totalPages);

                for (let b = 1; b < pages && b < totalPages;) {
                    if (lowerLimit > 1) {
                        lowerLimit--;
                        b++;
                    }

                    if (b < pages && upperLimit < totalPages) {
                        upperLimit++;
                        b++;
                    }
                }

                if (lowerLimit === 0 || upperLimit === 0) {
                    return [];
                }

                return Array(upperLimit - lowerLimit + 1).fill().map((_, idx) => lowerLimit + idx);
            },
        },

        methods: {
            fetchNewsArticles() {
                const params = {
                    page: this.page,
                    search: this.searchQuery,
                };

                const promise = NewsArticleService.index(params);

                promise.then(response => {
                    this.page = response.data.current_page;
                    this.totalPages = response.data.last_page;
                    this.newsArticles = response.data.data;
                });

                return promise;
            },

            onSearchInput: debounce(function () {
                let promise = this.fetchNewsArticles();
                promise.then(() => {
                    this.resetPagination();
                });
            }, 250),

            paginate(page) {
                this.page = page;
                this.fetchNewsArticles().then(response => {
                    const table = this.$refs.overviewTable;
                    table.scrollIntoView({ behavior: 'smooth' });
                });
            },

            resetPagination() {
                this.page = 1;
            },

            showDetail(article) {
                this.$router.push('nieuws/' + article.id);
            },
        },

        beforeMount() {
            this.fetchNewsArticles();
        }
    }
</script>
