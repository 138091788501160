<template>
    <div class="powerpage-pins-component">
        <template v-for="(componentInputMain, indexMain) in component.componentInputs">
            <PowerpageInputFields v-show="visibleField(componentInputMain)"
                                  class="field"
                                  :class="`field--${componentInputMain.input.type}`"
                                  :key="`input-${indexMain}`"
                                  v-if="!elements.includes(componentInputMain) && !images.includes(componentInputMain) && !isImageSpecificInput(componentInputMain.key)"
                                  :component-input="componentInputMain"
                                  :component="component"
                                  v-model="componentInputMain.value" />
        </template>
        <div v-for="(image, index) in images" class="image-container">
            <label class="label">Afbeelding {{ index + 1 }}</label>
            <div class="picture"
                 :class="`picture--${index + 1}`">
                <div class="preview">
                    <img :src="image.previewImage" v-if="image.previewImage" />
                    <svg v-else
                         class="empty"
                         xmlns="http://www.w3.org/2000/svg" id="image" viewBox="0 0 48.89 41.84">
                        <path fill="#FFFFFF"
                              d="M.39,45.38V3.53H49.28V45.38ZM46,36.77a4.75,4.75,0,0,0,.22-.87c0-8.88,0-17.75,0-26.63,0-2-1.05-2.24-2.67-2.23-12.32,0-24.64,0-37,0-2,0-2.7.68-2.69,2.69.08,8.43,0,16.87,0,25.3,0,.47.1.93.19,1.72.8-.93,1.37-1.59,1.93-2.26L17.12,21.09c1.95-2.34,3.07-2.36,5.16-.1,1.5,1.61,3,3.24,4.48,4.85,2,2.12,2.41,2.14,4.7.24s2.65-2,4.83.32Z"
                              transform="translate(-0.39 -3.53)" />
                        <path fill="#FFFFFF" d="M42.67,15.78a5.28,5.28,0,1,1-5.41-5.2A5.32,5.32,0,0,1,42.67,15.78Z" transform="translate(-0.39 -3.53)" />
                    </svg>
                    <div class="pins" :key="keyCounter">
                        <svg v-for="pinIndex in numberOfElements['e' + (index + 1)]"
                             :key="`pin_${pinIndex}`"
                             v-if="component.componentInputs.find(input => input.key === `element_${index + 1}_pin_position_x`).value"
                             :style="`top:${pinTopPosition(index, pinIndex)}%;left:${pinLeftPosition(index, pinIndex)}%;`"
                             class="pin"
                             xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                            <circle id="Ellipse_276" data-name="Ellipse 276" cx="20" cy="20" r="20" fill="#9ba0a9" opacity="0.5" />
                            <circle id="Ellipse_277" data-name="Ellipse 277" cx="10" cy="10" r="10" transform="translate(10 10)" fill="#db4649" />
                        </svg>
                    </div>
                </div>
                <div class="inputs">
                    <PowerpageInputFields class="field"
                                          :key="`input-image-${index}`"
                                          :component-input="imageByIndex(index + 1)"
                                          :component="component"
                                          @base64Updated="base64Updated(imageByIndex(index + 1), $event)"
                                          v-model="imageByIndex(index + 1).value" />
                    <PowerpageInputFields class="field"
                                          :key="`input-image-alt-${index}`"
                                          :component-input="imageAltByIndex(index + 1)"
                                          :component="component"
                                          @base64Updated="base64Updated(imageAltByIndex(index + 1), $event)"
                                          v-model="imageAltByIndex(index + 1).value" />
                    <PowerpageInputFields class="field"
                                          :key="`input-number-of-elements-${index}`"
                                          :component-input="numberOfElementsInputByIndex(index + 1)"
                                          :component="component"
                                          @input="changeNumberOfElements(numberOfElementsInputByIndex(index + 1).key, $event)"
                                          v-model="numberOfElementsInputByIndex(index + 1).value" />
                    <template v-for="(componentInput, idx) in elementsByIndex(index + 1)">
                        <PowerpageInputFields v-for="inputIndex in numberOfElements['e' + getElementNumber(componentInput.key)]"
                                              :style="`order:${inputIndex}`"
                                              :key="`element_${idx}_${inputIndex}`"
                                              class="field element"
                                              :class="{'first-item' : isFirstItemOfElements(componentInput, index + 1), 'percentage' : isPositionInput(componentInput)}"
                                              :data-name="`Pin ${inputIndex}`"
                                              :component-input="componentInput"
                                              :component="component"
                                              :is-element="true"
                                              :index="inputIndex - 1"
                                              :multiple-relations-allowed="false"
                                              @input="changedValue(componentInput, $event)"
                                              v-model="componentInput.value" />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import hasElements from '../mixins/hasElements';
    import PowerpageInputFields from './PowerpageInputFields';

    export default {
        name: 'PowerpagePinsComponent',
        mixins: [hasElements],
        components: { PowerpageInputFields },
        props: {
            component: {
                type: Object,
                default() {
                    return {};
                },
            },
        },
        computed: {
            images() {
                return this.component.componentInputs.filter(input => input.key.startsWith('image_') && !input.key.endsWith('_alt'));
            },
        },
        data() {
            return {
                keyCounter: 0,
            };
        },
        methods: {
            isImageSpecificInput(key)
            {
                return key.startsWith('number_of_elements_') || (key.startsWith('image_') && key.endsWith('_alt'));
            },
            visibleField(componentInput) {
                if (componentInput.parent !== null) {
                    const parent = this.component.componentInputs.find(input => input.id === componentInput.parent);

                    return parent.value;
                }

                return true;
            },
            isPositionInput(componentInput) {
                return componentInput.key.includes('position_');
            },
            pinTopPosition(elementIndex, pinIndex) {
                const componentInput = this.component.componentInputs.find(input => input.key === `element_${elementIndex + 1}_pin_position_y`);

                if (componentInput == null || componentInput.value == null) {
                    return null;
                }

                return componentInput.value[pinIndex - 1];
            },
            pinLeftPosition(elementIndex, pinIndex) {
                const componentInput = this.component.componentInputs.find(input => input.key === `element_${elementIndex + 1}_pin_position_x`);

                if (componentInput == null || componentInput.value == null) {
                    return null;
                }

                return componentInput.value[pinIndex - 1];
            },
            changedValue(componentInput, value) {
                this.$set(componentInput, 'value', value);

                this.keyCounter++;
            },
            imageByIndex(index) {
                return this.component.componentInputs.find(element => element.key === `image_${index}`);
            },
            imageAltByIndex(index) {
                return this.component.componentInputs.find(element => element.key === `image_${index}_alt`);
            },
            numberOfElementsInputByIndex(index) {
                return this.component.componentInputs.find(element => element.key === `number_of_elements_${index}`);
            },
            getBase64(file) {
                this.isLoading = true;
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                })).then((res) => {
                    this.isLoading = false;

                    return res;
                });
            },
            base64Updated(componentInput, base64) {
                this.$set(componentInput, 'previewImage', base64);
            },
        },
    };
</script>

<style scoped
       lang="scss">
    .powerpage-pins-component {
        .image-container {
            margin-bottom : 10px;

            .label {
                display       : block;
                margin-bottom : 6px;
                font-size     : 12px;
                font-weight   : 500;
                line-height   : 14px;
                color         : rgba(26, 26, 26, 0.7);
            }

            .picture {
                display               : grid;
                grid-template-columns : 50% 50%;
                grid-gap              : 20px;

                .preview {
                    width           : 100%;
                    padding-top     : 100%;
                    height          : 0;
                    background      : rgba(0, 0, 0, 0.1);
                    display         : flex;
                    justify-content : center;
                    position        : relative;

                    .empty {
                        position    : absolute;
                        left        : 50%;
                        top         : 50%;
                        width       : 80px;
                        margin-left : -40px;
                        margin-top  : -40px;
                    }

                    img {
                        position        : absolute;
                        left            : 0;
                        top             : 0;
                        width           : 100%;
                        height          : 100%;
                        object-fit      : cover;
                        object-position : center;
                    }

                    .pins {
                        position : absolute;
                        width    : 100%;
                        height   : 100%;
                        top      : 0;
                        left     : 0;

                        .pin {
                            position  : absolute;
                            top       : 0;
                            left      : 0;
                            transform : translate(-20px, -20px);
                        }
                    }
                }

                &--1, &--3 {
                    .preview {
                        padding-top : calc(440 / 305 * 100%);
                        max-width   : 440px;
                    }
                }

                &--2, &--4, &--5 {
                    .preview {
                        padding-top : calc(440 / 610 * 100%);
                    }
                }

                .inputs {
                    border         : 1px solid rgba(0, 0, 0, 0.1);
                    padding        : 20px;
                    display        : flex;
                    flex-direction : column;

                    .field {
                        &.element {
                            margin-left : 30px;
                        }

                        &.first-item::before {
                            margin-left   : -30px;
                            content       : attr(data-name);
                            font-size     : 12px;
                            font-weight   : 500;
                            line-height   : 14px;
                            color         : rgba(26, 26, 26, 0.7);
                            margin-bottom : 20px;
                            display       : block;
                            margin-top    : 10px;
                        }

                        &.percentage ::v-deep.field-control {
                            position : relative;

                            input {
                                padding-right : 60px;
                            }

                            &::after {
                                content  : '%';
                                position : absolute;
                                right    : 23px;
                                top      : 15px;
                                opacity  : 0.3;
                            }
                        }

                        &.first-item {
                            margin-top : 30px;
                        }
                    }
                }
            }
        }
    }
</style>
