<template>
    <draggable handle=".circle-btn" v-model="sortedConfigurations" @end="onEndSorting">
        <div v-for="configuration in sortedConfigurations">
            <div class="question-row">
                <div class="question-row-columns pointer" @click.prevent="editConfiguration(configuration.id)">
                    <div class="column column-1">{{ configuration.title }}</div>
                    <div class="column column-2">{{ configuration.configuration_type.title }}</div>
                    <div class="column column-right">
                        <span class="circle-btn circle-btn--no-background">
                            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 12H6V10H0V12ZM0 0V2H18V0H0ZM0 7H12V5H0V7Z" fill="black" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </draggable>
</template>

<script>
    import { ConfigurationService } from '../../../services';
    import draggable from 'vuedraggable';

    export default {
        name: 'configuration-list',

        components: { draggable },

        props: {
            configurations: {
                type: Array,
                required: true,
            },

            level: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                sortedConfigurations: this.configurations.sort((a, b) => (a.position > b.position) ? 1 : -1),
            };
        },

        methods: {
            onEndSorting(e) {
                const resorted = [];

                this.sortedConfigurations.forEach((configuration, index) => {
                    resorted.push({
                        id: configuration.id,
                        order: index,
                    });
                });

                ConfigurationService.updatePositions(resorted).then(() => {
                    this.$emit('change');
                });
            },

            editConfiguration(configurationId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.configurator.edit',
                    params: {
                        id: productId,
                        configuration: configurationId,
                    },
                });
            },
        },
    };
</script>
