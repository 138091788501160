<template>
    <div class="images-container">
        <label class="upload" for="image-input">
            <div>
                <div>Upload hier je foto's</div>
                <label class="btn btn--blue" for="image-input">
                    {{ $t('images.add_image') }}
                </label>

                <input id="image-input"
                       name="images"
                       type="file"
                       @change="(e) => { fileInputChange(e) }">
            </div>
        </label>
        <label class="upload">
            <div>
                <div>{{ $t('news.youtube') }}</div>
                <div class="field video">
                    <div class="field-control">
                        <input v-model="youtube" class="display" type="text" />
                    </div>

                    <div class="thumbnail">
                        <file-input v-model="thumbnail"
                                    class="small"
                                    @base64Updated="$emit('base64Updated', $event)"
                                    file-type="image/*">
                            <template #default="{image}">
                                <img :src="image"
                                     alt="Image"
                                     v-if="image">
                                <img :src="thumbnail"
                                     v-else-if="!!thumbnail">
                                <div class="img-placeholder"
                                     v-else />
                            </template>
                        </file-input>
                    </div>
                </div>

                <label class="btn btn--blue" @click="addVideo(youtube)">
                    Opslaan
                </label>

                <div @click="deleteVideo()">
                    Video verwijderen
                </div>

            </div>
        </label>

        <div class="images">
            <draggable v-model="images"
                       handle=".drag-handle"
                       @end="onEnd">
                <div v-for="data in images" class="image">
                    <div class="drag-handle">
                        <img alt="drag-icon" src="/svgs/drag.svg">
                    </div>
                    <div class="delete-image" @click="deleteImage(data)">
                        <img alt="close-icon" src="/svgs/close-icon.svg">
                    </div>
                    <div class="field">
                        <img :srcset="data.srcset"
                             sizes="1px"
                             @load="setImageSizes" />
                    </div>
                    <div class="field field-control">
                        <input v-model="data.alt" class="show-input" placeholder="Vul hier een alt in"
                               type="text" @change="saveImage(data)">
                    </div>
                    <div class="field field--checkbox marge" @click="data.hide_margin = !data.hide_margin;saveImage(data)">
                        <input v-model="data.hide_margin" :name="`marge_${data.id}`" type="checkbox" value="1" />
                        <div class="field-control">
                            <label :v-for="`marge_${data.id}`">Zonder marge tonen</label>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import FileInput from '../../../components/FileInput';
    import { ProductService } from '../../../services';

    export default {
        data() {
            return {
                images: [],
                youtube: '',
                thumbnail: null,
            };
        },

        components: {
            draggable,
            FileInput,
        },

        methods: {
            setImageSizes(event) {
                let img = event.path[0];

                if (img.dataset.sized === undefined) {
                    img.sizes = Math.ceil(img.getBoundingClientRect().width / window.innerWidth * 100) + 'vw';
                    img.dataset.sized = '';
                }
            },
            loadImages() {
                ProductService.loadImages(this.$route.params.id)
                              .then(({ data }) => {
                                  this.images = data.data;
                              });
            },
            loadVideos() {
                ProductService.loadVideos(this.$route.params.id)
                              .then(response => {
                                  if (response.data) {
                                      this.youtube = response.data.youtube_id;
                                  }
                              });
            },

            addVideo(code) {
                var img = new Image();
                img.src = 'http://img.youtube.com/vi/' + code + '/mqdefault.jpg';
                let self = this;

                img.onload = function () {
                    if (img.width === 120) {
                        this.$flashMessage(this.$t('Ongeldige video'), 3500, 'error');
                    } else {
                        ProductService.storeProductVideo(this.$route.params.id, code, this.thumbnail).then(response => {
                            this.$flashMessage(this.$t('Video opgeslagen'), 3500, 'success');
                        });
                    }
                }.bind(this);
            },

            deleteImage(data) {
                ProductService.deleteProductImage(data.id)
                              .then(response => {
                                  this.$flashMessage(this.$t('images.delete_success'), 3500, 'success');
                                  this.loadImages();
                              })
                              .catch(err => {
                                  this.$flashMessage(this.$t('images.delete_fail'), 3500, 'success');
                              });
            },

            deleteVideo() {
                this.youtube = '';
                ProductService.deleteProductVideo(this.$route.params.id).then(response => {
                    if (response.data) {
                        this.$flashMessage(this.$t('Video verwijderd'), 3500, 'success');
                    } else {
                        this.$flashMessage(this.$t('Video verwijderen mislukt'), 3500, 'error');
                    }
                });
            },

            fileInputChange(e) {
                const files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    return;
                }

                this.createImage(files[0]);
            },

            saveImage(data) {
                this.$nextTick(() => {
                    ProductService.updateProductImage(data.id, { alt: data.alt, hide_margin: data.hide_margin }).then(response => {
                        if (response.status === 204) {
                            this.$flashMessage(this.$t('Product afbeelding opgeslagen'), 3500, 'success');
                        } else {
                            this.$flashMessage(this.$t('Product afbeelding opslaan mislukt'), 3500, 'error');
                        }
                    });
                });
            },

            createImage(file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.storeImage(e.target.result);
                };
                reader.readAsDataURL(file);
            },

            storeImage(base64) {
                ProductService.storeProductImage(this.$route.params.id, base64)
                              .then(({ data }) => {
                                  this.images.push(data.data);
                              })
                              .catch(err => {
                                  this.$flashMessage(this.$t('images.store_fail'), 3500, 'error');
                              });
            },

            onEnd() {
                ProductService.updateProductImagePosition(this.images.map(image => image.id))
                              .then(_ => this.$flashMessage(this.$t('images.updated_order'), 3500, 'success'));
            },
        },

        mounted() {
            this.loadImages();
            this.loadVideos();
        },
    };
</script>

<style scoped>
    .video {
        margin-top : 45px;
    }

    .field .field-control .display {
        display : block;
    }
</style>
