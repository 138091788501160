<template>
    <div class="content-wrapper">
        <div class="cards">
            <div class="general-information card"
                 v-if="!isRelationship">
                <div class="card-heading">
                    <h3 class="card-title">Algemene informatie</h3>
                </div>

                <div class="field">
                    <label class="label">Titel</label>
                    <div class="field-control">
                        <input v-model="page.title"
                               type="text">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Meta titel</label>
                    <div class="field-control">
                        <input v-model="page.seoTitle"
                               type="text">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Meta beschrijving</label>
                    <div class="field-control">
                        <input v-model="page.seoDescription"
                               type="text">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Slug</label>
                    <div class="field-control">
                        <input v-model="page.slug"
                               type="text">
                    </div>
                </div>

                <div class="field">
                    <label class="label">Preview Afbeelding</label>
                    <div class="field-control">
                        <file-input v-model="page.previewImage"
                                    file-type="image/*">
                            <template #default="{image}">
                                <img :src="image"
                                     alt="Image"
                                     v-if="image">
                                <img :src="page.previewImage"
                                     v-else-if="!!page.previewImage">
                                <div class="img-placeholder"
                                     v-else />
                            </template>
                        </file-input>
                    </div>
                </div>
            </div>

            <div class="general-information card"
                 v-if="!isRelationship">
                <div class="card-heading">
                    <h3 class="card-title">Filters</h3>
                </div>

                <div v-for="filter in filters"
                     class="field">
                    <label class="label">{{ filter.name }}</label>
                    <div class="field-control">
                        <vue-multiselect v-model="chosenFilters[filter.id]"
                                         :options="filter.filterOptions"
                                         label="name"
                                         track-by="id"
                                         :close-on-select="false"
                                         placeholder="Selecteer filters"
                                         :multiple="true" />
                    </div>
                </div>
            </div>

            <h3>Componenten</h3>

            <transition-group name="flip-list">
                <powerpage-component v-for="(component, idx) in sortedComponents"
                                     :offset="errors ? getComponentInputOffset(idx) : 0"
                                     :errors="errors"
                                     :is-category="isCategory"
                                     :preserve-search="true"
                                     @deleteComponent="deleteComponent(idx)"
                                     @orderChanged="orderChanged(component, $event)"
                                     @refreshComponent="refreshComponent(component)"
                                     :maxOrder="components.length"
                                     :disableDelete="(component.id === categoryComponentId && isCategory) || (component.id === productComponentId && isProduct)"
                                     :key="`powerpage-component-${component.uuid}`"
                                     :component="component" />
            </transition-group>
        </div>

        <div class="components">
            <div class="group"
                 :key="`componentGroup-${idx}`"
                 v-for="(componentGroup, idx) in componentGroups"
                 v-if="componentGroup.components.length > 0">
                <h3 @click="toggleComponentGroupOpen(componentGroup.id)"
                    :class="{'open' : openComponentGroups.includes(componentGroup.id)}">
                    {{ componentGroup.name }}
                    <svg width="10"
                         height="6"
                         viewBox="0 0 10 6"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.6"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0 0H10L5 6L0 0Z"
                              fill="black" />
                    </svg>
                </h3>
                <ul v-if="openComponentGroups.includes(componentGroup.id)">
                    <template v-for="(component, index) in componentGroup.components">
                        <li @click="addComponent(component)"
                            :key="`component-${idx}-${index}`"
                            v-if="component.id !== categoryComponentId && component.id !== productComponentId">
                            <img :src="`/backend/powerpages/components/${component.id}`">
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import VueMultiselect from 'vue-multiselect';
    import FileInput from '../../components/FileInput';
    import PowerpageComponent from '../../components/PowerpageComponent';
    import PowerpageService from '../../services/powerpages';
    import ComponentGroupService from '../../services/powerpages-component-groups';
    import PowerpageFilterService from '../../services/powerpages-filters';

    export default {
        name: 'PowerpageCreateForm',
        components: { FileInput, PowerpageComponent, VueMultiselect },
        props: {
            pageId: {
                type: Number,
                default: null,
            },
            isRelationship: {
                type: Boolean,
                default: false,
            },
            isCategory: {
                type: Boolean,
                default: false,
            },
            isProduct: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                chosenFilters: [],
                page: {
                    filters: {},
                    offline: true,
                },
                componentGroups: [],
                components: [],
                filters: [],
                openComponentGroups: [],
                categoryComponentId: 42,
                productComponentId: 46,
                errors: {},
            };
        },
        computed: {
            maxOrder: {
                get() {
                    return this.components.length;
                },
            },

            allAvailableComponents() {
                return this.componentGroups.map((group) => {
                    return group.components;
                }).flat();
            },

            categoryComponent() {
                return this.allAvailableComponents.find((component) => {
                    return component.id === this.categoryComponentId;
                });
            },

            productComponent() {
                return this.allAvailableComponents.find((component) => {
                    return component.id === this.productComponentId;
                });
            },

            sortedComponents: {
                get() {
                    return this.components.sort((a, b) => {
                        return a.order > b.order ? 1 : -1;
                    });
                },
            },
        },
        mounted() {
            ComponentGroupService.index()
                                 .then(response => {
                                     this.componentGroups = response.data.data;

                                     this.addCategoryComponentIfMissing();
                                     this.addProductComponentIfMissing();
                                 });

            this.loadFilters();
        },
        watch: {
            pageId: {
                handler() {
                    if (this.pageId !== null) {
                        this.loadPowerpage();
                    }
                },
                immediate: true,
            },
        },
        methods: {
            resetErrors() {
                this.errors = {};
            },
            getComponentInputOffset(idx){
                let offset = 0;

                for(let i = 0; i < idx; i++){
                    offset += this.sortedComponents[i].componentInputs.length;
                }

                return offset;
            },
            refreshComponent(currentComponent) {
                let allComponents = [];
                this.componentGroups.forEach(componentGroup => {
                    allComponents = allComponents.concat(componentGroup.components);
                });

                const updatedComponent = allComponents.find(component => component.id === currentComponent.id);
                const currentInputs = this.pluck(currentComponent.componentInputs, 'id');

                const missingInputs = updatedComponent.componentInputs.filter(input => !currentInputs.includes(input.id));

                missingInputs.forEach(missingInput => {
                    const index = updatedComponent.componentInputs.findIndex(updatedInput => updatedInput.id === missingInput.id);
                    currentComponent.componentInputs.splice(index, 0, Object.assign({}, missingInput, { order: currentComponent.order }));
                });
            },
            pluck(array, key) {
                return array.map(o => o[key]);
            },
            addCategoryComponentIfMissing() {
                if (
                    this.isCategory
                    && !this.components.some(component => component.id === this.categoryComponentId)
                ) {
                    this.addComponent(this.categoryComponent, 1);
                }
            },
            addProductComponentIfMissing() {
                if (
                    this.isProduct
                    && !this.components.some(component => component.id === this.productComponentId)
                ) {
                    this.addComponent(this.productComponent, 1);
                }
            },
            toggleComponentGroupOpen($id) {
                if (this.openComponentGroups.includes($id)) {
                    const index = this.openComponentGroups.indexOf($id);
                    this.openComponentGroups.splice(index, 1);
                } else {
                    this.openComponentGroups.push($id);
                }
            },
            savePowerpage($noRedirect = false) {
                const powerpage = {
                    slug: this.page.slug,
                    title: this.page.title,
                    seoTitle: this.page.seoTitle,
                    seoDescription: this.page.seoDescription,
                    previewImage: this.page.previewImage,
                    offline: this.page.offline ? 1 : 0,
                    componentInputs: [],
                    filters: this.chosenFilters,
                };

                this.components.forEach(component => {
                    const componentInputs = this.isCategory ? component.componentInputs : component.componentInputs.filter(componentInput => !componentInput.key.includes('category_only_'));

                    componentInputs.forEach(componentInput => {
                        powerpage.componentInputs.push({
                            componentInputPageId: componentInput.componentInputPageId,
                            componentInputId: componentInput.id,
                            order: component.order,
                            value: typeof componentInput.value !== 'undefined' ? componentInput.value : null,
                        });
                    });
                });

                if (this.pageId != null) {
                    return PowerpageService.update(this.pageId, powerpage, this.isRelationship)
                                           .then(() => {
                                               this.$flashMessage(this.$t('powerpages.update_success'), 3500, 'success');
                                           })
                                           .catch((err) => {
                                               this.errors = err.response.data.errors;

                                               const firstError = err.response.data.errors[Object.keys(err.response.data.errors)[0]];
                                               this.$flashMessage(firstError[0], 3500, 'error');
                                           });
                }

                return PowerpageService.store(powerpage, this.isRelationship)
                                       .then(async (response) => {
                                           this.$flashMessage(this.$t('powerpages.save_success'), 3500, 'success');

                                           if (!$noRedirect) {
                                               await this.$router.push({ name: 'powerpages.show', params: { id: response.data.data.id } });
                                               this.$emit('powerpageSaved', response.data.data.id);
                                           } else {
                                               this.$emit('powerpageSaved', response.data.data.id);
                                           }
                                       })
                                       .catch((err) => {
                                           const firstError = err.response.data.errors[Object.keys(err.response.data.errors)[0]];
                                           this.$flashMessage(firstError[0], 3500, 'error');
                                       });
            },
            async loadPowerpage() {
                if (this.pageId) {
                    const response = await PowerpageService.show(this.pageId);

                    this.page = response.data;

                    Object.values(this.page.filters).forEach((filter) => {
                        this.chosenFilters[filter.id] = filter.options;
                    });
                }

                this.components = this.page.components.map(component => {
                    return {
                        id: component.id,
                        uuid: Object.values(component.inputs)[0].componentInputPageId,
                        order: component.order,
                        name: component.name,
                        componentInputs: Object.entries(component.inputs).map(([key, input]) => {
                            return {
                                id: input.id,
                                componentInputPageId: input.componentInputPageId,
                                order: input.order,
                                value: input.value,
                                title: input.title,
                                selectOptions: input.selectOptions,
                                key: key,
                                placeholder: input.placeholder,
                                input: {
                                    type: input.type,
                                },
                                parent: input.parent,
                                nullable: input.nullable,
                            };
                        }),
                    };
                });
                this.$emit('changedStatus', this.page.offline);
            },
            async loadFilters() {
                const response = await PowerpageFilterService.index();

                this.filters = response.data.data;
            },
            async addComponent(component, order = null) {
                const componentOrder = order == null ? this.maxOrder + 1 : order;

                const newComponent = Object.assign({}, component, { order: componentOrder });

                newComponent.componentInputs = newComponent.componentInputs.map(componentInput => Object.assign({}, componentInput, { order: newComponent.order }));

                newComponent.uuid = Date.now();

                this.components.push(newComponent);

                if (order !== null) {
                    this.orderChanged(newComponent, {
                        order: newComponent.order,
                        direction: 'up',
                    });
                }
            },
            deleteComponent(idx) {
                this.components.splice(idx, 1);
                for (let i = 0; i < this.components.length; i++) {
                    this.components[i].order = i + 1;
                }
            },
            orderChanged(movedComponent, event) {
                const order = event.order;
                const direction = event.direction;

                let component = this.sortedComponents.find((component) => {
                    return component.order === order && movedComponent !== component;
                });

                if (component != null) {
                    if (direction === 'down') {
                        component.order = order - 1;
                    }

                    if (direction === 'up') {
                        component.order = order + 1;
                    }
                }
            },
            toggleOffline() {
                this.page.offline = !this.page.offline;
                this.$emit('changedStatus', this.page.offline);
                this.savePowerpage();
            },
        },
    };
</script>
<style scoped
       lang="scss">

    .content-wrapper {
        width           : 100%;
        display         : flex;
        justify-content : space-between;

        .cards {
            width         : 65%;
            padding-right : 80px;
            border-right  : 1px solid rgba(6, 14, 19, 0.1);

            .card::v-deep {
                background    : white;
                border-radius : 4px;
                box-shadow    : 0px 2px 8px rgba(0, 0, 0, 0.1);
                border        : 1px solid rgba(0, 0, 0, 0.02);
                padding       : 34px;

                &:not(:last-child) {
                    margin-bottom : 42px;
                }

                .card-heading {
                    margin-bottom   : 16px;
                    display         : flex;
                    justify-content : space-between;
                    align-items     : center;
                    z-index         : 10;

                    .ordering {
                        margin-left  : auto;
                        margin-right : 10px;
                        display      : flex;

                        .order {
                            cursor       : pointer;
                            margin-right : 10px;
                            width        : 15px;
                            height       : 15px;
                        }
                    }
                }

                .card-title {
                    font-size   : 18px;
                    font-weight : bold;
                    line-height : 100%;
                }

                .redactor-toolbar {
                    z-index : 9;
                }
            }

            & > h3 {
                font-size     : 18px;
                line-height   : 100%;
                font-weight   : bold;
                margin-bottom : 42px;
            }
        }

        .components {
            position     : sticky;
            top          : 60px;
            width        : 35%;
            padding-left : 80px;
            height       : fit-content;

            .group {
                margin-bottom : 20px;

                & > h3 {
                    cursor          : pointer;

                    font-size       : 18px;
                    line-height     : 100%;
                    font-weight     : bold;
                    margin-bottom   : 16px;

                    width           : 100%;
                    display         : flex;
                    justify-content : space-between;
                    align-items     : center;

                    &.open {
                        svg {
                            transform : rotate(180deg);
                        }
                    }

                    &:hover {
                        color : #F26419;
                    }
                }

                ul {
                    list-style            : none;
                    display               : grid;
                    grid-template-columns : repeat(2, 50%);
                    grid-gap              : 10px;

                    li {
                        height     : 93px;
                        border     : 1px solid rgba(0, 0, 0, 0.1);
                        background : #FFFFFF;
                        cursor     : pointer;

                        transition : transform .1s; /* Animation */

                        &:hover {
                            border    : 1px solid #F26419;

                            transform : scale(2);
                        }

                        img {
                            width      : 100%;
                            height     : 100%;
                            object-fit : contain
                        }
                    }
                }
            }
        }
    }
</style>
