import { uuid } from '../../helpers';
import Configuration from './Configuration';

export default class ConfigurationOption {
    constructor(id, configuration_id, title, image, color, secondary_color, has_custom_color, custom_color_label_1, custom_color_label_2, preview_image, is_default, order, configurations, articles) {
        this.id = id;
        this.configuration_id = configuration_id;
        this.title = title;
        this.image = image;
        this.color = color;
        this.secondary_color = secondary_color;
        this.has_custom_color = !!has_custom_color;
        this.custom_color_label_1 = custom_color_label_1;
        this.custom_color_label_2 = custom_color_label_2;
        this.preview_image = preview_image;
        this.is_default = !!is_default;
        this.order = order;
        this.configurations = configurations.map(c => {
            return Configuration.make(c);
        }) || [];
        this.articles = articles.map(a => {
            return { id: a.id, title: a.title, code: a.code, quantity: a.pivot.quantity };
        }) || [];
    }

    static make(obj) {
        return new this(obj.id, obj.configuration_id, obj.title, obj.image, obj.color, obj.secondary_color, obj.has_custom_color, obj.custom_color_label_1, obj.custom_color_label_2, obj.preview_image, obj.is_default, obj.order, obj.configurations || [], obj.articles || []);
    }

    static makeEmpty(configuration_id) {
        return new this(null, configuration_id, '', null, null, null, false, null, null, null, false, 0, [], []);
    }

    exists() {
        return !this.id.toString().startsWith('_');
    }
}
