import MessageContainer from './MessageContainer';

export default {
    install(Vue) {
        const messageContainer = new Vue({
            data() {
                return {
                    messages: [],
                };
            },
            methods: {
                removeMessage(message, type) {
                    const index = this.messages.findIndex(msg => msg.message === message && msg.type === type);

                    this.messages.splice(index, 1);
                },
            },
            render: h => h(MessageContainer),
        });

        const el = document.createElement('div');

        document.body.appendChild(el);

        messageContainer.$mount(el);

        Vue.mixin({
            methods: {
                $resetMessages() {
                    messageContainer.messages = [];
                },
                $message(message, type = 'error') {
                    messageContainer.messages.push({
                        message,
                        type,
                    });
                },
                $flashMessage(message, timeout = 5000, type = 'error') {
                    this.$message(message, type);

                    setTimeout(() => {
                        messageContainer.removeMessage(message, type);
                    }, timeout);
                },
            },
        });
    },
};