<template>
    <div>
        <div class="page-header">
            <h1 class="page-title"> {{ $t('products.new') }}</h1>

            <div>
                <a href="#"
                   class="btn btn--orange"
                   :class="{'adding-product' : addingProduct}"
                   @click.prevent="save">
                    {{ $t('products.save') }}
                </a>
            </div>
        </div>

        <div class="tabs tabs--push-more">
            <a href="#" class="active" @click.prevent>{{ $t('general.general_information') }}</a>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('products.name') }}</label>
                <div class="field-control">
                    <input type="text" v-model="name" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.url') }}</label>
                <div class="field-control">
                    <input type="text" v-model="url" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.description') }}</label>
                <div class="field-control">
                    <textarea v-model="description"></textarea>
                </div>
            </div>

            <div class="field field--push-medium field--checkbox" style="margin-top: 48px">
                <input type="checkbox" id="isConfigurable" v-model="isConfigurable" />
                <div class="field-control">
                    <label for="isConfigurable">Is te configureren</label>
                </div>
            </div>

            <div class="field field--push-medium field--checkbox">
                <input type="checkbox" id="hasDifferentColors" v-model="hasMultipleColors" />
                <div class="field-control">
                    <label for="hasDifferentColors">Verschillende kleuren</label>
                </div>
            </div>

            <div class="field field--push-hard field--checkbox">
                <input type="checkbox" id="hasDiscount" v-model="hasDiscount" />
                <div class="field-control">
                    <label for="hasDiscount">Korting</label>
                </div>
            </div>

            <div v-if="hasDiscount" class="field field--push-hard">
                <label class="label" for="hasDiscount">Prijs voor korting (Alleen Steelux)</label>
                <div class="field-control">
                    <input type="number" step="0.01" value="0.00" placeholder="0.00" v-model="pricePreDiscount" />
                </div>
            </div>
            <div v-if="hasDiscount" class="field field--push-hard">
                <label class="label" for="hasDiscount">Kortings percentage (Alleen Povag)</label>
                <div class="field-control">
                    <input type="number" step="0.01" value="0.00" placeholder="0.00" v-model="discountPercentage" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.suggestion') }}</label>
                <div class="field-control">
                    <textarea placeholder="Type hier uw suggestie tekst.." v-model="suggestion"></textarea>
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.subcategory') }}</label>
                <div class="field-control">
                    <multiselect v-model="categories"
                                 :options="categoriesList"
                                 label="name"
                                 track-by="id"
                                 :close-on-select="false"
                                 :limit="3"
                                 :taggable="true"
                                 placeholder="Selecteer subcategorie"
                                 :multiple=true>
                        <template slot="option" slot-scope="{ option }">
                            <span>
                                {{ option.parent_name != null ? option.parent_name + ' | ' + option.name : option.name }}
                            </span>
                        </template>
                    </multiselect>
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [1]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_1" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [2]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_2" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [3]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_3" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [4]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_4" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [5]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_5" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [6]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_6" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [7]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_7" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [8]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_8" />
                </div>
            </div>

            <div class="field">
                <label class="label">{{ $t('products.tariff_rate', [9]) }}</label>
                <div class="field-control">
                    <input type="number" v-model="tariffPieces.tariff_9" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '../../store';
    import Multiselect from 'vue-multiselect';
    import { ProductService } from '../../services';

    export default {
        name: 'create-product',

        components: { Multiselect },

        async beforeRouteEnter(to, from, next) {
            await store.dispatch('categories/fetch_categories');

            next();
        },

        data() {
            return {
                name: null,
                url: '/',
                description: null,
                isConfigurable: false,
                hasMultipleColors: false,
                hasDiscount: false,
                pricePreDiscount: 0.00,
                discountPercentage: 0,
                suggestion: null,
                categories: [],
                tariffPieces: {
                    tariff_1: null,
                    tariff_2: null,
                    tariff_3: null,
                    tariff_4: null,
                    tariff_5: null,
                    tariff_6: null,
                    tariff_7: null,
                    tariff_8: null,
                    tariff_9: null,
                },
                addingProduct: false,
            };
        },

        computed: {
            categoriesList() {
                const stateCategories = this.$store.state.categories.categories;
                return stateCategories.filter(category => {
                    return category.category_id !== null;
                });
            },
        },

        watch: {
            url: function (newVal) {
                if (!newVal.length) {
                    this.url = '/';
                    return;
                }

                if (newVal[0] !== '/') {
                    this.url = '/' + newVal;
                }
            },
        },

        methods: {
            save() {
                if(this.addingProduct !== true) {
                    this.addingProduct = true;

                    const params = {
                        name: this.name,
                        url: this.url,
                        description: this.description,
                        suggestion: this.suggestion,
                        categories: this.categories.map(c => c.id),
                        price_pre_discount: this.pricePreDiscount,
                        discount_percentage: this.discountPercentage,
                        tariff_pieces: this.tariffPieces,
                        ...this.isConfigurable && { is_configurable: this.isConfigurable },
                        ...this.hasMultipleColors && { has_multiple_colors: this.hasMultipleColors },
                    };

                    ProductService.save(params).then(response => {
                        this.addingProduct = false;
                        this.$flashMessage(this.$t('products.save_success'), 3500, 'success');
                        this.$router.push({ name: 'products.general-information', params: { id: response.data.id } });
                    }).catch((err) => {
                        const errors = Object.entries(err.response.data.errors)
                                             .map((err) => err[1])
                                             .flat()
                                             .join('<br />');

                        this.$flashMessage(errors, 3500, 'error');
                        this.addingProduct = false;
                    });
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .btn {
        &.adding-product {
            opacity : 0.5;
            cursor   : default;
        }
    }
</style>
