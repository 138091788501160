<template>
  <div class="product-detail">
    <div class="page-header page-header--push-top">
      <h1 class="page-title">{{ $t('faq.name') }}</h1>
    </div>

    <div class="half-size">
      <div class="field">
        <label class="label">{{ $t('faq.category') }}</label>
        <div class="field-control">
          <select v-model="faq.faq_question_category_id">
            <option value=""></option>
            <option v-for="category in categories" :key="'category' + category.id" :value="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('faq.question') }}</label>
        <div class="field-control">
          <input type="text" v-model="faq.question" />
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('faq.answer') }}</label>
        <div class="field-control">
          <input type="text" v-model="faq.answer" />
        </div>
      </div>
    </div>
    <a href="#" class="btn btn--orange" @click.prevent="save">
      {{ $t('faq.save') }}
    </a>
    <a href="#" class="btn btn--link" v-if="faq.id" @click.prevent="deleteModal = true">
      {{ $t('faq.delete') }}
    </a>
    <BaseModal v-if="deleteModal"
               :is-confirmation="true"
               :title="$t('faq.delete_modal_title')"
               :content="$t('faq.delete_modal_content')"
               @close="deleteModal = false"
               @confirm="deleteRedirect()" />
  </div>
</template>
<script>
import { FaqQuestionsService } from "../../services";
import BaseModal from '../../components/BaseModal';

export default {
  name: 'FaqQuestionsShow',
  components: {
    BaseModal
  },

  data() {
    return {
      deleteModal: false,
      faq: { question: '', answer: '' },
      categories: []
    };
  },

  beforeMount() {
    this.fetchRedirect();
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    getCategories(){
      FaqQuestionsService.getCategories().then((response) => {
        this.categories = response.data;
      });
    },
    fetchRedirect() {
      if (this.$route.path !== '/faq-questions/create') {
        FaqQuestionsService.show(this.$route.params.id).then(response => {
          this.faq = response.data;
        })
      }
    },

    deleteRedirect() {
      FaqQuestionsService.destroy(this.faq.id).then(response => {
        this.$router.push({ path: '/faq-questions' });
      })
    },

    save() {
      let promise;
      if (this.faq.id) {
        promise = FaqQuestionsService.update(this.faq.id, this.faq).then(response => {
          this.$flashMessage(this.$t('faq.update_success'), 3500, 'success');
          this.$router.push({ path: '/faq-questions' });
        });
      } else {
        promise = FaqQuestionsService.store(this.faq).then(response => {
          this.redirect = response.data;
          this.$flashMessage(this.$t('faq.update_success'), 3500, 'success');
          this.$router.push({ path: '/faq-questions' });
        });
      }

      promise.catch((err) => {
        const errors = Object.entries(err.response.data.errors)
            .map((err) => err[1])
            .flat()
            .join("<br />");
        this.$flashMessage(errors, 3500, 'error');
      });
    },
  },
}
</script>
