<template>
    <div class="configuration-tree-row">
        <div class="configuration row">
            <div class="label"
                 :class="{'selected' : selected, 'error' : hasError }"
                 @click="selectConfiguration">
                <div class="icon">
                    <multiple-choice v-if="configuration.configuration_type && configuration.configuration_type.id === 3" />
                    <single-choice v-if="configuration.configuration_type && configuration.configuration_type.id === 2" />
                    <img src="/images/customColor.png" v-if="configuration.configuration_type && configuration.configuration_type.id === 1">
                </div>
                {{ configuration.title ? configuration.title : '...' }}
            </div>
            <div v-if="configuration.configuration_option_id"
                 class="delete-configuration"
                 @click="deleteConfiguration">
                <span class="text">Vraag verwijderen</span>
                <delete-icon />
            </div>
        </div>
        <div class="configuration-option " v-for="(configurationOption, configurationOptionIndex) in configuration.configuration_options ">
            <div class="row">
                <div class="configuration-option-label">
                    <template v-if="configuration.configuration_type.id === 1">
                        <span class="colorIndicator"
                              v-if="configurationOption.secondary_color !== null"
                              :style="`background:${splitColor(configurationOption)}`" />
                        <span class="colorIndicator"
                              v-else-if="configurationOption.color !== null"
                              :style="`background-color:${configurationOption.color};`" />
                        <span class="colorIndicator"
                              v-else
                              style="background-image:url('/images/customColor.png')" />
                    </template>
                    {{ configurationOption.title }}
                </div>
                <div class="add-sub-configuration"
                     @click="addSubConfiguration(configurationOption)">
                    <span class="text">Subvraag toevoegen</span>
                    <add-icon />
                </div>
            </div>
            <div class="child" v-for="(child, childIndex) in configurationOption.configurations">
                <configuration-tree-row class="row"
                                        :index="configurationOptionIndex"
                                        v-model="configurationOption.configurations[childIndex]"
                                        :offset="offset + `configuration_options\\.${configurationOptionIndex}\\.configurations\\.${childIndex}\\.`"
                                        @delete="deleteChild(configurationOption, child)" />
            </div>
        </div>
    </div>
</template>
<script>
    import AddIcon from '../../../components/icons/AddIcon';
    import DeleteIcon from '../../../components/icons/DeleteIcon';
    import MultipleChoice from '../../../components/icons/MultipleChoice';
    import SingleChoice from '../../../components/icons/SingleChoice';
    import Configuration from '../../../models/products/Configuration';

    export default {
        name: 'ConfigurationTreeRow',
        components: { DeleteIcon, AddIcon, SingleChoice, MultipleChoice },
        model: {
            prop: 'configuration',
            event: 'input'
        },
        props: {
            configuration: {
                type: Object,
                required: true,
            },
            offset: {
                type: String,
                default: '',
            },
            index: {
                type: Number,
                default: 0,
            },
        },
        mounted() {
            if (this.configuration.configuration_options.filter(configurationOption => configurationOption.order === 0).length > 0) {
                // this list is not ordered yet
                let count = 1;
                this.configuration.configuration_options.forEach(configurationOption => {
                    configurationOption.order = count;
                    count++;
                });
            }

            return this.configuration.configuration_options.sort((a, b) => (a.order > b.order) ? 1 : -1);
        },
        computed: {
            selected() {
                return this.configuration.id === this.selectedConfiguration.id;
            },
            selectedConfiguration() {
                return this.$store.state.configurations.selectedConfiguration;
            },
            errors() {
                return Object.keys(this.$store.state.configurations.errors).filter((key) => {
                    const regEx = new RegExp(`^${this.offset}configuration_options\\.\\d+\\.configurations`);
                    const regEx2 = new RegExp(`^${this.offset}`);

                    return !regEx.test(key) && regEx2.test(key);
                });
            },
            hasError() {
                return this.errors.length > 0;
            },
        },
        methods: {
            deleteChild(configurationOption, child) {
                configurationOption.configurations = configurationOption.configurations.filter(configuration => configuration !== child);
            },
            selectConfiguration() {
                this.$store.commit('configurations/set_selected_configuration', { configuration: this.configuration, offset: this.offset });
            },
            splitColor(configurationOption) {
                return `${configurationOption.color} -webkit-linear-gradient(-45deg, ${configurationOption.color} 50%, ${configurationOption.secondary_color} 50%)`;
            },
            addSubConfiguration(configurationOption) {
                if (configurationOption.configurations == null) {
                    configurationOption.configurations = [];
                }

                console.log(this.configuration);
                const emptyConfiguration = Configuration.makeEmpty(this.configuration.product_id, configurationOption.id);
                configurationOption.configurations.push(emptyConfiguration);
            },
            deleteConfiguration() {
                if (confirm('Weet je zeker dat je deze vraag wilt verwijderen?')) {
                    this.$emit('delete');
                }
            },
        },
    };
</script>
