<template>
    <div class="card powerpage-component"
         :id="component.uuid">
        <div class="card-heading">
            <h3 class="card-title">
                {{ component.order }} * {{ component.name }}
                <div class="thumbnail">
                    <img :src="`/backend/powerpages/components/${component.id}`">
                </div>
            </h3>

            <div class="ordering">
                <img v-if="this.component.order > 1"
                     src="/svgs/up-arrow.svg"
                     class="order order-up"
                     @click="moveOrderUp" />
                <img v-if="this.component.order < this.maxOrder"
                     src="/svgs/down-arrow.svg"
                     class="order order-down"
                     @click="moveOrderDown" />
            </div>

            <options-menu>
                <a href="#"
                   @click.prevent="refreshComponent">Velden updaten</a>
                <a href="#"
                   v-if="!disableDelete"
                   @click="deleteComponent">Verwijderen</a>
            </options-menu>
        </div>
        <div class="card-body">
            <template v-if="component.id !== pinsComponentId">
                <template v-for="(componentInput, idx) in componentInputs">
                    <PowerpageInputFields v-show="visibleField(componentInput)"
                                          :error="errors[`componentInput.${idx + offset}.value`]"
                                          class="field"
                                          :class="`field--${componentInput.input.type}`"
                                          :key="`input-${componentInput.id}`"
                                          v-if="!hasNumberOfElements || !elements.includes(componentInput)"
                                          :component-input="componentInput"
                                          :component="component"
                                          @input="changeNumberOfElements(componentInput.key, $event)"
                                          v-model="componentInput.value" />

                    <PowerpageInputFields v-show="visibleField(componentInput)"
                                          :error="errors[`componentInput.${idx + offset}.value`]"
                                          v-for="index in numberOfElements['e' + getElementNumber(componentInput.key)]"
                                          v-if="hasNumberOfElements && elements.includes(componentInput)"
                                          :style="`order:${index}`"
                                          :key="`element_${componentInput.id}_${index}`"
                                          class="field element"
                                          :class="{'first-item' : isFirstItemOfElements(componentInput)}"
                                          :data-name="`Element ${index}`"
                                          :component-input="componentInput"
                                          :component="component"
                                          :is-element="true"
                                          :index="index - 1"
                                          v-model="componentInput.value" />
                </template>
            </template>
            <template v-else>
                <powerpage-pins-component :component="component" />
            </template>
        </div>
    </div>
</template>

<script>
    import OptionsMenu from './options-menu';
    import PowerpageInputFields from './PowerpageInputFields';
    import PowerpagePinsComponent from './PowerpagePinsComponent';
    import hasElements from '../mixins/hasElements';

    export default {
        name: 'PowerpageComponent',
        mixins: [hasElements],
        props: {
            component: {
                required: true,
                type: Object,
            },

            offset: {
                required: true,
                type: Number,
                default: 0,
            },

            maxOrder: {
                required: true,
                type: Number,
            },

            disableDelete: {
                type: Boolean,
                default: false,
            },

            isCategory: {
                type: Boolean,
                default: false,
            },

            errors: {
                type: Object,
                default() {
                    return {};
                },
            },
        },

        data() {
            return {
                numberOfElements: {},
                pinsComponentId: 44,
            };
        },

        mounted() {
            const inputs = this.componentInputs.filter(componentInput => componentInput.key.includes('number_of_elements_'));

            inputs.forEach(input => {
                this.$set(this.numberOfElements, 'e' + this.getElementNumber(input.key), parseInt(input.value == null ? 1 : input.value));
            });
        },

        computed: {
            hasNumberOfElements() {
                return Object.keys(this.numberOfElements).length > 0;
            },
            elements() {
                return this.componentInputs.filter(componentInput => componentInput.key.includes('element_'));
            },
            componentInputs() {
                return this.isCategory ? this.component.componentInputs : this.component.componentInputs.filter(componentInput => !componentInput.key.includes('category_only_'));
            },
        },

        components: {
            PowerpagePinsComponent,
            PowerpageInputFields,
            OptionsMenu,
        },

        methods: {
            visibleField(componentInput) {
                if (componentInput.parent !== null) {
                    const parent = this.componentInputs.find(input => input.id === componentInput.parent);

                    return parent.value;
                }

                return true;
            },

            refreshComponent() {
                this.$emit('refreshComponent');
            },
            getElementNumber(key) {
                return key.replace(/^\D+/g, '').charAt(0);
            },
            isFirstItemOfElements($element) {
                return this.elements.indexOf($element) === 0;
            },
            changeNumberOfElements(key, value) {
                if (key.includes('number_of_elements_')) {
                    this.$set(this.numberOfElements, 'e' + this.getElementNumber(key), parseInt(value));
                }
            },
            deleteComponent() {
                this.$emit('deleteComponent');
            },

            moveOrderUp() {
                this.component.order--;
                this.$emit('orderChanged', { order: this.component.order, direction: 'up' });

                this.moveToComponent();
            },

            moveOrderDown() {
                this.component.order++;
                this.$emit('orderChanged', { order: this.component.order, direction: 'down' });

                this.moveToComponent();
            },

            moveToComponent() {
                setTimeout(() => {
                    document.getElementById(this.component.uuid).scrollIntoView({
                        behavior: 'smooth',
                        block: "center",
                    });
                }, 501);
            },
        },
    };
</script>

<style scoped
       lang="scss">
    .powerpage-component {

        ::v-deep .field {
            .error-message {
                border     : 1px solid red;
                padding    : 10px;
                font-size  : 14px;
                background : #ffe8e8;
                margin     : 10px 0px;
            }

            .field-control {
                &.error {
                    input, textarea, .redactor-box {
                        outline : 1px solid red;
                    }
                }
            }

            &--image {
                .field-control {
                    height : auto;
                }
            }
        }

        .card-body {
            display        : flex;
            flex-direction : column;

            .field {
                &.element {
                    margin-left : 30px;
                }

                &.first-item::before {
                    margin-left   : -30px;
                    content       : attr(data-name);
                    font-size     : 12px;
                    font-weight   : 500;
                    line-height   : 14px;
                    color         : rgba(26, 26, 26, 0.7);
                    margin-bottom : 20px;
                    display       : block;
                    margin-top    : 10px;
                }

                &.first-item {
                    margin-top : 30px;
                }
            }
        }

        .card-title {
            display     : flex;
            align-items : center;

            .thumbnail {
                margin-left : 10px;
                width       : 80px;
                height      : 30px;
                border      : 1px solid rgba(6, 14, 19, 0.1);
                cursor      : pointer;
                z-index     : 101;
                background  : white;

                &:hover {
                    transform : scale(8);
                }

                img {
                    width      : 100%;
                    height     : 100%;
                    object-fit : contain;
                }
            }
        }
    }
</style>
