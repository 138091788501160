<template>
    <div class="configuration-tree">
        <configuration-tree-row v-model="configuration" />
    </div>
</template>

<script>
    import ConfigurationTreeRow from './ConfigurationTreeRow';

    export default {
        name: 'ConfigurationTree',
        components: { ConfigurationTreeRow },
        props: {
            configuration: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .configuration-tree {
        padding-left : 5px;

        ::v-deep .configuration {
            display         : flex;
            align-items     : center;
            justify-content : space-between;

            .label {
                background    : #DBEDF0;
                border-radius : 6px;
                padding       : 12px;
                cursor        : pointer;

                display       : inline-flex;
                align-items   : center;

                &.selected, &:hover {
                    background : linear-gradient(180deg, #009EB3 0%, #027988 100%);
                    color      : #FFFFFF;

                    svg {
                        path {
                            fill : #FFFFFF;
                        }
                    }
                }

                &.error {
                    background : linear-gradient(180deg, #FF002D 0%, #6F0315 100%);
                    color      : #FFFFFF;

                    svg {
                        path {
                            fill : #FFFFFF;
                        }
                    }
                }
            }

            .icon {
                svg, img {
                    height       : 13px;
                    margin-right : 10px;
                    display      : block;
                }

                svg {
                    path {
                        fill : #000000;
                    }
                }
            }
        }

        ::v-deep .configuration-option {
            position    : relative;
            padding     : 16px 0 0 24px;
            box-sizing  : border-box;
            margin-left : 18px;

            &:before {
                position         : absolute;
                top              : 24px;
                left             : 0;
                width            : 15px;
                height           : 1px;
                margin           : auto;
                content          : '';
                background-color : #666666;
            }

            &:after {
                position         : absolute;
                top              : 0;
                bottom           : 0;
                left             : 0;
                width            : 1px;
                height           : 100%;
                content          : '';
                background-color : #666666;
            }

            &:last-child:after {
                height : 24px;
            }

            .child {
                padding-top : 16px;
            }

            .add-sub-configuration, .delete-configuration {
                display     : flex;
                align-items : center;
                cursor      : pointer;
                font-size   : 12px;

                svg {
                    margin-left : 5px;
                    height      : 20px;

                    path {
                        fill : #009EB3;
                    }
                }

                .text {
                    display : none;
                }

                &:hover {
                    .text {
                        display : block;
                    }
                }
            }

            .delete-configuration {
                svg {
                    path {
                        fill : #E3342F;
                    }
                }
            }

            > .row {
                display         : flex;
                align-items     : center;
                justify-content : space-between;

                .configuration-option-label {
                    display     : flex;
                    align-items : center;
                }

                .colorIndicator {
                    width           : 14px;
                    height          : 14px;
                    border-radius   : 50%;
                    display         : block;
                    margin-right    : 10px;
                    background-size : cover;
                }
            }
        }
    }
</style>
