<template>
    <div class="product-detail">
        <div class="page-header page-header--push-top">
            <h1 class="page-title">{{$t('redirects.redirect')}}</h1>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('redirects.from') }}</label>
                <div class="field-control">
                    <input type="text" v-model="redirect.from" />
                </div>
            </div>
            <div class="field">
                <label class="label">{{ $t('redirects.to') }}</label>
                <div class="field-control">
                    <input type="text" v-model="redirect.to" />
                </div>
            </div>
        </div>
        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('redirects.save') }}
        </a>
        <a href="#" class="btn btn--link" v-if="redirect.id" @click.prevent="deleteModal = true">
            {{ $t('redirects.delete') }}
        </a>
        <BaseModal v-if="deleteModal"
                   :is-confirmation="true"
                   :title="$t('redirects.delete_modal_title')"
                   :content="$t('redirects.delete_modal_content')"
                   @close="deleteModal = false"
                   @confirm="deleteRedirect()" />
    </div>
</template>
<script>
    import { RedirectService } from "../../services";
    import BaseModal from '../../components/BaseModal';

    export default {
        name: 'redirects-show',
        components: {
            BaseModal
        },

        data() {
            return {
                deleteModal: false,
                redirect: { from: '', to: '' },
            };
        },

        beforeMount() {
            this.fetchRedirect();
        },

        methods: {
            fetchRedirect() {
                if (this.$route.path !== '/redirects/create') {
                    RedirectService.show(this.$route.params.id).then(response => {
                        this.redirect = response.data;
                    })
                }
            },

            deleteRedirect(){
                RedirectService.destroy(this.redirect.id).then(response => {
                    this.$router.push({ path: '/redirects' });
                })
            },

            save() {
                let promise;
                if (this.redirect.id) {
                    promise = RedirectService.update(this.redirect.id, this.redirect).then(response => {
                        this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                        this.$router.push({ path: '/redirects' });
                    });
                } else {
                    promise = RedirectService.store(this.redirect).then(response => {
                        this.redirect = response.data;
                        this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                        this.$router.push({ path: '/redirects' });
                    });
                }

                promise.catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");
                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
    }
</script>
