import {appendToFormData} from "../helpers";
import BaseService from './index';

const baseParams = {
    baseURL: process.env.MIX_APP_URL,
};

export default {
    index(params) {
        return BaseService.get(`/backend/powerpages/pages`, params, baseParams)
    },

    show(powerpageId) {
        return BaseService.get(`/powerpages/pages/${powerpageId}`, {}, baseParams);
    },

    store(params, relationship) {
        const formData = new FormData();

        Object.entries(params).forEach(([key,value]) => {
            appendToFormData(formData, key, value);
        });

        const route = relationship ? '/backend/powerpages/pages' : '/powerpages/pages';

        return BaseService.post(route, formData, {}, baseParams);
    },

    update(powerpageId, params, relationship) {
        const formData = new FormData();

        Object.entries(params).forEach(([key,value]) => {
            appendToFormData(formData, key, value);
        });
        appendToFormData(formData, '_method', 'PUT');

        const route = relationship ? `/backend/powerpages/pages/${powerpageId}` : `/powerpages/pages/${powerpageId}`;

        return BaseService.post(route, formData, {}, baseParams);
    },

    delete(id) {
        return BaseService.delete(`/powerpages/pages/${id}`, baseParams);
    },
}
