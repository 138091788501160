<template>
    <div>
        <div class="filters space-between">
            <div class="search">
                <img src="/svgs/search-icon.svg"
                     alt="search-icon">
                <input class="search"
                       type="text"
                       v-model="searchQuery"
                       @input="onSearchInput" />

            </div>

            <div>
                <router-link :to="{name: 'products.related-products.create'}"
                             class="btn btn--primary">
                    {{ $t('related_products.add_related_product') }}
                </router-link>
            </div>
        </div>

        <table class="table">
            <thead>
                <tr>
                    <th>{{ $t('related_products.product') }}</th>
                </tr>
            </thead>

            <draggable tag="tbody"
                       v-model="relatedProductGroups"
                       @change="updatePosition">
                <tr v-for="relatedProductGroup in relatedProductGroups"
                    :key="'related_product_' + relatedProductGroup.id"
                    :id="relatedProductGroup.id"
                    @click="editRelatedProduct(relatedProductGroup.id)">
                    <td>{{ relatedProductGroup.title }}</td>
                </tr>
            </draggable>
        </table>
    </div>
</template>

<script>
    import debounce from "lodash/debounce";
    import draggable from 'vuedraggable'
    import { RelatedProductGroupService } from "../../../services";

    export default {
        name: 'related-products-tab',

        components: {
            draggable,
        },

        data() {
            return {
                searchQuery: '',
                relatedProductGroups: [],
            };
        },

        beforeMount() {
            this.fetchRelatedProducts();
        },

        methods: {
            onSearchInput: debounce(function () {
                this.fetchRelatedProducts();
            }, 250),

            fetchRelatedProducts() {
                const productId = this.$route.params.id;

                RelatedProductGroupService.index(productId, this.searchQuery)
                    .then(response => {
                        this.relatedProductGroups = response.data.data;
                    });
            },

            editRelatedProduct(relatedProductGroupId) {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.related-products.edit',
                    params: {
                        id: productId,
                        relatedProductGroup: relatedProductGroupId
                    }
                });
            },

            createRelatedProduct() {
                const productId = this.$route.params.id;

                this.$router.push({
                    name: 'products.relevante-producten.create',
                    params: {
                        id: productId
                    }
                });
            },

            updatePosition(e) {
                const relatedProductGroupPositions = this.relatedProductGroups
                    .map((relatedProductGroup, idx) => {
                        return {
                            id: relatedProductGroup.id,
                            position: idx,
                        };
                    });
                RelatedProductGroupService.updatePositions(this.$route.params.id, relatedProductGroupPositions);
            },
        }
    }
</script>
