export default {
    nl: {
        choice_help: {
            choice_help: 'Keuzehulp',
            general_information: 'Algemene informatie',
            answers: 'Antwoorden',
            questions: 'Keuzehulpvragen',
            question: 'Keuzehulpvraag:',
            name: 'Vraag',
            description: 'Beschrijving',
            back: 'Terug naar overzicht',

            new_question: 'Nieuwe keuzehulpvraag',
            add_question: 'Nieuwe vraag toevoegen',
            add_answer: 'Nieuw antwoord toevoegen',
            delete_answer: 'Antwoord verwijderen',

            new_answer: 'Nieuw antwoord',
            answer: 'Antwoord',
            filter_option: 'Filteroptie',
            image: 'Afbeelding',
            follow_up_question: 'Vervolgvraag',
            answer_title: 'Antwoord',
            answer_description: 'Omschrijving',

            save_question: 'Keuzehulpvraag opslaan',
            delete: 'Nieuws artikel verwijderen',

            delete_modal:{
                title: 'Antwoord verwijderen',
                content: 'Weet u zeker dat u dit antwoord wil verwijderen?'
            }
        },
    },
}
