import { CategoryService } from '../../services';

const state = {
    category: [],
    categories: [],
};

const getters = {};

const actions = {
    fetch_category({ commit }, categoryId) {
        const promise = CategoryService.show(categoryId);

        promise.then(response => {
            commit('set_category', response.data);
        });

        return promise;
    },

    fetch_categories({ commit, state }) {
        if (state.categories.length > 0) {
            return new Promise((resolve, reject) => resolve(state.categories));
        }

        const promise = CategoryService.index();

        promise.then(response => {
            commit('set_categories', response.data);
        });

        return promise;
    },

    update_category({ state }) {
        return CategoryService.update(state.category.id, state.category);
    },
};

const mutations = {
    set_category(state, category) {
        state.category = category;
    },

    clear_category(state) {
        state.category = []
    },

    set_categories(state, categories) {
        state.categories = categories;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
