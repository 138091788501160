<template>
    <div>
        <div class="field">
            <label class="label">{{ $t('pages.about_us_page.video') }}</label>
            <div class="field-control">
                <input type="text" v-model="form.video">
            </div>
        </div>

        <div>
            <button class="btn btn--orange" @click="save">
                {{ $t('general.save') }}
            </button>
        </div>
    </div>
</template>

<script>
    import AboutUsPageService from '../../services/cms/about-us-page';

    export default {
        data() {
            return {
                form: {
                    video: "",
                },
            };
        },
        methods: {
            save() {
                AboutUsPageService.update({ ...this.form }).then(_ => {
                    this.$flashMessage('Succesvol opgeslagen', 3500, 'succes')
                }).catch(err => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                })
            },
        },
        async mounted() {
            this.form = (await AboutUsPageService.get()).data.data;
        },
    }
</script>
