<template>
    <div class="powerpage-tab">
        <a @click.prevent="savePowerpage" class="btn btn--orange store-powerpage" href="#">
            {{ $t('products.store_powerpage') }}
        </a>

        <div class="field field--push-medium field--checkbox" style="margin-top: 48px">
            <input type="checkbox" id="isVisible" v-model="product.powerpage_enabled" @change="savePowerpageStatus" />
            <div class="field-control">
                <label for="isVisible">Powerpage inschakelen</label>
            </div>
        </div>

        <PowerpageCreateForm :is-product="true"
                             :is-relationship="true"
                             :page-id="product.powerpages_page_id ? product.powerpages_page_id : null"
                             ref="powerpageForm"
                             @powerpageSaved="setPowerpage" />
    </div>
</template>

<script>

    import { ProductService } from '../../../services';
    import PowerpageService from '../../../services/powerpages';
    import PowerpageCreateForm from '../../powerpages/PowerpageCreateForm';

    export default {
        components: { PowerpageCreateForm },
        props: {
            product: {
                required: true,
            },
        },
        methods: {
            async savePowerpage() {
                await this.$refs.powerpageForm.savePowerpage(true);
            },
            async savePowerpageStatus() {
                return ProductService.updatePowerpageEnabled(this.product.id, this.product.powerpage_enabled)
                                     .then(() => {
                                         this.$flashMessage(this.product.powerpage_enabled ? this.$t('products.powerpage_enabled') : this.$t('products.powerpage_disabled'), 3500, 'success');
                                     })
                                     .catch((err) => {
                                         const firstError = err.response.data.errors[Object.keys(err.response.data.errors)[0]];
                                         this.$flashMessage(firstError[0], 3500, 'error');
                                     });
            },
            async setPowerpage($id) {
                this.product.powerpages_page_id = $id;
                await this.$store.commit('products/set_product', this.product);

                let promise = this.$store.dispatch('products/update_product');

                promise.then(() => {
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                });

                promise.catch(err => {
                    const errors = Object.entries(err.response.data.errors)
                                         .map((err) => err[1])
                                         .flat()
                                         .join('<br />');

                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
        name: 'PowerpageTab',
    };
</script>
<style lang="scss" scoped>
    .powerpage-tab {
        .store-powerpage {
            margin-bottom : 30px;
            float         : right;
        }
    }
</style>
