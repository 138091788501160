import BaseService from "../index";

export default {
    get() {
        return BaseService.get(`/cms/returns-and-refunds-page`);
    },

    update(params) {
        return BaseService.put(`/cms/returns-and-refunds-page`, params);
    },
};
