<template>
    <div class="category-filters">
        <table class="table">
            <thead>
                <tr>
                    <th class="highlighted">Visible</th>
                    <th class="highlighted">Highlighted</th>
                    <th>Filter category</th>
                </tr>
            </thead>
            <draggable @end="savePriority($event)" tag="tbody" v-model="category.category_filter_category">
                <template v-for="pivot in category.category_filter_category">
                    <tr v-if="pivot.filter_category && pivot.filter_category.id"
                        :id="pivot.filter_category.id">
                        <td class="highlighted">
                            <div @click="pivot.is_enabled = !pivot.is_enabled" class="checkbox"
                                 :class="{'checked':pivot.is_enabled}">
                            </div>
                        </td>
                        <td class="highlighted">
                            <div @click="pivot.is_highlighted = !pivot.is_highlighted" class="checkbox"
                                 :class="{'checked':pivot.is_highlighted}">
                            </div>
                        </td>
                        <td>{{ pivot.filter_category.name }}</td>
                    </tr>
                </template>
            </draggable>
        </table>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: "FiltersTab",
        components: {
            draggable
        },
        props: {
            category: {
                required: true
            },
        },
        methods: {
            savePriority(e) {
                this.category.category_filter_category = this.category.category_filter_category.map((pivot, index) => {
                    pivot.priority = index + 1;
                    return pivot;
                });
                this.$store.commit('categories/set_category', this.category)
            },
        }
    }
</script>
