import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/static`, params);
    },

    store(params) {
        return BaseService.post(`/static`, params);
    },

    show(itemId) {
        return BaseService.get(`/static/${itemId}`);
    },

    update(itemId, params) {
        return BaseService.post(`/static/${itemId}`, params);
    },

    destroy(itemId) {
        return BaseService.post(`/static/${itemId}/destroy`);
    },
}
