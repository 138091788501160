<template>
  <div class="category-settings">
    <div @click="selectInspiration" class="field field--checkbox">
      <input :checked="!!category.is_inspiration" type="checkbox" />
      <div class="field-control">
        <label>{{ $t('categories.is_inspiration') }}</label>
      </div>
    </div>
    <div @click="selectCustom" class="field field--checkbox">
      <input :checked="!!category.is_custom" type="checkbox" />
      <div class="field-control">
        <label>{{ $t('categories.is_custom') }}</label>
      </div>
    </div>
    <div @click="selectFilterAccessoires" class="field field--checkbox">
      <input :checked="!!category.can_filter_accessoires" type="checkbox" />
      <div class="field-control">
        <label>{{ $t('categories.can_filter_accessoires') }}</label>
      </div>
    </div>
    <div @click="selectIsSolution" class="field field--checkbox" v-if="category.category_id == null">
      <input :checked="!!category.is_solution" type="checkbox" />
      <div class="field-control">
        <label>{{ $t('categories.is_solution') }}</label>
      </div>
    </div>
    <div @click="selectIsHidden" class="field field--checkbox">
      <input :checked="!!category.is_hidden" type="checkbox" />
      <div class="field-control">
        <label>{{ $t('categories.is_hidden') }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsTab",
  props: {
    category: {
      required: true
    },
  },
  methods: {
    selectInspiration() {
      this.category.is_inspiration = !this.category.is_inspiration;
      this.saveSettings()
    },
    selectCustom() {
      this.category.is_custom = !this.category.is_custom;
      this.saveSettings();
    },
    selectFilterAccessoires() {
      this.category.can_filter_accessoires = !this.category.can_filter_accessoires;
      this.saveSettings();
    },
    selectIsSolution() {
      this.category.is_solution = !this.category.is_solution;
      this.saveSettings();
    },
    selectIsHidden() {
      this.category.is_hidden = !this.category.is_hidden;
      this.saveSettings();
    },
    saveSettings() {
      this.$store.commit('categories/set_category', this.category)
    },
  }
}
</script>
