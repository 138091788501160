import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/discount-codes`, params);
    },

    store(params) {
        return BaseService.post(`/discount-codes`, params);
    },

    show(id) {
        return BaseService.get(`/discount-codes/${id}`);
    },

    update(id, params) {
        return BaseService.put(`/discount-codes/${id}`, params);
    },
}
