import BaseService from './index';

const baseParams = {
    baseURL: process.env.MIX_APP_URL,
};

export default {
    index(params) {
        return BaseService.get(`/powerpages/filters`, params, baseParams)
    },

    show(filterId) {
        return BaseService.get(`/powerpages/filters/${filterId}`, {}, baseParams);
    },

    store(params) {
        return BaseService.post(`/powerpages/filters`, params, {}, baseParams);
    },

    update(filterId, params) {
        return BaseService.post(`/powerpages/filters/${filterId}`, params, {}, baseParams);
    },
}
