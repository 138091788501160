export default {
    data() {
        return {
            numberOfElements: {},
        };
    },
    mounted() {
        const inputs = this.component.componentInputs.filter(componentInput => componentInput.key.includes('number_of_elements_'));

        inputs.forEach(input => {
            this.$set(this.numberOfElements, 'e' + this.getElementNumber(input.key), parseInt(input.value != null ? input.value : 1));
        });
    },
    computed: {
        hasNumberOfElements() {
            return Object.keys(this.numberOfElements).length > 0;
        },
        elements() {
            return this.component.componentInputs.filter(componentInput => componentInput.key.includes('element_'));
        },
    },
    methods: {
        getElementNumber(key) {
            return key.replace(/^\D+/g, '').charAt(0);
        },
        elementsByIndex(index) {
            return this.elements.filter(element => element.key.startsWith(`element_${index}_`));
        },
        isFirstItemOfElements($element, index = null) {
            if(this.index !== null) {
                return this.elementsByIndex(index).indexOf($element) === 0;
            }

            return this.elements.indexOf($element) === 0;
        },
        changeNumberOfElements(key, value) {
            if (key.includes('number_of_elements_')) {
                this.$set(this.numberOfElements, 'e' + this.getElementNumber(key), parseInt(value));
            }
        },
    },
};
