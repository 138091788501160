export default {
    nl: {
        filters: {
            tab_title: 'Filters',
            open: 'Standaard opengeklapt',

            create_filter: 'Filter toevoegen',
            edit_filter: 'Filter aanpassen',

            save: 'Filter opslaan',
            delete: 'Filter verwijderen',

            title: 'Filter titel',
            option: 'Optie',

            filters: 'Filters',
            filter: 'Filter',
            new: 'Nieuwe Filter toevoegen',
            options: 'Filter opties',
            add_filter: 'Antwoord toevoegen',

            save_success: 'Filter opgeslagen',
            update_success: 'Filter aangepast',

            delete_modal: {
                title: 'Filter verwijderen',
                content: 'Wil je dit filter echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },

            label: 'Tonen als label',
            abovePriceSlider: 'Tonen boven het prijs filter',
            color: 'Kleur label',
        },
    },
};
