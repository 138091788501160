<template>
    <div @click="click"
         class="file-input-wrapper">
        <input @change="fileChanged"
               class="file-input"
               ref="fileInput"
               type="file"
               :name="name">
        <div :key="image"
             class="file-input-button">
            <slot :image="image"
                  :is-loading="isLoading" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FileInput',
        props: {
            value: {},
            default: {
                required: false,
                type: String,
                default() {
                    return '/images/happy-emoji.png';
                },
            },
            createBase64: {
                required: false,
                type: Boolean,
                default() {
                    return true;
                },
            },
            name: {
                required: false,
                type: String,
                default() {
                    return null;
                },
            },
        },
        data() {
            return {
                base64: null,
                isLoading: false,
            };
        },
        created() {
            if (typeof this.value === 'string') {
                this.$emit('base64Updated', this.value);
            }
        },
        methods: {
            getBase64(file) {
                this.isLoading = true;
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                })).then((res) => {
                    this.isLoading = false;

                    return res;
                });
            },
            click(e) {
                this.$refs.fileInput.click(e);
            },
            async fileChanged(e) {
                const file = Array.from(e.target.files)[0];

                this.$emit('input', file);

                if (this.createBase64) {
                    this.base64 = await this.getBase64(file);
                    this.$emit('base64Updated', this.base64);
                } else {
                    this.base64 = true;
                }
            },
        },
        computed: {
            image: {
                get() {
                    if (this.base64 !== null) {
                        return this.base64;
                    }

                    if (typeof this.value === 'string') {
                        return this.value;
                    }

                    return this.default;
                },
            },
        },
    };
</script>

<style lang="scss"
       scoped>
    .file-input-wrapper {
        position         : relative;
        width            : 100%;
        height           : 112px;
        background-color : #002D4D;
        display          : flex;
        align-items      : center;
        box-sizing       : border-box;
        padding          : 0 24px;
        border-radius    : 4px;

        &.small {
            height : 64px;

            .file-input-button {
                height : 48px;

                img,
                .img-placeholder {
                    width  : 48px;
                    height : 48px;
                }
            }
        }

        .file-input {
            display : none;
        }

        .file-input-button {
            position    : relative;
            display     : flex;
            align-items : center;
            width       : 100%;
            height      : 64px;
            max-height  : 64px;
            cursor      : pointer;

            span {
                display     : block;
                opacity     : 1;
                margin-left : 16px;
                font-size   : 16px;
                font-weight : 600;
                line-height : 64px;
                color       : #FFFFFF;
                text-align  : center;
            }

            img,
            .img-placeholder {
                display       : block;
                position      : relative;
                width         : 64px;
                height        : 64px;
                object-fit    : cover;
                border-radius : 32px;
                margin        : 0 8px 0 0;
                background    : #F26419;

                &.checkmark {
                    padding    : 16px 0;
                    box-sizing : border-box;
                    object-fit : contain;
                    background : white;
                }
            }
        }

        &.new-object-file-input {
            height : 144px;

            .file-input-button {
                height     : 100%;
                max-height : unset;

                .object-image {
                    width : 100%;
                }
            }
        }

        .object-image {
            display       : flex;
            flex-flow     : row nowrap;
            position      : relative;
            background    : rgba(225, 2, 116, 0.1);
            border-radius : 4px;
            padding       : 20px 24px;
            margin        : 0 0 24px 0;

            .img-placeholder,
            img {
                display          : block;
                position         : relative;
                width            : 64px;
                height           : 64px;
                object-fit       : cover;
                border-radius    : 32px;
                margin           : 0 16px 0 0;
                background-color : white;

                &.checkmark {
                    padding    : 16px 0;
                    box-sizing : border-box;
                    object-fit : contain;
                }
            }

            span {
                line-height : 64px;
                font-weight : 600;
                font-size   : 16px;
            }
        }
    }
</style>
