import { ChoiceHelpService } from '../../services';

const state = {
    question: {},
};

const getters = {};

const actions = {
    fetch_question({ commit }, questionId) {
        const promise = ChoiceHelpService.show(questionId);

        promise.then(response => {
            commit('set_question', response.data);
        });

        return promise;
    },
};

const mutations = {
    set_question(state, question) {
        state.question = question;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

