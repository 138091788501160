<template>
    <div>
        <div class="page-header">
            <div>
                <h1 v-if="divider.id"
                    class="page-title">{{ divider.divider_label }}</h1>
                <h1 v-else
                    class="page-title">{{ $t('categories.dividers.add') }}</h1>
            </div>

            <div>
                <a href="#"
                   class="btn btn--link"
                   v-if="divider.id"
                   @click.prevent="deleteDivider">
                    {{ $t('categories.dividers.delete') }}
                </a>
                <a href="#"
                   class="btn btn--orange"
                   @click.prevent="saveDivider">
                    {{ $t('categories.dividers.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('categories.dividers.name') }}</label>
                <div class="field-control">
                    <input type="text"
                           v-model="divider.divider_label">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { DividerService } from "../../../../services";

    export default {
        name: 'divider',

        beforeRouteEnter(to, from, next) {
            if (to.params.divider) {
                DividerService.show(to.params.id, to.params.divider).then(response => {
                    next(vm => {
                        vm.divider = response.data;
                    })
                }).catch((err) => {
                    next({
                        name: 'categories.labels',
                        params: { id: to.params.id },
                    });
                });
            } else {
                next();
            }
        },

        data() {
            return {
                divider: {},
            };
        },

        methods: {
            saveDivider() {
                if (this.divider.id) {
                    this.update(this.divider.id, this.divider);
                } else {
                    this.store(this.divider);
                }
            },
            deleteDivider() {
                DividerService.delete(this.divider.category_id, this.divider.id)
                    .then(response => {
                        this.$flashMessage(this.$t('categories.dividers.delete_success'), 3500, 'success');
                        this.$router.push({
                            name: 'categories.category_dividers',
                            params: {
                                id: this.$route.params.id,
                            },
                        });
                    })
                    .catch(err => {
                        const errors = Object.entries(err.response.data.errors)
                            .map((err) => err[1])
                            .flat()
                            .join("<br />");

                        this.$flashMessage(errors, 3500, 'error');
                    });
            },
            store(params) {
                DividerService.store(this.$route.params.id, params).then(response => {
                    this.$flashMessage(this.$t('categories.dividers.save_success'), 3500, 'success');
                    this.$router.push({
                        name: 'categories.category_dividers',
                        params: { id: this.$route.params.id}
                    });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            update(dividerId, params) {
                DividerService.update(this.$route.params.id, dividerId, params).then(response => {
                    this.$flashMessage(this.$t('categories.dividers.update_success'), 3500, 'success');
                    this.$router.push({
                        name: 'categories.category_dividers',
                        params: { id: this.$route.params.id }
                    });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            saveRelevantProduct() {
                const productId = this.$route.params.id;
                const relevantProductId = this.$route.params.relevantProduct;

                const params = {
                    relevant_product_id: this.relevantProduct.id,
                };

                if (!relevantProductId) {
                    this.store(productId, params);
                    return;
                }

                this.update(relevantProductId, productId, params);
            },
        },
    };
</script>
