<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">
                {{ $route.params.id ? $t('filters.edit_filter') : $t('filters.create_filter') }}
            </h1>

            <div>
                <a href="#"
                   class="btn btn--link"
                   v-if="$route.params.id"
                   @click.prevent="isDeleteModalActive = true">
                    {{ $t('filters.delete') }}
                </a>

                <a href="#"
                   class="btn btn--orange"
                   @click.prevent="saveFilter">
                    {{ $t('filters.save') }}
                </a>
            </div>
        </div>

        <div class="half-size">
            <div class="panel">
                <div class="panel-heading">
                    <h3 class="panel-title">{{ $t('filters.title') }}</h3>
                </div>

                <div class="field field--push-hard">
                    <div class="field-control">
                        <input type="text"
                               v-model="filter" />
                    </div>
                </div>

                <div @click="is_open = !is_open"
                     class="field field--checkbox">
                    <input :checked="is_open"
                           type="checkbox" />
                    <div class="field-control">
                        <label>{{ $t('filters.open') }}</label>
                    </div>
                </div>

                <div @click="showAsLabel = !showAsLabel"
                     class="field field--checkbox">
                    <input :checked="showAsLabel"
                           type="checkbox" />
                    <div class="field-control">
                        <label>{{ $t('filters.label') }}</label>
                    </div>
                </div>

                <div @click="showAbovePriceSlider = !showAbovePriceSlider"
                     class="field field--checkbox">
                    <input :checked="showAbovePriceSlider"
                           type="checkbox" />
                    <div class="field-control">
                        <label>{{ $t('filters.abovePriceSlider') }}</label>
                    </div>
                </div>

                <h3 class="panel-title panel-title--push panel-title--brighter">{{ $t('filters.options') }}</h3>

                <div>
                    <div class="sub-panel"
                         v-for="(option, index) in options"
                         :key="'filter_option_' + index">
                        <div class="sub-panel-heading">
                            <h3 class="sub-panel-title">{{ $t('filters.option') }}</h3>

                            <options-menu>
                                <a href="#"
                                   @click.prevent="deleteOption(index)">Verwijderen</a>
                            </options-menu>
                        </div>

                        <div :class="`field ${showAsLabel ? '' : 'field--no-margin'}`">
                            <div class="field-control">
                                <input type="text"
                                       v-model="option.option" />
                            </div>
                        </div>

                        <div class="field field--no-margin"
                             v-if="showAsLabel">
                            <label class="label">{{ $t('filters.color') }}</label>
                            <div class="field-control">
                                <color-picker-input v-model="option.label_color" />
                            </div>
                        </div>
                    </div>

                    <div class="new-row"
                         @click="addNewOption">
                        <div class="new-row__circle">
                            <img src="/svgs/plus.svg">
                        </div>

                        <span>{{ $t('filters.add_filter') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <BaseModal v-if="isDeleteModalActive"
                   :is-confirmation="true"
                   :title="$t('filters.delete_modal.title')"
                   :content="$t('filters.delete_modal.content')"
                   @close="isDeleteModalActive = false"
                   @confirm="deleteFilter" />
    </div>
</template>

<script>
    import BaseModal from '../../components/BaseModal';
    import ColorPickerInput from "../../components/color-picker-input";
    import OptionsMenu from '../../components/options-menu';
    import { FilterService } from '../../services';

    export default {
        name: 'filter-form',

        components: {
            ColorPickerInput,
            OptionsMenu,
            BaseModal,
        },

        beforeRouteEnter(to, from, next) {
            if (to.params.id) {
                FilterService.show(to.params.id).then(response => {
                    next(vm => {
                        vm.filter = response.data.name;
                        vm.is_open = response.data.is_open;
                        vm.showAsLabel = response.data.show_as_label;
                        vm.showAbovePriceSlider = response.data.show_above_price_slider;
                        vm.options = response.data.filters;
                    })
                }).catch((err) => {
                    next({ name: 'filters.index' });
                });
            } else {
                next();
            }
        },

        data() {
            return {
                filter: null,
                is_open: false,
                showAsLabel: false,
                showAbovePriceSlider: false,
                options: [],
                isDeleteModalActive: false,
            };
        },

        methods: {
            addNewOption() {
                this.options.push({
                    id: null,
                    title: '',
                    label_color: null,
                });
            },

            deleteOption(index) {
                this.options.splice(index, 1);
            },

            store(params) {
                FilterService.store(params).then(response => {
                    this.$flashMessage(this.$t('filters.save_success'), 3500, 'success');
                    this.$router.push({ name: 'filters.edit', params: { id: response.data.id } });
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            update(id, params) {
                FilterService.update(id, params).then(response => {
                    this.$flashMessage(this.$t('filters.update_success'), 3500, 'success');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },

            saveFilter() {
                const id = this.$route.params.id;

                const params = {
                    name: this.filter,
                    is_open: this.is_open,
                    show_as_label: this.showAsLabel,
                    show_above_price_slider: this.showAbovePriceSlider,
                    options: this.options,
                };

                if (!id) {
                    this.store(params);
                    return;
                }

                this.update(id, params);
            },

            deleteFilter() {
                const id = this.$route.params.id;

                FilterService.delete(id).then(() => {
                    this.$router.push({ name: 'filters.index' });
                }).catch((err) => {
                    this.isDeleteModalActive = false;
                    this.$flashMessage(err.response.data.message, 3500, 'error');
                });
            },
        },
    }
</script>
