export default {
    nl: {
        static_page: {
            title: 'Static meta data',
            redirect: 'Redirect',
            redirects: 'Redirects',
            add: 'Data toevoegen',
            delete: 'Verwijderen',
            url: 'Pagina url',
            meta_title: 'Meta titel',
            meta_description: 'Meta beschrijving',
            save: 'Opslaan',
            delete_modal_title: 'Meta data verwijderen',
            delete_modal_content: 'Wil je deze meta data echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
        },
    },
}
