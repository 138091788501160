<template>
    <div>
        <div class="field">
            <label class="label">{{ $t('general.header_title') }}</label>
            <div class="field-control">
                <input type="text" v-model="headerTitle">
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('general.header_description') }}</label>
            <div class="field-control">
                <textarea type="text" v-model="headerDescription" />
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('general.header_button_text') }}</label>
            <div class="field-control">
                <input type="text" v-model="headerButtonText">
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('general.header_button_url') }}</label>
            <div class="field-control">
                <input type="text" v-model="headerButtonUrl">
            </div>
        </div>

        <div class="field field--push-harder field--image">
            <label class="label">Header afbeelding</label>
            <div class="field-control" v-if="headerImage === null">
                <label for="image-input" class="btn btn--blue">
                    {{ $t('images.add_image') }}
                </label>

                <input id="image-input"
                       name="images"
                       type="file"
                       accept="image/*"
                       @change="fileInputChange($event)">
            </div>
            <div class="field-control" v-else>
                <div class="image-container">
                    <img class="image" :src="headerImage.path || headerImage" alt="">
                    <div class="icon" @click="headerImage = null">
                        <img src="/svgs/close-icon.svg" alt="close-icon">
                    </div>
                </div>
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('general.homepage_video') }}</label>
            <div class="field-control">
                <input type="text" v-model="homepageVideo">
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('general.recommendedProducts') }}</label>
            <div class="field-control">
                <multiselect v-model="selectedRecommendedProducts"
                             :options="products"
                             label="name"
                             :multiple="true"
                             track-by="id"
                             :max="4"
                             :loading="isFetchingProducts"
                             @search-change="searchMultiselectProducts"
                             placeholder="Selecteer producten" />
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('general.categories') }}</label>
            <div class="field-control">
                <multiselect v-model="selectedCategories"
                             :options="categories"
                             label="name"
                             :multiple="true"
                             track-by="id"
                             :loading="isFetchingProducts"
                             @search-change="searchMultiselectCategories"
                             placeholder="Selecteer categorien" />
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('general.workfield') }}</label>
            <div class="field-control">
                <multiselect v-model="selectedWorkfieldCategories"
                             :options="categories"
                             label="name"
                             :multiple="true"
                             track-by="id"
                             :loading="isFetchingProducts"
                             @search-change="searchMultiselectCategories"
                             placeholder="Selecteer categorien" />
            </div>
        </div>

        <div>
            <a href="#" class="btn btn--orange" @click.prevent="save">
                {{ $t('general.save') }}
            </a>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import Multiselect from 'vue-multiselect';
    import { CategoryService, HomepageCmsService, ProductService } from '../../services';

    export default {
        name: 'home-page-settings',

        components: { Multiselect },

        data() {
            return {
                selectedRecommendedProducts: [],
                selectedWorkfieldCategories: [],
                selectedCategories: [],
                products: [],
                categories: [],
                isFetchingProducts: false,
                headerImage: null,
                headerTitle: null,
                headerDescription: null,
                headerButtonText: null,
                headerButtonUrl: null,
                homepageVideo: null,
            };
        },

        async beforeMount() {
            await this.fetchProducts();
            await this.fetchCategories();
            this.fetchHomePageCmsSettings();
        },

        methods: {
            async fetchProducts() {
                const products = await ProductService.index();
                this.products = await products.data.data;
            },

            async fetchCategories() {
                const categories = await CategoryService.index();
                this.categories = await categories.data;
            },

            fileInputChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                const reader = new FileReader();

                if (!files.length) {
                    return;
                }
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    this.headerImage = e.target.result;
                };
            },

            fetchHomePageCmsSettings() {
                HomepageCmsService.get().then(response => {
                    const data = response.data;

                    this.selectedRecommendedProducts = data.recommendations;
                    this.selectedWorkfieldCategories = data.workfieldCategories;
                    this.selectedCategories = data.categories;
                    this.headerImage = data.headerImage;
                    this.headerTitle = data.headerTitle;
                    this.headerDescription = data.headerDescription;
                    this.headerButtonText = data.headerButtonText;
                    this.headerButtonUrl = data.headerButtonUrl;
                    this.homepageVideo = data.homepageVideo;
                });
            },

            searchMultiselectProducts: debounce(function (query) {
                this.isFetchingProducts = true;

                const params = {
                    filters: {
                        name: query,
                    },
                };

                ProductService.index(params).then(response => {
                    this.products = response.data.data;
                    this.isFetchingProducts = false;
                });
            }, 250),

            searchMultiselectCategories: debounce(function (query) {
                this.isFetchingProducts = true;

                const params = {
                    filters: {
                        name: query,
                    },
                };

                CategoryService.index(params).then(response => {
                    this.categories = response.data;
                    this.isFetchingProducts = false;
                });
            }, 250),

            save() {
                const params = {
                    recommendations: this.selectedRecommendedProducts.map(p => p.id),
                    workfieldCategories: this.selectedWorkfieldCategories.map(p => p.id),
                    categories: this.selectedCategories.map(p => p.id),
                    headerImage: this.headerImage,
                    headerTitle: this.headerTitle,
                    headerDescription: this.headerDescription,
                    headerButtonText: this.headerButtonText,
                    headerButtonUrl: this.headerButtonUrl,
                    homepageVideo: this.homepageVideo,
                };
                const promise = HomepageCmsService.store(params);
                promise.then(response => {
                    this.$flashMessage('Succesvol opgeslagen', 3500, 'succes');
                });
                promise.catch(err => {
                    const errors = Object.entries(err.response.data.errors)
                                         .map((err) => err[1])
                                         .flat()
                                         .join('<br />');

                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
    };
</script>
