export default {
    nl: {
        pros_and_cons: {
            tab_title: 'Pros and cons',

            add_item: 'Nieuw item toevoegen',
            create_title: 'Nieuwe pro of con toevoegen',

            save: 'Item toevoegen',
            delete: 'Item verwijderen',

            title: 'Titel',
            type: 'Type',
            type_pros: 'Pluspunt',
            type_con: 'Minpunt',

            save_success: 'Pros and cons opgeslagen',
            update_success: 'Pros and cons aangepast',

            delete_modal: {
                title: 'Item verwijderen',
                content: 'Wil je dit item echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
}
