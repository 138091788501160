<template>
    <div>
        <div class="tabs">
            <router-link :to="{name: 'products.prices.discounts', props: routeProps}" class="tab">
                Kortingen
            </router-link>
            <router-link :to="{name: 'products.prices.graduated_prices', props: routeProps}" class="tab">
                Staffelprijzen
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'ProductPrices',

        computed: {
            routeProps() {
                return {
                    product: this.$route.params.product,
                };
            },
        },
    };
</script>

<style scoped lang="scss">
    .tabs {
        width         : fit-content;
        margin-bottom : 16px;

        .tab {
            padding : 10px 16px;
        }
    }
</style>
