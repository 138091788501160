import BaseService from './index';

export default {
    index(questionId,params) {
        return BaseService.get(`/choice-help/${questionId}/answers`, params)
    },

    store(params) {
        return BaseService.post(`/choice-help-answers`, params)
    },

    show(answerId) {
        return BaseService.get(`/choice-help-answers/${answerId}`);
    },

    update(answerId, params) {
        return BaseService.post(`/choice-help-answers/${answerId}`, params);
    },

    destroy(answerId) {
        return BaseService.post(`/choice-help-answers/${answerId}/delete`);
    },
}
