export default {
    nl: {
        redirects: {
            redirect: 'Redirect',
            redirects: 'Redirects',
            add: 'Redirect toevoegen',
            delete: 'Verwijderen',
            from: 'Van url',
            to: 'Naar url',
            save: 'Opslaan',
            delete_modal_title: 'Redirect verwijderen',
            delete_modal_content: 'Wil je deze redirect echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
        },
    },
}
