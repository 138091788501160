<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">{{ $t('categories.categories') }}</h1>
            <div>
                <a @click.prevent="$router.push({name: 'categories.create-sub'})" class="btn btn--light-blue" href="#">
                    {{ $t('categories.new_sub_category') }}
                </a>
                <a @click.prevent="$router.push({name: 'categories.create'})" class="btn btn--orange" href="#">
                    {{ $t('categories.new') }}
                </a>
            </div>
        </div>

        <div class="filters">
            <div class="search">
                <img alt="search-icon" src="/svgs/search-icon.svg">
                <input :placeholder="$t('general.search') + '...'"
                       @input="onSearchInput"
                       class="search"
                       type="text"
                       v-model="searchQuery" />
            </div>
        </div>

        <div class="custom-table table">
            <div class="thead">
                <div class="item">{{ $t('categories.name') }}</div>
            </div>
            <div class="tbody">
                <div class="category-item" v-for="category in categories" v-if="categories.length > 0">
                    <div class="item" @click="showDetail(category)">{{ category.name }}</div>
                    <div class="item sub-item" @click="showDetail(subCategory)" v-for="subCategory in category.sub_categories">
                        {{ subCategory.name }}
                    </div>
                </div>
                <div class="no-results" v-else>
                    {{ $t('general.noResults') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { CategoryService } from '../../services';
    import debounce from 'lodash/debounce';

    export default {
        name: 'overview-categories',

        data() {
            return {
                categories: [],
                searchQuery: '',
            }
        },

        methods: {
            fetchCategories() {
                const params = {
                    search: this.searchQuery
                };

                const promise = CategoryService.main(params);

                promise.then(response => {
                    this.categories = response.data;
                });

                return promise;
            },

            onSearchInput: debounce(function () {
                this.fetchCategories();
            }, 250),

            showDetail(category) {
                this.$router.push('categorieen/' + category.id)
            },
        },

        beforeMount() {
            this.fetchCategories();
        }
    }
</script>
