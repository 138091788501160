<template>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.837 3.20948C14.5472 -1.06983 7.50732 -1.06983 3.21742 3.20948C-1.07247 7.48878 -1.07247 14.5112 3.21742 18.7905C7.50732 23.0698 14.4372 23.0698 18.727 18.7905C23.0169 14.5112 23.1269 7.48878 18.837 3.20948V3.20948ZM14.1072 15.6085L11.0272 12.5362L7.94731 15.6085L6.40735 14.0723L9.48727 11L6.40735 7.92768L7.94731 6.39152L11.0272 9.46384L14.1072 6.39152L15.6471 7.92768L12.5672 11L15.6471 14.0723L14.1072 15.6085V15.6085Z" fill="black"/>
    </svg>
</template>

<script>
    export default {
        name: 'DeleteIcon',
    };
</script>