<template>
    <div>
        <div class="field">
            <label class="label" for="title">{{ $t('categories.meta_title') }}</label>
            <div class="field-control">
                <input id="title" type="text" v-model="product.meta_title" class="meta-titel">
            </div>
        </div>
        <div class="field">
            <label class="label">{{ $t('categories.meta_description') }}</label>
            <div class="field-control">
                <textarea placeholder="" v-model="product.meta_description"></textarea>
            </div>
        </div>

        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('products.save_meta') }}
        </a>
    </div>
</template>

<script>
    export default {
        computed: {
            product() {
                return this.$store.state.products.product;
            },
        },
        methods: {
            save() {
                this.$store.commit('products/set_product', this.product);

                this.$store.dispatch('products/update_product').then(response => {
                    this.$flashMessage(this.$t('products.update_success'), 3500, 'success');
                }).catch((err) => {
                    const errors = Object.entries(err.response.data.errors)
                        .map((err) => err[1])
                        .flat()
                        .join("<br />");

                    this.$flashMessage(errors, 3500, 'error');
                });
            },
        },
        name: "ProductMetaTab"
    }
</script>
