export default {
    nl: {
        configurations: {
            new_configuration_title: 'Nieuwe vraag toevoegen',
            edit_configuration_title: 'Vraag bewerken',
            save: 'Vraag opslaan',
            delete: 'Vraag verwijderen',
            default_configuration: 'Standaard vraag',
            compound_configuration: 'Samengestelde vraag',
            configuration: 'Vraag',
            configuration_option: 'Antwoord',
            configuration_options: 'Antwoorden',
            number: 'Aantal',
            order: 'Volgorde',
            is_default_configuration_option: 'Standaard antwoord',
            has_custom_color: 'Eigen kleurstelling',
            custom_color_label_1: 'Vraag 1',
            custom_color_label_2: 'Vraag 2',
            add_configuration: 'Vraag toevoegen',
            add_configuration_option: 'Antwoord toevoegen',
            multiple_configuration_options_possible: 'Meerdere antwoorden mogelijk',
            parent_configuration: 'Bovenliggende vraag',
            dependent_configuration: 'Afhankelijke vraag',
            dependent_configuration_option: 'Afhankelijk antwoord',

            save_success: 'Vraag opgeslagen',
            update_success: 'Vraag aangepast',

            delete_modal: {
                title: 'Vraag verwijderen',
                content: 'Wil je deze vraag echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
};
