<template>
  <div>
    <div class="field">
      <label class="label" for="title">Titel</label>
      <div class="field-control">
        <input @blur="storeSeo" id="title" type="text" v-model="category.seo_title" class="seo-title">
      </div>
    </div>
    <div class="field">
      <label class="label" for="content">SEO Content</label>
      <Redactor v-on:input="storeSeo" id="content" v-model="category.seo_body" :config="redactorConfig" />
    </div>
  </div>
</template>

<script>
import Redactor from '../../../components/redactor-text-area'

export default {
  components: {
    Redactor
  },
  props: {
    category: {
      required: true
    },
  },
  data() {
    return {
      redactorConfig: {
        lang: 'nl',
        buttons: ['bold', 'html', 'link', 'image', 'video'],
        pasteImages: true,
        plugins: ['video', 'image'],
        imageUpload: '/backend/images/upload'
      },
    }
  },
  methods: {
    storeSeo() {
      this.$store.commit('categories/set_category', this.category)
    },
  },
  name: "SeoContentTab"
}
</script>

<style>
.redactor-styles {
  height : 500px;
}
</style>
